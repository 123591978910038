import { Expose } from 'class-transformer';

export class FormatSelect {
	@Expose({ name: 'value' })
	value: string | undefined;

	@Expose({ name: 'label' })
	label: string | undefined;

	@Expose({ name: 'id' })
	id: number | undefined;

	@Expose({ name: 'type' })
	type: number | undefined;
}
