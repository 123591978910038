/* eslint-disable @typescript-eslint/no-unused-vars */
// import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import FullContent from './pages/FullContent';
import Profile from './components/User/LoggedUserProfile';
import Layout from './pages/Layout';
import Login from './pages/Login';
import Home from './pages/Home';
import RegisterPatientForm from './components/RegisterPatient/RegisterPatientForm';
import MedicalConditions from './components/MedicalContidions/MedicalConditions';
import PatientProfile from './components/PatienProfile/PatientProfile';
import Appointments from './components/Appointments/Appointments';
import CreateAppointment from './components/Calendar/Appointment/CreateAppointment';
import PatientList from './components/PatientList/PatientList';
import ListNote from './components/Notes/NoteList/ListNote';
import RegisterUserForm from './components/User/Register';
import ForgotPassword from './components/ForgotPassword';
import RegisterEntity from './components/Entity/RegisterEntity';
import ProfileEntity from './components/Entity/ProfileEntity';
import ListEntity from './components/Entity/ListEntity';
import CreateNote from './components/Notes/CreateNote';
import FirstLogin from './components/FirstLogin';
import LinkUsers from './components/LinkUsers';
import UpdatePatientForm from './components/UpdatePatientForm/UpdatePatientForm';
import CreateReservation from './components/Calendar/CreateReservation';
import UpdateUser from './components/User/Update';
import UpdateEntity from './components/Entity/UpdateEntity';
import UpdateTutorForm from './components/UpdatePatientForm/UpdateTutorForm';
import UpdateDoctorForm from './components/UpdatePatientForm/UpdateDoctorForm';
import UpdateAllerProfCondForm from './components/UpdatePatientForm/UpdateConditionForm';
import UpdateAllergyForm from './components/UpdatePatientForm/UpdateAllergyForm';
import UpdateSurgeryForm from './components/UpdatePatientForm/UpdateSurgeryForm';
import UpdateMedicationForm from './components/UpdatePatientForm/UpdateMedicationForm';
import UpdateVaccineForm from './components/UpdatePatientForm/UpdateVaccineForm';
import ReactBigCalendar from './components/Calendar/ReactBigCalendar';
import ProfileEntityInfo from './components/Entity/ProfileEntityInfo';
import LinkEntity from './components/Entity/LinkEntity';
import ManageUser from './components/Entity/ManageUser';
import ManageEntity from './components/Entity/ManageEntity';
import PieMetrics from './components/Chart/PieMetrics';
import UseInicializer from './hooks/UseInicializer';
import ShowProfile from './components/User/ProfileUser/ShowProfile';
// import AddTutorForm from "./components/AddPatientData/AddTutorForm";

function App() {
	UseInicializer();
	return (
		<React.Fragment>
			<BrowserRouter>
				<FullContent>
					<Routes>
						<Route
							path="/entities/profile"
							element={<Layout container={<ProfileEntity />} />}
						/>
						<Route
							path="/metrics"
							element={<Layout container={<PieMetrics />} />}
						/>
						<Route
							path="/entities/profile/information"
							element={<Layout container={<ProfileEntityInfo />} />}
						/>
						<Route path="/tripalosky" element={<CreateAppointment />} />
						<Route path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						<Route
							path="/profile/user"
							element={<Layout container={<ShowProfile />} />}
						/>
						<Route path="/firstlogin" element={<FirstLogin />} />
						<Route path="/forgotpassword" element={<ForgotPassword />} />
						<Route
							path="/register/user"
							element={<Layout container={<RegisterUserForm />} />}
						/>
						<Route
							path="/update/entity"
							element={<Layout container={<UpdateEntity />} />}
						/>
						{/* <Route
							path="/update/entity"
							element={<Layout container={<UpdateEntityClass />} />}
          /> */}
						<Route
							path="/profile/entity"
							element={<Layout container={<RegisterUserForm />} />}
						/>
						<Route
							path="/entities"
							element={<Layout container={<ListEntity />} />}
						/>
						<Route
							path="/entities/manage/users"
							element={<Layout container={<ManageUser />} />}
						/>
						<Route
							path="/entities/manage/entity"
							element={<Layout container={<ManageEntity />} />}
						/>
						<Route
							path="/patientupdate"
							element={<Layout container={<UpdatePatientForm />} />}
						/>
						<Route
							path="/tutorupdate"
							element={<Layout container={<UpdateTutorForm />} />}
						/>
						<Route
							path="/doctorupdate"
							element={<Layout container={<UpdateDoctorForm />} />}
						/>
						<Route
							path="/patientCond"
							element={<Layout container={<UpdateAllerProfCondForm />} />}
						/>
						<Route
							path="/allergyupdate"
							element={<Layout container={<UpdateAllergyForm />} />}
						/>
						<Route
							path="/surgery/update"
							element={<Layout container={<UpdateSurgeryForm />} />}
						/>
						<Route
							path="/medication/update"
							element={<Layout container={<UpdateMedicationForm />} />}
						/>
						<Route
							path="/vaccine/update"
							element={<Layout container={<UpdateVaccineForm />} />}
						/>
						<Route
							path="/profile/update"
							element={<Layout container={<UpdateUser />} />}
						/>
						<Route
							path="/register/RegisterEntity"
							element={<Layout container={<RegisterEntity />} />}
						/>
						<Route path="/home" element={<Layout container={<Home />} />} />
						<Route
							path="/newreservation"
							element={<Layout container={<CreateReservation />} />}
						/>
						<Route
							path="/patientprofile/:id/"
							element={<Layout container={<PatientProfile />} />}
						/>
						<Route
							path="/userprofile"
							element={<Layout container={<Profile />} />}
						/>
						<Route
							path="/profilepatient"
							element={<Layout container={<Profile />} />}
						/>
						<Route
							path="/registerpatient"
							element={<Layout container={<RegisterPatientForm />} />}
						/>
						<Route
							path="/calendar"
							element={<Layout container={<ReactBigCalendar />} />}
						/>
						<Route
							path="/appointments"
							element={<Layout container={<Appointments />} />}
						/>
						<Route
							path="/patients"
							element={<Layout container={<PatientList />} />}
						/>
						<Route
							path="/appointments/create"
							element={<Layout container={<CreateAppointment />} />}
						/>
						<Route
							path="/appointments/create/:idenfiedPatient"
							element={<Layout container={<CreateAppointment />} />}
						/>
						<Route
							path="/user/linkuser"
							element={<Layout container={<LinkUsers />} />}
						/>
						<Route
							path="/entity/linkentity"
							element={<Layout container={<LinkEntity />} />}
						/>
						<Route
							path="/patient/newnote/:id"
							element={<Layout container={<CreateNote />} />}
						/>
						<Route
							path="/patient/listnote/:id"
							element={<Layout container={<ListNote />} />}
						/>
						<Route
							// path="/appointments/info/:id"
							path="/patientconditions/:id"
							element={<Layout container={<MedicalConditions />} />}
						/>
						{/* <Route
							path="/add/tutor/"
							element={<Layout container={<AddTutorForm />} />}
						/> */}
					</Routes>
				</FullContent>
			</BrowserRouter>
		</React.Fragment>
	);
}

export default App;
