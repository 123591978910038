/* eslint-disable @typescript-eslint/no-unused-vars */
import Swal from 'sweetalert2';
import api from './Api';
import TokenService from './Token';

const registerPatient = async (patientobject: any) => {
	let user: any = localStorage.getItem('user');
	console.log('Datos Raw Residente: ', patientobject);

	let {
		medicine,
		tutores,
		specialtyDoctor,
		vaccines,
		condition,
		countryOrigin,
		allergy,
		surgery,
		clinicalMedicalNH,
		firstname,
		middlename,
		surname,
		second_surname,
		email,
		insuranceCarrier,
		insuranceCarrierII,
		tytocareId,
		preferenceHospital,
		nursingHomeId,
		nursingHomeSourceId,
		entryDate,
		birthday,
		gender,
		statusSex,
		bloodtype,
		maritalStatus,
		phone,
		socialSecurityId,
		user_type,
		isVaccinated,
		photoProfile,
		photoURL,
		socialSecurityCardURL,
		driverLicecseURL,
		insuranceCardURL,
		otherSource,
	}: any = patientobject;

	//   //Validaciones antes de enviar los datos al backend
	user_type = 4;
	birthday = new Date(birthday).toISOString().substring(0, 10); //yyyy-mm-dd
	nursingHomeSourceId =
		nursingHomeSourceId === undefined
			? (nursingHomeSourceId = { value: 0 })
			: nursingHomeSourceId;
	medicine = medicine[0]?.nameMedicine === '' ? [] : medicine;
	tutores = tutores[0]?.tutorFirstname === '' ? [] : tutores;
	specialtyDoctor =
		specialtyDoctor[0]?.doctorFirstname === '' ? [] : specialtyDoctor;
	surgery = surgery[0]?.item === undefined ? [] : surgery;
	condition = condition[0]?.item === undefined ? [] : condition;
	allergy = allergy[0]?.item === undefined ? [] : allergy;
	vaccines = vaccines[0]?.vaccineName === '' ? [] : vaccines;
	isVaccinated = vaccines.length > 0 ? true : false;
	nursingHomeSourceId =
		otherSource !== null
			? { value: otherSource, label: null }
			: nursingHomeSourceId;

	const objet = {
		medicine,
		tutores,
		specialtyDoctor,
		vaccines,
		condition,
		countryOrigin,
		allergy,
		surgery,
		clinicalMedicalNH,
		firstname,
		middlename,
		surname,
		second_surname,
		email,
		insuranceCarrier,
		insuranceCarrierII,
		tytocareId,
		preferenceHospital,
		nursingHomeId,
		nursingHomeSourceId,
		entryDate,
		birthday,
		gender,
		statusSex,
		bloodtype,
		maritalStatus,
		phone,
		socialSecurityId,
		user_type,
		isVaccinated,
		//photoURL,
		//socialSecurityCardURL,
		//driverLicecseURL,
		//photoProfile,
		//insuranceCardURL,
	};

	console.log('Datos Proceeesados: ', objet);
	const result = await api.instanceUsers
		.post(`/user/create`, objet, {
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				USER_ENTITY_ID: `${localStorage.getItem('role')}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then((response) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'Residente registrado con éxito',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return response.data;
		})
		.catch((error) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Vuelva a intertarlo nuevamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return error;
		});
	// console.log(`result.status ${JSON.stringify(result)}`);
	// console.log(`result.user_id ${JSON.stringify(result?.data[0]?.user_id)}`);

	// if (result?.data[0]?.user_id !== null) {
	// 	const newUser = result?.data[0];
	// 	const objetImage = {
	// 		userEntityId: newUser.user_id,
	// 		photo: photoURL,
	// 		driverLicense: driverLicecseURL,
	// 		insuranceCard: insuranceCardURL,
	// 		socialSecurityCard: socialSecurityCardURL,
	// 	};
	// 	const form = new FormData();
	// 	form.append('userEntityId', objetImage?.userEntityId);
	// 	form.append('photo', objetImage?.photo);
	// 	form.append('driverLicense', objetImage?.driverLicense);
	// 	form.append('insuranceCard', objetImage?.insuranceCard);
	// 	form.append('socialSecurityCard', objetImage?.socialSecurityCard);
	// 	// console.log(`form: ${JSON.stringify(form)}`);
	// 	// console.log(`objetImage: ${JSON.stringify(objetImage)}`);
	// 	try {
	// 		const dataImage: any = await api.instanceUsers.post(
	// 			`/profile/update-images-docs/`,
	// 			objetImage,
	// 			{
	// 				headers: {
	// 					Authorization: `${JSON.parse(user).TokenType} ${
	// 						JSON.parse(user).IdToken
	// 					}`,
	// 					'Content-Type': 'multipart/form-data',
	// 					USER_ENTITY_ID: `${localStorage.getItem('role')}`,
	// 				},
	// 				withCredentials: true,
	// 			},
	// 		);
	// 		// console.log(`status upLoadImage: ${JSON.stringify(dataImage)}`);
	// 	} catch (error) {
	// 		return `error: ${error}`;
	// 	}
	// }

	return result;
};

const createEvent = async (event: any) => {
	console.log('Raw data: ', event);

	let user: any = localStorage.getItem('user');

	let {
		expectedDuration,
		beginAt,
		endAt,
		description,
		orderAttention,
		address,
		chiefComplaint,
		tytocareId,
		insuranceCarrier,
		doctorUserEntityId,
		statusPriorityId,
		statusAssistanceTypeId,
		patientUserEntityId,
		statusStatusId,
		created_by,
		calendarId,
	} = event;

	doctorUserEntityId = doctorUserEntityId.value;
	created_by = localStorage.getItem('userId');
	patientUserEntityId = patientUserEntityId.value;
	statusPriorityId = statusPriorityId.value;
	statusAssistanceTypeId = statusAssistanceTypeId.value;
	orderAttention = orderAttention > 0 ? orderAttention : 0;
	expectedDuration = 0;
	chiefComplaint = '';
	statusStatusId = 1;
	address = '';
	beginAt = new Date(beginAt).toISOString().substring(0, 19);
	endAt = new Date(endAt).toISOString().substring(0, 19);
	calendarId = 1;

	const obj = {
		expectedDuration,
		beginAt,
		endAt,
		description,
		orderAttention,
		address,
		chiefComplaint,
		tytocareId,
		insuranceCarrier,
		doctorUserEntityId,
		statusPriorityId,
		statusAssistanceTypeId,
		patientUserEntityId,
		statusStatusId,
		created_by,
		calendarId,
	};

	console.log('Formated data: ', obj);

	return await api.instanceEvents
		.post(`/event/createDate`, obj, {
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				USER_ENTITY_ID: `${localStorage.getItem('role')}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then(
			(response) => {
				return response.data;
			}, // response.data is the token
		)
		.catch((error) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Se ha presentado un error interno',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
		});
};

const registerEntity = async (data: any) => {
	let user: any = localStorage.getItem('user');
	const userid = TokenService.getUserIdAction();

	console.log('Raw data ', data);

	let {
		address,
		legalName,
		comercialName,
		webPage,
		email,
		firstLline,
		secondLline,
		ein,
		npi,
		phoneTwo,
		phone,
		phoneExt,
		state,
		zipCode,
		country,
		pobox,
		poboxState,
		poboxZipCode,
		poboxCountry,
	} = data;

	ein = Number(ein) || null;
	npi = Number(npi) || null;

	const obj = {
		createdBy: userid,
		legalName,
		comercialName,
		npi,
		ein,
		webPage,
		email,
		phone,
		phoneTwo,
		phoneExt,
		zipCodePoBox: poboxZipCode,
		address,
		firstLline,
		secondLline,
		statePoBox: poboxCountry?.label,
		zipCode: zipCode !== null ? zipCode : null,
		pobox,
		state: state?.label,
		country: country?.value,
		poboxState: poboxState?.label,
	};

	console.log('Formated Data ', obj);

	const result = await api.instanceEntities
		.post(
			`/${data.entityType.value === 0 ? `nursing_home` : `clinic`}/create`,
			obj,
			{
				headers: {
					Authorization: `${JSON.parse(user).TokenType} ${
						JSON.parse(user).IdToken
					}`,
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			},
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
	return result;
};

const recoveryPassword = async (data: any) => {
	const { hash, new_password, confirmation_code } = data;
	const result: any = await api.instanceUsers
		.post(
			`/user/confirm-recovery-password/${hash}`,
			{ new_password, confirmation_code },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			},
		)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error);
		});
	return result;
};

const forgotPassword = async (email: any) => {
	const result: any = await api.instanceUsers
		.post(`/user/forget-password`, email, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error);
		});
	return result;
};

const addPatientNote = async (data: any) => {
	let user: any = localStorage.getItem('user');

	const result: any = await api.instanceUsers
		.post(`/user/add-resident-notes`, data, {
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				USER_ENTITY_ID: `${localStorage.getItem('role')}`,
				'Content-Type': 'application/json',
			},
		})
		.then((response) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'Nota registrada con éxito',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return response;
		})
		.catch((error) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Se ha presentado un error interno',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return error;
		});
	return result;
};

const registerService = {
	registerPatient,
	createEvent,
	registerEntity,
	forgotPassword,
	recoveryPassword,
	addPatientNote,
};

export default registerService;
