import React from 'react';
import options from '../../data/selectOptions';
import { clearSpace, emailValidation } from '../../utils/regexExpresion';
import { customStyles } from '../customStyleNewLabel';
import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg';
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg';
import { phoneLengthVerification } from '../../utils/phoneLengthVerification';

const StepThree = ({
	Controller,
	Select,
	control,
	NumberFormat,
	register,
	tutorAppend,
	tutorFields,
	tutorRemove,
	errors,
	CustomValueContainer,
	mode,
	watch,
}: any) => {
	// pais direcion municipio/departamento codigo postal
	return (
		<React.Fragment>
			{tutorFields.map((field: any, index: any) => {
				return (
					<div
						key={field.id}
						className="w-full mx-auto place-items-start flex flex-col space-y-5 border-b-2">
						{/* Informacion personal */}

						<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
							<img src={InformationPersonal} className={`w-12`} alt="" />
							<h1 className="text-xl">{`Información personal`}</h1>
						</div>
						<div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorFirstname`}
									name={`tutores.${index}.tutorFirstname`}
									className="input-style peer"
									placeholder="Primer Nombre*"
									{...register(`tutores.${index}.tutorFirstname`, {
										required: {
											value: true,
											message: 'Primer nombre es requerido',
										},
									})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorFirstname`}>
									Primer Nombre*
								</label>

								{errors.tutores?.[index]?.tutorFirstname && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorFirstname?.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorMiddlemame`}
									className="input-style peer"
									placeholder="Segundo Nombre"
									{...register(`tutores.${index}.tutorMiddlemame`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorMiddlemame`}>
									Segundo Nombre
								</label>
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorSurname`}
									className="input-style peer"
									placeholder="Primer Apellido*"
									{...register(`tutores.${index}.tutorSurname`, {
										required: {
											value: true,
											message: 'Primer apellido es requerido',
										},
									})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorSurname`}>
									Primer Apellido*
								</label>
								{errors.tutores?.[index]?.tutorSurname && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorSurname.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorSecondSurname`}
									className="input-style peer"
									placeholder="Segundo Apellido"
									{...register(`tutores.${index}.tutorSecondSurname`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorSecondSurname`}>
									Segundo Apellido
								</label>
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.tutorMaritalStatus`}
									rules={{
										required: {
											value: true,
											message: 'Estado civil es requerido',
										},
									}}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<Select
											components={{ ValueContainer: CustomValueContainer }}
											className="w-full"
											placeholder="Estado civil...*"
											styles={customStyles}
											onBlur={onBlur}
											onChange={onChange}
											isClearable
											ref={ref}
											options={options.maritalOptions}
											value={value}
										/>
									)}
								/>
								{errors.tutores?.[index]?.tutorMaritalStatus && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorMaritalStatus.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.tutorRelationship`}
									rules={{
										required: {
											value: true,
											message:
												'La relacion del responsable con el residente es requerido',
										},
									}}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<Select
											components={{ ValueContainer: CustomValueContainer }}
											className="w-full"
											placeholder="Seleccione relacion...*"
											styles={customStyles}
											onBlur={onBlur}
											onChange={onChange}
											isClearable
											options={options.relationshipOptions}
											value={value}
										/>
									)}
								/>
								{errors.tutores?.[index]?.tutorRelationship && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorRelationship.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorProfession`}
									className="input-style peer"
									placeholder="Profesion"
									{...register(`tutores.${index}.tutorProfession`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorProfession`}>
									Profesion
								</label>
								{errors.tutores?.[index]?.tutorProfession && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorProfession.message}
									</p>
								)}
							</div>

							{/* <div className="w-11/12 lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.isMobilePersonalTutor`}
									render={({
										field: { onChange, onBlur, value = false, ref },
									}: any) => (
										<label
											className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px] cursor-pointer"
											htmlFor={`tutores.${index}.isMobilePersonalTutor`}>
											<Switch
												id={`tutores.${index}.isMobilePersonalTutor`}
												name={`tutores.${index}.isMobilePersonalTutor`}
												onChange={onChange}
												height={16}
												width={30}
												checked={value}
												checkedIcon={false}
												uncheckedIcon={false}
												className={`mr-5`}
											/>
											<span>Es Celular</span>
										</label>
									)}
								/>
							</div> */}

							{/* </div> */}

							{/* contacto de informacion personal */}
							{/* <p className="text-center text-2xl">
							Información personal
						</p> */}
							{/* <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-3"> */}
							{/* <div className="w-11/12 lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.isMobileHomeTutor`}
									render={({
										field: { onChange, onBlur, value = false, ref },
									}: any) => (
										<label
											className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px] cursor-pointer"
											htmlFor={`tutores.${index}.isMobileHomeTutor`}>
											<Switch
												id={`tutores.${index}.isMobileHomeTutor`}
												name={`tutores.${index}.isMobileHomeTutor`}
												onChange={onChange}
												height={16}
												width={30}
												checked={value}
												checkedIcon={false}
												uncheckedIcon={false}
												className={`mr-5`}
											/>
											<span>Es Celular</span>
										</label>
									)}
								/>
							</div> */}
							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.tutorPhone`}
									rules={{
										required: {
											value: true,
											message: 'El numero celular es requerido',
										},
										validate: {
											validateLength: (data: string) => {
												return data.replace(clearSpace, '').replace(' ', '')
													.length < 10
													? `¡Error, solo hay ${
															data.replace(clearSpace, '').replace(' ', '')
																.length
													  } de 10 dígitos!`
													: null;
											},
										},
									}}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<NumberFormat
											id={`tutores.${index}.tutorPhone`}
											name={`tutores.${index}.tutorPhone`}
											className="input-style peer"
											thousandSeparator={true}
											onChange={onChange}
											onBlur={onBlur}
											placeholder="Número Celular*"
											displayType={'input'}
											format="### ### ####"
											value={value}
										/>
									)}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorPhone`}>
									Número Celular*
								</label>
								{errors.tutores?.[index]?.tutorPhone && (
									<p className="text-sm text-red-600 mt-2">
										{errors?.tutores?.[index]?.tutorPhone.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorEmail`}
									className="input-style peer"
									placeholder="Correo electrónico*"
									{...register(`tutores.${index}.tutorEmail`, {
										pattern: {
											value: emailValidation,
											message: 'Correo electrónico Invalido',
										},
									})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorEmail`}>
									Correo electrónico*
								</label>
								{errors.tutores?.[index]?.tutorEmail && (
									<p className="text-sm text-red-600 mt-2">
										{errors?.tutores?.[index]?.tutorEmail.message}
									</p>
								)}
							</div>

							{/* <div className="w-11/12 lg:w-64 h-full">
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorExt`}
								>
									<input
										type="text"
										id={`tutores.${index}.tutorExt`}
										className="w-full h-10 focus:outline-none bg-transparent"
										placeholder={`Extension`}
										{...register(`tutores.${index}.tutorExt`, {})}
									/>
								</label>
								{errors.tutores?.[index]?.tutorExt && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorExt.message}
									</p>
								)}
							</div> */}

							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.tutorOptionalPhone`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<NumberFormat
											id={`tutores.${index}.tutorOptionalPhone`}
											name={`tutores.${index}.tutorOptionalPhone`}
											className="input-style peer"
											thousandSeparator={true}
											onChange={onChange}
											onBlur={onBlur}
											placeholder="Teléfono Fijo"
											displayType={'input'}
											format="### ### ####"
											value={value}
										/>
									)}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorOptionalPhone`}>
									Teléfono Fijo
								</label>
								{watch(`tutores[${index}]`)
									?.tutorOptionalPhone?.replace(clearSpace, '')
									?.replace(' ', '').length < 10 &&
								watch(`tutores[${index}]`)
									?.tutorOptionalPhone?.replace(clearSpace, '')
									?.replace(' ', '').length !== 0 ? (
									<p className="text-sm text-red-600 mt-2">
										{`¡Error, el teléfonodebe tener 10 dígitos!`}
									</p>
								) : null}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="number"
									id={`tutores.${index}.tutorOptionalExt`}
									className="input-style peer"
									placeholder="Extensión Teléfono Fijo"
									{...register(`tutores.${index}.tutorOptionalExt`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorOptionalExt`}>
									Extensión Teléfono Fijo
								</label>
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorHomeAddressLineOne`}
									className="input-style peer"
									placeholder="Dirección Linea 1*"
									{...register(`tutores.${index}.tutorHomeAddressLineOne`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorHomeAddressLineOne`}>
									Dirección Linea 1*
								</label>
								{errors.tutores?.[index]?.tutorHomeAddressLineOne && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorHomeAddressLineOne.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorHomeAddressLineTwo`}
									className="input-style peer"
									placeholder="Dirección Linea 2"
									{...register(`tutores.${index}.tutorHomeAddressLineTwo`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorHomeAddressLineTwo`}>
									Dirección Linea 2
								</label>
								{errors.tutores?.[index]?.tutorHomeAddressLineTwo && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorHomeAddressLineTwo.message}
									</p>
								)}
							</div>

							<div className="lg:w-64 h-full ">
								<Controller
									control={control}
									name={`tutores.${index}.tutorHomeState`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<Select
											components={{ ValueContainer: CustomValueContainer }}
											className="w-full"
											placeholder="Pueblo/Municipio...*"
											isClearable
											styles={customStyles}
											onBlur={onBlur}
											onChange={onChange}
											options={options.city}
											value={value}
										/>
									)}
								/>
							</div>

							{/* 
							<div className="lg:w-64 h-full">
								<label
								className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
								htmlFor={`tutores.${index}.tutorHomeCity`}>
								<Controller
									control={control}
									name={`tutores.${index}.tutorHomeCity`}
									render={({
									field: { onChange, onBlur, value, ref },
									}: any) => (
									<Select
										className="w-full"
										placeholder="Ciudad...*"
										styles={customStyles}
										onBlur={onBlur}
										onChange={onChange}
										options={options.city}
										value={value}
									/>
									)}
								/>
								</label>
							</div> */}

							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.tutorZipCode`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<NumberFormat
											id={`tutores.${index}.tutorZipCode`}
											className="input-style peer"
											thousandSeparator={true}
											onChange={onChange}
											onBlur={onBlur}
											placeholder="Código postal*"
											displayType={'input'}
											format="#####"
											value={value}
										/>
									)}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorZipCode`}>
									Código postal*
								</label>
								{errors.tutores?.[index]?.tutorZipCode && (
									<p className="text-sm text-red-600 mt-2">
										{errors?.tutores?.[index]?.tutorZipCode.message}
									</p>
								)}
							</div>

							<div className="lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.tutorHomeCountry`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<Select
											components={{ ValueContainer: CustomValueContainer }}
											className="w-full"
											placeholder="Pais...*"
											isClearable
											styles={customStyles}
											onBlur={onBlur}
											onChange={onChange}
											options={[{ value: 0, label: 'Puerto Rico' }]}
											value={value}
										/>
									)}
								/>
							</div>
						</div>
						{/* Informacion laboral */}
						<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
							<img src={InformationLaboral} className={`w-12`} alt="" />

							<h1 className="text-xl">{`Información laboral`}</h1>
						</div>

						<div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
							{/* <div className="w-11/12 lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.isMobileLaborTutor`}
									render={({
										field: { onChange, onBlur, value = false, ref },
									}: any) => (
										<label
											className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px] cursor-pointer"
											htmlFor={`tutores.${index}.isMobileLaborTutor`}>
											<Switch
												id={`tutores.${index}.isMobileLaborTutor`}
												name={`tutores.${index}.isMobileLaborTutor`}
												onChange={onChange}
												height={16}
												width={30}
												checked={value}
												checkedIcon={false}
												uncheckedIcon={false}
												className={`mr-5`}
											/>
											<span>Es Celular</span>
										</label>
									)}
								/>
							</div> */}
							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.workPlace`}
									className="input-style peer"
									placeholder="lugar de trabajo"
									{...register(`tutores.${index}.workPlace`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.workPlace`}>
									lugar de trabajo
								</label>
								{/* {errors.tutores?.[index]?.workPlace && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.workPlace.message}
									</p>
								)} */}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.tutorLaborPhone`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<NumberFormat
											id={`tutores.${index}.tutorLaborPhone`}
											className="input-style peer"
											thousandSeparator={true}
											onChange={onChange}
											onBlur={onBlur}
											placeholder="Número Celular"
											displayType={'input'}
											format="### ### ####"
											value={value}
										/>
									)}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorLaborPhone`}>
									Número Celular
								</label>
								{errors.tutores?.[index]?.tutorLaborPhone && (
									<p className="text-sm text-red-600 mt-2">
										{errors?.tutores?.[index]?.tutorLaborPhone.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.jopOptPhone`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<NumberFormat
											id={`tutores.${index}.jopOptPhone`}
											className="input-style peer"
											thousandSeparator={true}
											onChange={onChange}
											onBlur={onBlur}
											placeholder="Teléfono Fijo"
											displayType={'input'}
											format="### ### ####"
											value={value}
										/>
									)}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.jopOptPhone`}>
									Teléfono Fijo
								</label>
								{errors.tutores?.[index]?.jopOptPhone && (
									<p className="text-sm text-red-600 mt-2">
										{errors?.tutores?.[index]?.jopOptPhone.message}
									</p>
								)}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="number"
									id={`tutores.${index}.jopOptPhoneExt`}
									className="input-style peer"
									placeholder="Extensión Teléfono"
									{...register(`tutores.${index}.jopOptPhoneExt`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.jopOptPhoneExt`}>
									Extensión Teléfono
								</label>
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorJobAddressLineOne`}
									className="input-style peer"
									placeholder="Dirección Laboral Linea 1"
									{...register(`tutores.${index}.tutorJobAddressLineOne`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorJobAddressLineOne`}>
									Dirección Laboral Linea 1
								</label>
								{/* {errors.tutores?.[index]?.tutorJobAddressLineOne && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorJobAddressLineOne.message}
									</p>
								)} */}
							</div>

							<div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`tutores.${index}.tutorJobAddressLineTwo`}
									className="input-style peer"
									placeholder="Dirección Laboral Linea 2"
									{...register(`tutores.${index}.tutorJobAddressLineTwo`, {})}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorJobAddressLineTwo`}>
									Dirección Laboral Linea 2
								</label>
								{/* {errors.tutores?.[index]?.tutorJobAddressLineTwo && (
									<p className="text-sm text-red-600 mt-2">
										{errors.tutores?.[index]?.tutorJobAddressLineTwo.message}
									</p>
								)} */}
							</div>

							<div className="lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.tutorJobState`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<Select
											components={{ ValueContainer: CustomValueContainer }}
											className="w-full"
											placeholder="Pueblo/Municipio..."
											isClearable
											styles={customStyles}
											onBlur={onBlur}
											onChange={onChange}
											options={options.city}
											value={value}
										/>
									)}
								/>
							</div>

							{/* <div className="lg:w-64 h-full">
								<label
								className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
								htmlFor={`tutores.${index}.tutorJobCity`}>
								<Controller
									control={control}
									name={`tutores.${index}.tutorJobCity`}
									render={({
									field: { onChange, onBlur, value, ref },
									}: any) => (
									<Select
										className="w-full"
										placeholder="Ciudad...*"
										styles={customStyles}
										onBlur={onBlur}
										onChange={onChange}
										options={options.city}
										value={value}
									/>
									)}
								/>
								</label>
							</div> */}

							<div className="w-full lg:w-64 h-full relative">
								<Controller
									control={control}
									name={`tutores.${index}.tutorJobZipCode`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<NumberFormat
											id={`tutores.${index}.tutorJobZipCode`}
											className="input-style peer"
											thousandSeparator={true}
											onChange={onChange}
											onBlur={onBlur}
											placeholder="Código postal"
											displayType={'input'}
											format="#####"
											value={value}
										/>
									)}
								/>
								<label
									className="input-style-label"
									htmlFor={`tutores.${index}.tutorJobZipCode`}>
									Código postal
								</label>
								{/* {errors.tutores?.[index]?.tutorJobZipCode && (
									<p className="text-sm text-red-600 mt-2">
										{errors?.tutores?.[index]?.tutorJobZipCode.message}
									</p>
								)} */}
							</div>

							<div className="lg:w-64 h-full">
								<Controller
									control={control}
									name={`tutores.${index}.tutorJobCountry`}
									render={({
										field: { onChange, onBlur, value, ref },
									}: any) => (
										<Select
											components={{ ValueContainer: CustomValueContainer }}
											className="w-full"
											placeholder="Pais..."
											isClearable
											styles={customStyles}
											onBlur={onBlur}
											onChange={onChange}
											options={[{ value: 0, label: 'Puerto Rico' }]}
											value={value}
										/>
									)}
								/>
							</div>
						</div>

						<div className="w-11/12 sm:w-64 h-full">
							<input
								type="button"
								className={`${
									mode === 'update' || mode === 'add' ? 'hidden' : ''
								} w-full h-10 bg-red-500 rounded-lg text-white mb-5`}
								onClick={() => tutorRemove(index)}
								value="Eliminar"
							/>
						</div>
					</div>
				);
			})}

			<input
				className={`${
					mode === 'update' || mode === 'add' ? 'hidden' : ''
				} mx-auto w-11/12 sm:w-64 rounded-lg px-4 py-2 bg-lime-600 shadow-md duration-300 font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700`}
				type="button"
				value={'Adicionar responsable'}
				onClick={() =>
					tutorAppend({
						// tutorFirstname: '',
						// tutorMiddlemame: '',
						// tutorSurname: '',
						// tutorSecondSurname: '',
						// tutorProfession: '',
						// tutorEmail: '',
						// jobAddress: '',
						// jobAddressTwo: '',
						// jobPhone: '',
						// tutorHomeAddressLineOne: '',
						// tutorHomeAddressLineTwo: '',
						// // tutorHomeCity: "",
						// tutorHomeState: '',
						// tutorHomeCountry: '',
						// tutorJobAddressLineOne: '',
						// tutorJobAddressLineTwo: '',
						// // tutorJobCity: "",
						// tutorJobState: '',
						// tutorJobCountry: '',
						// tutorOptionalPhone: '',
					})
				}
			/>
		</React.Fragment>
	);
};

export default StepThree;
