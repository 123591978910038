import api from './Api';

const appointmentList = async (obj: any) => {
	let user: any = localStorage.getItem('user');

	let { physicianIdentified, entryDate } = obj;
	physicianIdentified = physicianIdentified.value;
	entryDate = new Date(entryDate).toISOString().substring(0, 10);

	const object = { id: physicianIdentified, beginAt: entryDate };

	const data = await api.instanceEvents.post(
		`/event/doctor-event-list`,
		object,
		{
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				USER_ENTITY_ID: `${localStorage.getItem('role')}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		},
	);
	return data.data.data[0];
};

const appointmentServices = {
	appointmentList,
};

export default appointmentServices;
