/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	emailValidation,
	npiValidation,
	websiteValidation,
} from '../../utils/regexExpresion';
import { Controller, useForm } from 'react-hook-form';
import options from '../../data/selectOptions';
import NumberFormat from 'react-number-format';
import { customStyles } from '../customStyleNewLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import Switch from 'react-switch';
import { useEffect, useState } from 'react';
import EntityForm from './FormEntity';
import updateEntity from '../../services/UpdateEntity';
import { connect } from 'react-redux';
import { clearUpdateEntityForm } from '../../actions';
import React from 'react';
import Entities from '../../assets/svg/entidades.svg';
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer';

const UpdateEntity = ({ ...props }) => {
	const [isRequired, setIsRequired] = useState(true);
	const [user, initUser] = useState<any>(null);

	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		watch,
		formState: { errors },
	} = useForm({});

	const inputs = [
		'entityType', //0
		'legalName', //1
		'comercialName', //2
		'npi', //3
		'ein', //4
		'webPage', //5
		'email', //6
		'isMobilePatient', //7
		'phone', //8
		'phoneExt', //9
		'firstLline', //10
		'secondLline', //11
		'pobox', //12
		'country', //13
		'state', //14
		'zipCode', //15
	];

	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				initUser({
					entityType: options.entityType
						.filter((item: any) => {
							if (props?.updateEntityForm?.type === 'C') {
								if (item.value === 1) {
									return item;
								}
							} else if (props?.updateEntityForm?.type === 'NH') {
								if (item.value === 0) {
									return item;
								}
							}
						})
						?.map((item: any) => {
							return item;
						})[0],
					phone: props?.updateEntityForm?.phone
						? props?.updateEntityForm?.phone
						: null,
					firstLline: props?.updateEntityForm?.Address_firstLline,
					secondLline: props?.updateEntityForm?.Address_secondLline,
					pobox: props?.updateEntityForm?.Address_pobox,
					country: options.state
						.filter((item: any) => {
							if (item?.value === props?.updateEntityForm?.countryId) {
								return item;
							}
						})
						?.map((item: any) => {
							return item;
						})[0],
					state: options.city
						.filter((item: any) => {
							if (item?.label === props?.updateEntityForm?.Address_state) {
								return item;
							}
						})
						?.map((item: any) => {
							return item;
						})[0],
					zipCode: props?.updateEntityForm?.Address_zipCode,
					legalName:
						props?.updateEntityForm?.type === 'C'
							? props?.updateEntityForm?.clinic_legalName
							: props?.updateEntityForm?.nursing_home_legalName,
					comercialName:
						props?.updateEntityForm?.type === 'C'
							? props?.updateEntityForm?.clinic_comercialName
							: props?.updateEntityForm?.nursing_home_comercialName,
					webPage:
						props?.updateEntityForm?.type === 'C'
							? props?.updateEntityForm?.clinic_webPage
							: props?.updateEntityForm?.nursing_home_webPage,
					email:
						props?.updateEntityForm?.type === 'C'
							? props?.updateEntityForm?.clinic_email
							: props?.updateEntityForm?.nursing_home_email,
					npi:
						props?.updateEntityForm?.type === 'C'
							? props?.updateEntityForm?.clinic_npi
							: props?.updateEntityForm?.nursing_home_npi,
					ein:
						props?.updateEntityForm?.type === 'C'
							? props?.updateEntityForm?.clinic_ein
							: props?.updateEntityForm?.nursing_home_ein,
					isMobilePatient: false,
					phoneExt: props?.updateEntityForm?.extension
						? props?.updateEntityForm?.extension
						: null,
					nursingHomeId: props?.updateEntityForm?.nursing_home_id,
				});
			}, 1000);
		}
	});

	useEffect(() => {
		reset(user);
	}, [user]);

	const onSubmit = async (data: any) => {
		let dataUpdate: any = null;
		data.nursingHomeId = props?.updateEntityForm?.nursing_home_id;
		data.clinic_id = props?.updateEntityForm?.clinic_id;
		// console.log(`data on submit:\n${JSON.stringify(data, null, 4)}`);
		if (props?.updateEntityForm?.type === 'NH') {
			dataUpdate = await updateEntity.nursingHomeUpdate(data);
		} else {
			dataUpdate = await updateEntity.clinicUpdate(data);
		}
		// console.log(`dataUpdate:\n${JSON.stringify(dataUpdate, null, 4)}`);

		if (dataUpdate.code === 200) {
			clearUpdateEntityForm(null);
			reset({
				legalName: null,
				comercialName: null,
				webPage: null,
				email: null,
				firstLline: null,
				secondLline: null,
				entityType: null,
				ein: '',
				npi: '',
				phoneTwo: '',
				phone: '',
				phoneExt: '',
				state: null,
				zipCode: '',
				country: null,
				pobox: '',
				poboxState: null,
				poboxZipCode: '',
				poboxCountry: null,
			});
			initUser({});
			navigate(`/entities/profile/information`);
		}
	};

	return (
		<div className="w-full space-y-7 ">
			<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<div className="flex flex-col md:flex-row gap-1">
					<div className="flex flex-row">
						<img src={Entities} className={`w-20`} alt="" />
						<h1 className="w-full self-center text-[#96c952] sm:text-4xl font-extrabold text-2xl ">
							Actualización de Entidad
						</h1>
					</div>
				</div>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
				<section className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col py-4">
					<EntityForm
						Controller={Controller}
						Select={Select}
						options={options}
						watch={watch}
						customSyles={customStyles}
						NumberFormat={NumberFormat}
						emailValidation={emailValidation}
						websiteValidation={websiteValidation}
						Switch={Switch}
						register={register}
						errors={errors}
						control={control}
						setIsRequired={setIsRequired}
						CustomValueContainer={CustomValueContainer}
						isRequired={isRequired}
					/>
					<div className="lg:col-span-2 xl:col-span-3 my-5">
						<input
							type={'button'}
							value="Volver"
							className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							onClick={() => {
								navigate(`/entities/profile`);
							}}
						/>
						<input
							type="submit"
							value="Actualizar"
							className="rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
						/>
					</div>
				</section>
			</form>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		updateEntityForm: state.updateEntityForm,
	};
};

const mapDispatchToProps = {
	clearUpdateEntityForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEntity);
// export default UpdateEntity;
