import React from 'react';
import { IdentificationIcon } from '@heroicons/react/outline';
import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg';

const Info = ({ dataProfile, ...props }: any) => {
	return (
		<>
			<div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
				{/* <img src={Allergy} className={`w-12`} alt="" /> */}
				<IdentificationIcon
					strokeWidth={1.3}
					className="w-12 h-12 text-[#294fa9]"
				/>
				<h1 className="text-sky-500 text-xl font-bold first-line:capitalize">
					{'Informacion Personal'}
				</h1>
			</div>
			<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
				<div className="w-fit md:ml-24 min-w-sm	 ">
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Estado de usuario: </p>
						<p>{dataProfile?.statusName ? dataProfile?.statusName : 'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Rol/es asignados: </p>
						<p>
							{dataProfile?.roles.map((item: any) => {
								return (
									<p key={item.id}>
										{item?.roleName}
										{dataProfile.length > 0 ? ', ' : ''}
									</p>
								);
							})}
						</p>
					</div>
					{dataProfile?.roles[0]?.roleName === 'Doctor' && (
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">NPI: </p>
							<p>{dataProfile?.npi ? dataProfile?.npi : 'N/A'}</p>
						</div>
					)}
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Fecha de nacimiento: </p>
						<p>
							{dataProfile?.birthday
								? new Date(dataProfile?.birthday).toISOString().substring(0, 10)
								: 'No encontrado'}
						</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Ocupacion/Especialidad: </p>
						<p>{dataProfile?.specialty ? dataProfile?.specialty : 'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Genero: </p>
						<p>{dataProfile?.genderName ? dataProfile?.genderName : 'N/A'}</p>
					</div>
				</div>
			</div>

			<div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
				<img src={InformationLaboral} className={`w-12`} alt="" />
				<h1 className="text-sky-500 text-xl font-bold first-line:capitalize">
					{'Información de contacto'}
				</h1>
			</div>
			<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
				<div className="w-fit md:ml-24 min-w-sm	 ">
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Correo electronico: </p>
						<p>{dataProfile?.email ? dataProfile?.email : 'N/A'}</p>
					</div>

					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Número de Celular:</p>
						<p>
							{dataProfile?.phones?.map((item: any) => {
								if (item?.isMobile === true) {
									return <p key={item.id}>{item?.number}</p>;
								} else {
									return 'N/A';
								}
							})}
						</p>
					</div>

					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Telefono: </p>
						<p>
							{dataProfile?.phones?.map((item: any) => {
								if (item?.isMobile === false) {
									return (
										<p>
											{`${item?.number ? item?.number : 'N/A'} `}
											<b className="text-sky-500 font-bold ">Ext: </b>
											{item?.extension ? ` ${item?.extension}` : 'N/A'}
										</p>
									);
								} else {
									return 'N/A';
								}
							})}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Info;
