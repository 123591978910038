import api from './Api';
import Swal from 'sweetalert2';
import TokenService from './Token';

export const CreateCalendar = async (params: any) => {
	const user = TokenService.getUser();
	const userid = TokenService.getUserIdAction();

	let {
		timeBeginAt,
		timeEndAt,
		dateBeginAt,
		dateFinishAt,
		expectedDuration,
		maxCapacity,
		doctorId,
		statusCalendarType,
		statusAssistanceType,
		nursingHomeId,
		isSunday,
		isMonday,
		isTuesday,
		isWednesday,
		isThursday,
		isFriday,
		isSaturday,
	} = params;

	console.log(`Raw `, params);

	// dateBeginAt = new Date(dateBeginAt).toISOString().substring(0, 10);
	// dateFinishAt = new Date(dateFinishAt).toISOString().substring(0, 10);
	// timeBeginAt = new Date(timeBeginAt).toISOString().substring(11, 16);
	// timeEndAt = new Date(timeEndAt).toISOString().substring(11, 16);

	dateBeginAt = new Date(dateBeginAt).toISOString();
	dateFinishAt = new Date(dateFinishAt).toISOString();
	timeBeginAt = new Date(timeBeginAt).toISOString().substring(11, 16);
	timeEndAt = new Date(timeEndAt).toISOString().substring(11, 16);
	nursingHomeId = nursingHomeId?.value;
	statusAssistanceType = statusAssistanceType.value;
	statusCalendarType = statusCalendarType.value;
	doctorId = doctorId.value;
	maxCapacity = parseInt(maxCapacity);

	let formatedData = {
		created_by: userid,
		timeBeginAt,
		timeEndAt,
		dateBeginAt,
		dateFinishAt,
		expectedDuration,
		maxCapacity,
		doctorId,
		statusCalendarType,
		statusAssistanceType,
		nursingHomeId,
		days: [
			{
				day: isSunday ? 'Domingo' : null,
			},
			{
				day: isMonday ? 'Lunes' : null,
			},
			{
				day: isTuesday ? 'Martes' : null,
			},
			{
				day: isWednesday ? 'Miercoles' : null,
			},
			{
				day: isThursday ? 'Jueves' : null,
			},
			{
				day: isFriday ? 'Viernes' : null,
			},
			{
				day: isSaturday ? 'Sabado' : null,
			},
		],
	};

	console.log(`Formated Data `, formatedData);

	return await api.instanceEvents
		.post('/calendar/createCalendar', formatedData, {
			headers: {
				Authorization: `${user.TokenType} ${user.IdToken}`,
				USER_ENTITY_ID: `${TokenService.getRole()}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then((res) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'El calendario fue creado correctamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return res;
		})
		.catch((err) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Vuelva a intertarlo nuevamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return err;
		});
};
