/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer';
import { emailValidation, npiValidation } from '../../utils/regexExpresion';
import UpdateUserService from '../../services/updateUserProfile';
import { clearUpdateProfileUserForm } from '../../actions';
import { PencilAltIcon } from '@heroicons/react/outline';
import { CalendarIcon } from '@heroicons/react/outline';
import useGetEntityII from '../../hooks/useGetEntityII';
import { Controller, useForm } from 'react-hook-form';
import { customStyles } from '../customStyleNewLabel';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import options from '../../data/selectOptions';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import FormUser from './Form';

const UpdateUser = ({ ...props }: any) => {
	const [stateData, setStateData] = useState<any>(null);
	const [isRequiredNPI, setIsRequiredNPI] = useState(false);
	const [onlySpecialty, setOnlySpecialty] = useState(false);
	const [withoutInstitution, setWithoutInstitution] = useState(false);
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		reset,
		watch,
		control,
		formState: { errors, isValid, isDirty },
	} = useForm({ mode: 'onChange' });

	// console.log(props?.updateProfileUserForm?.country);

	const userTypeSelected = (value: any) => {
		const DAEADS = [1, 6, 7, 8];
		const ADS = [1, 8];
		const DAE = [9, 10, 11];

		if (DAEADS.includes(value?.value)) {
			setIsRequiredNPI(true);
		} else {
			setIsRequiredNPI(false);
		}

		if (ADS.includes(value?.value)) {
			setOnlySpecialty(true);
		} else {
			setOnlySpecialty(false);
		}

		if (DAE.includes(value?.value)) {
			setWithoutInstitution(true);
		} else {
			setWithoutInstitution(false);
		}
		return value;
	};

	const onSubmit = async (data: any) => {
		data.id = props?.updateProfileUserForm?.id;
		data.clinic_id = props?.updateProfileUserForm?.roles[0]?.clinicId;
		data.nursinghome_id = props?.updateProfileUserForm?.roles[0]?.nursingHomeId;
		data.phone = [
			{
				phoneId: props?.updateProfileUserForm?.phones[0]?.id,
				number: data.phone,
				isMobile: true,
				extension: data.phoneExt,
			},
		];
		data.userEntityId = props?.updateProfileUserForm?.roles[0].id;
		await UpdateUserService.UpdateUserProfile(data);
		clearUpdateProfileUserForm(null);
		reset({
			user_type: {},
			firstname: '',
			middlename: '',
			surname: '',
			second_surname: '',
			email: '',
			gender: {},
			birthday: '',
			phone: '',
			phoneExt: '',
			isMobilePatient: '',
			specialty: '',
			countryOrigin: {},
			municipalState: {},
		});
		setStateData({});
		navigate(`/userprofile`, { state: {} });
	};

	useEffect(() => {
		if (stateData === null) {
			setTimeout(() => {
				setStateData({
					user_type: options.roleList
						.filter((item: any) => {
							if (
								item?.value === props?.updateProfileUserForm?.roles[0].roleId
							) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						}),
					firstname: props?.updateProfileUserForm?.firstname,
					middlename: props?.updateProfileUserForm?.middlename,
					surname: props?.updateProfileUserForm?.surname,
					second_surname: props?.updateProfileUserForm?.secondSurname,
					email: props?.updateProfileUserForm?.email,
					gender: options.genderOptions
						.filter((c: any) => {
							if (c.value === props?.updateProfileUserForm?.genderId) {
								return c;
							}
						})
						.map((c: any) => {
							return c;
						}),
					birthday: new Date(props?.updateProfileUserForm?.birthday),
					phone: props?.updateProfileUserForm?.phones[0]?.number,
					phoneExt: props?.updateProfileUserForm?.phones[0]?.extension,
					isMobilePatient: props?.updateProfileUserForm?.phones[0]?.isMobile,
					specialty: props?.updateProfileUserForm?.specialty,
					npi: props?.updateProfileUserForm?.npi,
					// countryOrigin: props?.updateProfileUserForm?.country,
					countryOrigin: options.country
						.filter((c: any) => {
							if (c.value === parseInt(props?.updateProfileUserForm?.country)) {
								// console.log(c);
								return c;
							}
						})
						.map((c: any) => {
							return c;
						}),
					// municipalState: props?.updateProfileUserForm?.city,
					municipalState: options.city
						.filter((c: any) => {
							// console.log(c);
							if (c.value === parseInt(props?.updateProfileUserForm?.city)) {
								return c;
							}
						})
						.map((c: any) => {
							// console.log(c);
							return c;
						}),
				});
			}, 1000);
		}
	});

	useEffect(() => {
		reset(stateData);
	}, [stateData]);

	const { homeList, clinicList, error, loading } = useGetEntityII();
	return (
		<div className="w-full space-y-7 ">
			<form onSubmit={handleSubmit(onSubmit)}>
				<section className="block">
					<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
						<PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
						<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
							Editando Perfil de Usuario
						</h2>
					</div>
					<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col p-7">
						<FormUser
							Controller={Controller}
							DatePicker={DatePicker}
							CalendarIcon={CalendarIcon}
							Select={Select}
							customStyles={customStyles}
							options={options}
							NumberFormat={NumberFormat}
							emailValidation={emailValidation}
							npiValidation={npiValidation}
							Switch={Switch}
							userTypeSelected={userTypeSelected}
							register={register}
							handleSubmit={handleSubmit}
							errors={errors}
							control={control}
							isRequiredNPI={isRequiredNPI}
							onlySpecialty={onlySpecialty}
							withoutInstitution={withoutInstitution}
							onSubmit={onSubmit}
							CustomValueContainer={CustomValueContainer}
							mode={'update'}
							homeList={homeList}
							clinicList={clinicList}
							loading={loading}
							watch={watch}
						/>
						<div className="lg:col-span-2 xl:col-span-3 my-5">
							<input
								type="submit"
								value="Actualizar Usuario"
								className="mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
							/>
						</div>
					</div>
				</section>
			</form>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		updateProfileUserForm: state.updateProfileUserForm,
	};
};
const mapDispatchToProps = {
	clearUpdateProfileUserForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
