/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import api from '../services/Api';

const useGetEntityRelations = (values: any) => {
	console.log(values);

	const [entityList, setEntityList] = useState<any>(null);
	useEffect(() => {
		const fetchdata = async () => {
			let user: any = localStorage.getItem('user');

			//Home <==> Clinic

			if (values?.clinicId === undefined) {
				await api.instanceEntities
					.post(
						`/nursing_home_clinic/home-for-clinic/`,
						{ nursingHomeId: values?.nursingHomeId },
						{
							headers: {
								Authorization: `${JSON.parse(user).TokenType} ${
									JSON.parse(user).IdToken
								}`,
								'Content-Type': 'application/json',
							},
							withCredentials: true,
						},
					)
					.then((response: any) => {
						setEntityList(response?.data.data[0]);
						// console.log(response?.data.data[0][0]);
					})
					.catch((err) => {
						setEntityList(null);
						//console.log(err.response);
						//console.log(err?.response?.status);
						if ([401].includes(err?.response?.status) && entityList === null) {
							fetchdata();
						}
					});
			}
			if (values?.nursingHomeId === undefined) {
				//Clinic <==> Home
				await api.instanceEntities
					.post(
						`/nursing_home_clinic/clinic-for-home/`,
						{ clinicId: values?.clinicId },
						{
							headers: {
								Authorization: `${JSON.parse(user).TokenType} ${
									JSON.parse(user).IdToken
								}`,
								'Content-Type': 'application/json',
							},
							withCredentials: true,
						},
					)
					.then((response: any) => {
						setEntityList(response?.data.data[0]);
						// console.log(response?.data.data[0][0]);
					})
					.catch((err) => {
						setEntityList(null);
						//console.log(err.response);
						//console.log(err?.response?.status);
						if ([401].includes(err?.response?.status) && entityList === null) {
							fetchdata();
						}
					});
			}
		};

		fetchdata();
	}, []);
	return { entityList };
};

export default useGetEntityRelations;
