/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { connect } from 'react-redux';
import { clearUpdatePatientAllerProfCondForm } from '../../actions';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Select, { components } from 'react-select';
import React, { useEffect, useState } from 'react';
import useGetAllergyConditionSurgery from '../../hooks/useGetAllergyConditionSurgery';
import { customStyles } from '../customStyleNewLabel';
import updatePatientASC from '../../services/updatePatientASC';
import AllergyForm from '../RegisterPatient/ConditionAllergySurgery/Allergy';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';

const UpdateAllergyForm = (props: any) => {
	const [user, initUser] = useState<any>(null);

	const navigate = useNavigate();
	//console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
	const {
		register,
		watch,
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
	} = useForm({
		// resolver: yupResolver(schema),
		mode: 'onChange',
		defaultValues: {
			allergy: [{}],
		},
	});
	let {
		listData: acsListData,
		error: acsError,
		isLoading: acsIsLoading,
	}: any = useGetAllergyConditionSurgery();

	const {
		fields: allergyFields,
		append: allergyAppend,
		remove: allergyRemove,
	} = useFieldArray({
		name: 'allergy',
		control,
	});

	//console.log(`props:\n${JSON.stringify(props, null, 4)}`);
	//console.log(`acsListData:\n${JSON.stringify(acsListData, null, 4)}`);

	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				initUser({
					description: props?.updatePatientAllerProfCondForm?.description,
					item: acsListData.allergies
						.filter((item: any) => {
							if (item?.value === props?.updatePatientAllerProfCondForm?.id) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],
				});
			}, 1000);
		}
	});

	//console.log(`user: \n${JSON.stringify(user)}`);
	useEffect(() => {
		reset({ allergy: [user] });
	}, [user]);

	const onSubmit = async (values: any) => {
		values.allergy[0].id = props?.updatePatientAllerProfCondForm?.patient;
		values.allergy[0].afectionId = props?.updatePatientAllerProfCondForm?.pcmid;
		const dataUpdate: any = await updatePatientASC.UpdatePatientASC(
			values.allergy[0],
		);
		if (dataUpdate.code === 200) {
			//alert(JSON.stringify(values, null, 4));
			clearUpdatePatientAllerProfCondForm(null);
			reset({
				allergy: [
					{
						description: '',
						item: {},
					},
				],
			});

			initUser({});
			navigate(
				`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
			);
		} else {
			navigate(
				`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
			);
		}
	};
	const trashSubmit = async (values: any) => {
		//console.log(`values ${JSON.stringify(values, null, 4)}`);
		const dataUpdate: any = await updatePatientASC.DeletePatientASC(values);
		if (dataUpdate.code === 200) {
			navigate(
				`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
			);
		} else {
			navigate(
				`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
			);
		}
	};

	const { ValueContainer, Placeholder } = components;

	const CustomValueContainer = ({ children, ...props }: any) => {
		return (
			<ValueContainer {...props}>
				<Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
					child && child.type !== Placeholder ? child : null,
				)}
			</ValueContainer>
		);
	};

	return (
		<div className="w-full space-y-4">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					<PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
					<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
						Editando Alergia
					</h2>
				</div>
				<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7  p-7">
					<AllergyForm
						Controller={Controller}
						control={control}
						Select={Select}
						register={register}
						allergyFields={allergyFields}
						allergyAppend={allergyAppend}
						allergyRemove={allergyRemove}
						acsListData={acsListData}
						acsError={acsError}
						acsIsLoading={acsIsLoading}
						customStyles={customStyles}
						CustomValueContainer={CustomValueContainer}
						mode={'update'}
					/>

					<div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<input
							type="button"
							value="Volver"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							onClick={() => {
								navigate(
									`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
								);
							}}
						/>
						<input
							type="submit"
							value="Guardar"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
						/>
						<div
							className="md:w-32 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() =>
								trashSubmit(props?.updatePatientAllerProfCondForm)
							}>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
const mapStateToProps = (state: any) => {
	return {
		updatePatientAllerProfCondForm: state.updatePatientAllerProfCondForm,
	};
};
const mapDispatchToProps = {
	clearUpdatePatientAllerProfCondForm,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAllergyForm);
