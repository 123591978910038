import Conditions from '../../assets/svg/PatientItems/Conditions.svg';
import { PencilAltIcon } from '@heroicons/react/outline';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUpdatePatientAllerProfCondForm } from '../../actions';

const ConditionPatient = (props: any) => {
	const { mane, type, description, id, pcmid, patient } = props;

	const navigate = useNavigate();

	return (
		<React.Fragment>
			<div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 ">
				{/* <div className="flex flex-col text-center"> */}

				<div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
					<img src={Conditions} className={`w-12`} alt="" />
					<h1 className="text-xl font-bold">
						<p className="text-black font-bold first-line:capitalize">{mane}</p>
					</h1>
				</div>

				{/* <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
					<div className="w-fit md:ml-24 min-w-sm	 md:max-w-md	"> */}
				<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
					<div className="w-fit md:ml-24 min-w-sm	 ">
						{/* <div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Condición: </p>
							<p>{mane}</p>
						</div> */}
						{/* <div className="flex flex-col text-center pb-3"> */}
						<div className="md:gap-1.5 md:flex md:flex-row max-w-md	">
							<p className="text-sky-500 font-bold ">Descripcción: </p>
							<p>{description ? description : 'N/A'}</p>
						</div>
					</div>
				</div>
				<div className="flex flex-row ">
					<div className={`lg:flex-1 flex flex-row`} />
					<div
						className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
						onClick={() => {
							navigate('/patientCond');
							props.setUpdatePatientAllerProfCondForm({
								mane,
								type,
								description,
								id,
								pcmid,
								patient,
							});
						}}>
						<PencilAltIcon className="w-8 h-8 " />
						<div>Editar datos</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
const mapDispatchToProps = {
	setUpdatePatientAllerProfCondForm,
};

export default connect(null, mapDispatchToProps)(ConditionPatient);
//className="text-xl font-bold"
