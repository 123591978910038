/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useForm, Controller } from 'react-hook-form';
import authService from '../services/Authentication';
import React, { useRef, useState } from 'react';

const ChangePassword = () => {
	const [passwordShown, setPasswordShown] = useState(false);
	const [passwordShown2, setPasswordShown2] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isDirty },
		control,
		watch,
	} = useForm({ mode: 'onChange' });
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};
	const togglePasswordVisiblity2 = () => {
		setPasswordShown2(passwordShown2 ? false : true);
	};
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const password = useRef({});
	password.current = watch('password', '');

	const onSubmit = async (data: any) => {
		try {
			const response: any = await authService.changepass({
				email: data.email,
				new_password: data.password,
				session: state.session,
			});
			const IdToken = response.data[0].IdToken
				? response.data[0].IdToken
				: false;
			const RefreshToken = response.data[0].RefreshToken
				? response.data[0].RefreshToken
				: false;
			const AccessToken = response.data[0].AccessToken
				? response.data[0].AccessToken
				: false;

			if (response.code === 200 && IdToken && RefreshToken && AccessToken) {
				navigate('/login');
			}
			// if (response.code === 200 && IdToken && RefreshToken && AccessToken) {
			// 	navigate('/home', { replace: true });
			// 	localStorage.setItem('token', IdToken);
			// 	window.location.reload();
			// } else if (response.code === 200 && response.data[0].result === false) {
			// 	console.log('Error al intentar iniciar sesión');
			// }
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="w-full space-y-7 p-7">
			<form onSubmit={(e) => e.preventDefault()}>
				<section className="block space-y-6">
					<h2 className="font-semibold text-center mb-2">
						Cambio de contraseña
					</h2>

					<div className="lg:w-fit mx-auto  gap-3 place-items-center flex flex-col">
						<div className="w-11/12 lg:w-96 h-full">
							<label
								className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
								htmlFor="email"
							>
								<input
									disabled
									type="text"
									id="email"
									className="w-full h-10 focus:outline-none bg-transparent"
									placeholder={'Correo electrónico'}
									{...register('email', {
										required: {
											value: true,
											message: 'No se capturo el correo apartir de la sesion',
										},
										value: state.email,
										// , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
									})}
								/>
							</label>
							{errors.email && (
								<p className="text-sm text-red-600 mt-2">
									{errors.email.message}
								</p>
							)}
						</div>
						<div className="w-11/12 lg:w-96 h-full">
							<label
								className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
								htmlFor="password"
							>
								<input
									type={passwordShown ? 'text' : 'password'}
									id="password"
									className="w-full h-10 focus:outline-none bg-transparent"
									placeholder="Nueva contraseña"
									{...register('password', {
										pattern: {
											value:
												/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
											message:
												'Contraseña debe contener almenos una letra mayuscula, una minuscula, un Número y un caracter especial',
										},
										maxLength: {
											value: 12,
											message: 'Contraseña tiene como maximo 12 caracteres',
										},
										minLength: {
											value: 8,
											message: 'Contraseña tiene como minimo 8 caracteres',
										},
										required: {
											value: true,
											message: 'Las contraseñas no coinciden',
										},
										// , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
									})}
								/>
								{!passwordShown ? (
									<EyeIcon
										onClick={togglePasswordVisiblity}
										className="h-5 md:h-7 hover:cursor-pointer"
									/>
								) : (
									<EyeOffIcon
										onClick={togglePasswordVisiblity}
										className="h-5 md:h-7 hover:cursor-pointer"
									/>
								)}
							</label>
							{errors.password && (
								<p className="text-sm text-red-600 mt-2">
									{errors.password.message}
								</p>
							)}
						</div>
						<div className="w-11/12 lg:w-96 h-full">
							<label
								className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
								htmlFor="new_password"
							>
								<input
									type={passwordShown2 ? 'text' : 'password'}
									id="new_password"
									className="w-full h-10 focus:outline-none bg-transparent"
									placeholder="Repetir Contraseña"
									{...register('new_password', {
										pattern: {
											value:
												/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
											message:
												'Contraseña debe contener almenos una letra mayuscula, una minuscula, un Número y un caracter especial',
										},
										maxLength: {
											value: 12,
											message: 'Contraseña tiene como maximo 15 caracteres',
										},

										validate: (value) =>
											value === password.current ||
											'Las contraseñas no coinciden',
										required: {
											value: true,
											message: 'Las contraseñas no coinciden',
										},

										// , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
									})}
								/>
								{!passwordShown2 ? (
									<EyeIcon
										onClick={togglePasswordVisiblity2}
										className="h-5 md:h-7 hover:cursor-pointer"
									/>
								) : (
									<EyeOffIcon
										onClick={togglePasswordVisiblity2}
										className="h-5 md:h-7 hover:cursor-pointer"
									/>
								)}
							</label>
							{errors.new_password && (
								<p className="text-sm text-red-600 mt-2">
									{errors.new_password.message}
								</p>
							)}
						</div>

						<div className="lg:col-span-2 xl:col-span-3 my-5">
							<input
								type="submit"
								onClick={handleSubmit(onSubmit)}
								value="Cambiar contraseña"
								className="mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
							/>
						</div>
					</div>
				</section>
			</form>
		</div>
	);
};

export default ChangePassword;
