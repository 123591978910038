import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUpdatePatientAllerProfCondForm } from '../../actions';
import Allergy from '../../assets/svg/PatientItems/alergia.svg';

const AllergyPatient = (props: any) => {
	const { mane, type, description, id, pcmid, patient } = props;

	const navigate = useNavigate();
	return (
		<div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 ">
			<div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
				<img src={Allergy} className={`w-12`} alt="" />
				<h1 className="text-xl font-bold">
					<b className="text-black font-bold first-line:capitalize">{mane}</b>
				</h1>
			</div>
			<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
				<div className="w-fit md:ml-24 min-w-sm	 ">
					{/* <div className="md:gap-1.5 md:flex md:flex-row">
						<b className="text-sky-500 font-bold">Alergia:</b>
						{mane}
					</div> */}
					<div className="md:gap-1.5 md:flex md:flex-row max-w-md	">
						<b className="text-sky-500 font-bold">Descripcción:</b>
						<p>{description ? description : 'N/A'}</p>
					</div>
				</div>
			</div>

			<div className="flex flex-row ">
				<div className={`lg:flex-1 flex flex-row`} />
				<div
					className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
					onClick={() => {
						navigate('/allergyupdate');
						props.setUpdatePatientAllerProfCondForm({
							mane,
							type,
							description,
							id,
							pcmid,
							patient,
						});
					}}>
					<PencilAltIcon className="w-8 h-8 border-b-2" />
					<div>Editar datos</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	setUpdatePatientAllerProfCondForm,
};
export default connect(null, mapDispatchToProps)(AllergyPatient);
