import React from 'react';

const AppointmentForm = ({
	Controller,
	control,
	Select,
	CustomValueContainer,
	customStyles,
	errors,
	isLoadingUser,
	dataUser,
	dataPhysicianList,
	DatePicker,
	register,
	options,
	obj,
	isObject,
	watch,
}: any) => {
	return (
		<>
			<div className="w-11/12 lg:w-72 h-full">
				<Controller
					control={control}
					name={`appointmentType`}
					rules={{
						required: {
							value: true,
							message: 'Tipo de cita es requerido',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							components={{
								ValueContainer: CustomValueContainer,
							}}
							isClearable
							placeholder="Tipo de Cita..."
							styles={customStyles}
							ref={ref}
							onBlur={onBlur}
							onChange={onChange}
							value={value}
							options={[
								{ value: 'Cita', label: 'Cita' },
								{ value: 'Turno', label: 'Turno' },
							]}
						/>
					)}
				/>
				{errors.appointmentType && (
					<p className="text-sm text-red-600 mt-2">
						{errors.appointmentType.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full">
				<Controller
					control={control}
					name={`patientUserEntityId`}
					render={({ field: { onChange, onBlur, value, ref } }: any) => {
						obj = isObject;

						let results: any[] = [];

						if (obj[0]?.value >= 0) {
							value = obj;
							results = obj;
						}

						return (
							<Select
								components={{
									ValueContainer: CustomValueContainer,
								}}
								isClearable
								placeholder={`${
									isLoadingUser
										? 'Cargando Pacientes...'
										: 'Seleccione Paciente...'
								}`}
								styles={customStyles}
								onBlur={onBlur}
								onChange={onChange}
								ref={ref}
								value={value}
								options={results.length > 0 ? results : dataUser}
								isDisabled={results.length > 0 ? true : isLoadingUser}
							/>
						);
					}}
				/>
				{errors.patientUserEntityId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.patientUserEntityId.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full">
				<Controller
					control={control}
					name={`doctorUserEntityId`}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							ref={ref}
							components={{
								ValueContainer: CustomValueContainer,
							}}
							isClearable
							placeholder={`${
								isLoadingUser ? 'Cargando Medicos...' : 'Seleccione Medico...'
							}`}
							styles={customStyles}
							onBlur={onBlur}
							onChange={onChange}
							value={value}
							options={dataPhysicianList}
							isDisabled={isLoadingUser}
						/>
					)}
				/>
				{errors.doctorUserEntityId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.doctorUserEntityId.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full">
				<label
					className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					htmlFor="beginAt">
					<Controller
						control={control}
						{...register('beginAt', {
							required: {
								value: true,
								message: 'Tiempo inicial es requerido',
							},
							// , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
						})}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<DatePicker
								placeholderText="Tiempo inicial"
								className="w-full focus:outline-none bg-transparent"
								selected={value}
								timeIntervals={15}
								onChange={onChange}
								onBlur={onBlur}
								ref={ref}
								showTimeSelect
								timeFormat="HH:mm"
								dateFormat="MMMM d, yyyy h:mm aa"
							/>
						)}
					/>
				</label>
				{errors.beginAt && (
					<p className="text-sm text-red-600 mt-2">{errors.beginAt.message}</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full">
				<label
					className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					htmlFor="endAt">
					<Controller
						control={control}
						{...register('endAt', {
							required: {
								value: true,
								message: 'Tiempo final es requerido',
							},
							// , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
						})}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<DatePicker
								placeholderText="Tiempo final"
								className="w-full focus:outline-none bg-transparent"
								selected={value}
								timeIntervals={15}
								onChange={onChange}
								ref={ref}
								onBlur={onBlur}
								showTimeSelect
								timeFormat="HH:mm"
								dateFormat="MMMM d, yyyy h:mm aa"
							/>
						)}
					/>
				</label>
				{errors.endAt && (
					<p className="text-sm text-red-600 mt-2">{errors.endAt.message}</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full relative">
				<textarea
					id="description"
					className="h-10 p-2 bg-transparent peer w-full rounded-md border-[1px] border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-sky-500 focus:ring-1 hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					placeholder="Descripcion de la cita"
					{...register('description', {
						required: {
							value: true,
							message: 'Se requiere una descripcion',
						},
					})}
				/>
				<label
					className="absolute left-2 -top-2 bg-white px-2 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2 peer-focus:text-gray-600 peer-focus:text-sm"
					htmlFor="description">
					Descripccion de la cita
				</label>
				{errors.description && (
					<p className="text-sm text-red-600 mt-2">
						{errors.description.message}
					</p>
				)}
			</div>

			<div
				className={` w-11/12 lg:w-72 h-full ${
					watch('appointmentType')?.value === 'Cita' ? `hidden` : ``
				} relative`}>
				<input
					id="orderAttention"
					type={`number`}
					className="h-10 p-2 peer w-full rounded-md border-[1px] border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-sky-500 focus:ring-1 hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					placeholder="Orden de atencion"
					{...register('orderAttention', {
						required: {
							value: watch('appointmentType')?.value === 'Cita' ? false : true,
							message: 'Se requiere una descripcion',
						},
					})}
				/>
				<label
					htmlFor="orderAttention"
					className="absolute left-2 -top-2 bg-white px-2 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2 peer-focus:text-gray-600 peer-focus:text-sm">
					Orden de atencion
				</label>
				{errors.orderAttention && (
					<p className="text-sm text-red-600 mt-2">
						{errors.orderAttention.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full relative">
				<input
					type="text"
					id="tytocareId"
					className="h-10 p-2 peer w-full rounded-md border-[1px] border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-sky-500 focus:ring-1 hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					placeholder="ID tytoCare"
					{...register('tytocareId', {
						required: {
							value: true,
							message: 'TytoCareId es requerido',
						},
					})}
				/>
				<label
					className="absolute left-2 -top-2 bg-white px-2 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2 peer-focus:text-gray-600 peer-focus:text-sm"
					htmlFor="tytocareId">
					ID tytoCare
				</label>
				{errors.tytocareId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.tytocareId.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full relative">
				<input
					type="text"
					id="insuranceCarrier"
					className="h-10 p-2 peer w-full rounded-md border-[1px] border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-sky-500 focus:ring-1 hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					placeholder="Seguro Médico"
					// disabled={isObject.length > 0 ? true : isLoadingUser}
					{...register('insuranceCarrier', {
						// value: valueInsurance,
						required: {
							value: true,
							message: 'Seguro Médico es requerido',
						},
					})}
				/>
				<label
					className="absolute left-2 -top-2 bg-white px-2 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2 peer-focus:text-gray-600 peer-focus:text-sm"
					htmlFor="insuranceCarrier">
					Seguro Médico
				</label>
				{errors.insuranceCarrier && (
					<p className="text-sm text-red-600 mt-2">
						{errors.insuranceCarrier.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full">
				<Controller
					control={control}
					name={`statusPriorityId`}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							components={{
								ValueContainer: CustomValueContainer,
							}}
							isClearable
							placeholder="Seleccione Prioridad..."
							styles={customStyles}
							onBlur={onBlur}
							onChange={onChange}
							ref={ref}
							value={value}
							options={options.priorities}
						/>
					)}
				/>
				{errors.statusPriorityId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.statusPriorityId.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-72 h-full">
				<Controller
					control={control}
					name={`statusAssistanceTypeId`}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							components={{
								ValueContainer: CustomValueContainer,
							}}
							isClearable
							placeholder="Seleccione asistencia..."
							styles={customStyles}
							onBlur={onBlur}
							onChange={onChange}
							ref={ref}
							value={value}
							options={options.assistance}
						/>
					)}
				/>
				{errors.statusAssistanceTypeId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.statusAssistanceTypeId.message}
					</p>
				)}
			</div>
		</>
	);
};

export default AppointmentForm;
