/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	BanIcon,
	LinkIcon,
	OfficeBuildingIcon,
	PencilIcon,
	SearchIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select, { components, OptionProps } from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { customStyles } from '../customStyleNewLabel';
import UnLinkConfirmation from '../Modals/UnLinkConfirmation';
import useGetUserEntity from '../../hooks/useGetUserEntity';
import options from '../../data/selectOptions';

const ManageUser = ({ ...props }: any) => {
	const [showModal, setShowModal] = useState<any>(false);
	const [userEntityList, setUserEntityList] = useState<any>(null);

	const { listData, error, isLoading } = useGetUserEntity({
		clinic: props?.state?.updateEntityForm?.clinic_id,
		home: props?.state?.updateEntityForm?.nursing_home_id,
	});

	useEffect(() => {
		if (userEntityList === null) {
			setTimeout(() => {
				setUserEntityList(listData);
			}, 1000);
		}
	});

	const navigate = useNavigate();
	const { control } = useForm({
		mode: 'onChange',
	});
	return (
		<div className="w-full h-screen">
			<div className="w-full h-fit shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<OfficeBuildingIcon
					color="#294fa9"
					className="h-[5rem] w-[5rem] self-center rounded-full bg-transparent "
				/>
				<div className="">
					<h1 className="my-5 justify-start md:text-4xl text-2xl font-semibold text-[#96c952]">
						{props?.state?.updateEntityForm?.clinic_comercialName ||
							props?.state?.updateEntityForm?.nursing_home_comercialName}
					</h1>
				</div>
			</div>

			<div className="flex flex-col gap-5 p-3 sm:p-7">
				<div className="w-full flex flex-col gap-5">
					<Controller
						control={control}
						name="patientSelect"
						rules={{
							required: {
								value: false,
								message: 'Nursing Home es requerida',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => {
							return (
								<Select
									className="w-full"
									placeholder={`Buscar uno de los usuarios asociados a su entidad`}
									ref={ref}
									isClearable
									backspaceRemovesValue
									styles={customStyles}
									onBlur={onBlur}
									onChange={onChange}
									options={[{ label: 'sdfgdfg' }]}
									value={value}
									isSearchable
								/>
							);
						}}
					/>
					<div className="w-full text-center">
						<button
							className={`w-fit px-4 py-1 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 cursor-pointer`}
							onClick={() => {
								navigate('/user/linkuser');
							}}>
							Vincular nuevos usuarios
						</button>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3">
					{/*Grupo entidades*/}

					{!isLoading ? (
						<div>Cargando...</div>
					) : (
						<>
							{userEntityList
								?.sort((a: any, b: any) => {
									if (a.firstname > b.firstname) {
										return 1;
									}
									if (a.firstname < b.firstname) {
										return -1;
									}
									return 0;
								})
								?.map((item: any) => {
									return (
										<div
											key={item?.id}
											className="bg-white flex flex-col gap-3 rounded-md border px-5 py-4 shadow-lg">
											<div className="flex flex-col">
												<div className="flex flex-row gap-x-1">
													<b>Rol:</b>
													<p>
														{options.roleList
															.filter((data: any) => {
																if (data?.value === item?.user_type_id) {
																	return data;
																}
															})
															?.map((data: any) => {
																return data.label;
															})}
													</p>
												</div>
												<div className="flex flex-row gap-x-1">
													<b>Nombre:</b>
													<p>{`${item?.us_firstname} ${
														item?.us_middlename ? item?.us_middlename : ''
													} ${item?.us_second_surname} ${
														item?.us_surname ? item?.us_surname : ''
													}`}</p>
												</div>
												<div className="flex flex-row gap-x-1">
													<b>correo:</b>
													<p>{`${item?.email ? item?.email : 'N/A'}`}</p>
												</div>
											</div>
											<div className="flex flex-row-reverse gap-3">
												<button
													className="px-4 py-2 rounded-xl border-[1px] font-bold bg-cyan-500 shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300"
													onClick={() => {
														navigate('/profile/update', {
															state: { id: item?.us_id },
														});
													}}>
													Editar
												</button>
												<button
													className="px-4 py-2 rounded-xl border-[1px] font-bold bg-[#f6501e] shadow-sm hover:cursor-pointer hover:bg-red-400 text-white hover:border-red-700 duration-300"
													onClick={() => {
														// setShowModal(true);
													}}>
													Desvincular
												</button>
											</div>
										</div>
									);
								})}
						</>
					)}
				</div>
			</div>
			<div className="w-full text-center">
				<button
					className={`w-fit px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
					onClick={() => {
						navigate('/entities/profile');
					}}>
					Volver
				</button>
			</div>
			{showModal && <UnLinkConfirmation setShowModal={setShowModal} />}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		state: {
			updateEntityForm: state.updateEntityForm,
		},
	};
};

const mapDispatchToProps = {};

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
