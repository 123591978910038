/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const AppointmenItem = (props: any) => {
	let { beginAt, endAt, patientFullName, orderAttention, description } = props;

	return (
		<div className="w-full flex p-3 border-2 shadow-md rounded-lg ">
			<div>
				<h1 className="font-bold">
					Asistencia {orderAttention > 0 ? 'Turno' : 'Cita'}
				</h1>
				<p className="font-extrabold">{patientFullName}</p>
				<p className="">
					<b>Fecha: </b>
					{new Date(beginAt).toISOString().substring(0, 10)}
					{/* {new Date(beginAt).toISOString().substring(11, 19)} -{" "}
          {new Date(endAt).toISOString().substring(11, 19)}{" "} */}
				</p>
				<p>
					<b>Hora: </b>
					{new Date(beginAt).toISOString().substring(11, 16)}
				</p>
				<p>
					<b>Descripccion: </b>
					{description}
				</p>
			</div>
			<div className="flex-1"></div>
			<input
				type="button"
				value="Ver"
				className="bg-white text-black h-fit rounded-lg px-4 py-2 shadow-md duration-300 border-[1px] font-semibold hover:cursor-pointer"
			/>
		</div>
	);
};

export default AppointmenItem;
