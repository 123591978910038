import { deserialize } from 'class-transformer';
import { FormatSelect } from './FormatSelect';

export const dataTransformer = (data: any) => {
	let counter: number = 0;
	let dataArray = data.map((item: any) => {
		return {
			value: (counter += 1),
			label: item.label,
			id: item.id,
			type: item.type,
			state: item?.state,
			ein: item?.ein,
			npi: item?.npi,
		};
	});

	let processData = deserialize(FormatSelect, JSON.stringify(dataArray));

	return processData;
};
