/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import service from '../../services/RegisterForms';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/outline';
import DatePicker from 'react-datepicker';
import NotesIcon from '../../assets/svg/notes-svgrepo-com.svg';

const CreateNote = () => {
	const [name, setName] = React.useState('');
	const location = useLocation();
	const { id, completeName }: any = location.state;
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty },
	} = useForm({
		mode: 'onChange',
	});

	const onSubmit = async (data: any) => {
		data.userId = parseInt(id);
		setIsLoading(true);
		const response = await service.addPatientNote(data);
		setIsLoading(false);
		// console.log('Registreishon: ', response);
		reset();
	};

	return (
		<div className="w-full h-full space-y-4">
			<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<div className="flex flex-col md:flex-row gap-1">
					<div className="flex flex-row gap-3">
						<img src={NotesIcon} className={`w-14 my-3`} alt="" />
						<h1 className="w-full self-center text-[#96c952] text-4xl font-extrabold ">
							{completeName}
						</h1>
					</div>
				</div>
			</div>

			<div className="p-4">
				<form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name="noteDate"
						rules={{
							required: {
								value: true,
								message:
									'No ha seleccionado un dia para encontrar las notas del residente',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<label
								htmlFor={`noteDate`}
								className="w-full rounded-md border-[1px] px-3 py-2 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]">
								<CalendarIcon className="w-6 h-6 mr-2" />
								<DatePicker
									autoComplete="off"
									id="noteDate"
									name="noteDate"
									className="w-full focus:outline-none bg-transparent"
									dateFormat="MM/dd/yyyy"
									placeholderText="Seleccione dia inicial..."
									onBlur={onBlur}
									selected={value}
									onChange={onChange}
									showYearDropdown
									yearDropdownItemNumber={1}
									scrollableYearDropdown
									value={value}
								/>
							</label>
						)}
					/>
					<div className="w-full h-full relative">
						<textarea
							id="note"
							name="note"
							{...register('note', {})}
							className="input-style-note peer"></textarea>
						<label className="input-style-label" htmlFor="note">
							Nota
						</label>
					</div>
					<div className="w-full flex gap-4 justify-center">
						<input
							type="button"
							value="Volver"
							className="mb-5 rounded-lg border-[1px] px-4 py-2 font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700  duration-300"
							onClick={() => {
								navigate(`/patient/listnote/${id}`, {
									state: { completeName },
								});
							}}
						/>
						{isLoading ? (
							<div className="mb-5 rounded-lg border-[1px] px-4 py-2 font-medium shadow-md duration-300 hover:cursor-pointer">
								Cargando...
							</div>
						) : (
							<input
								type="submit"
								value="Guardar Nota"
								className="mb-5 rounded-lg border-[1px] px-4 py-2 font-bold bg-sky-600 shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700  duration-300"
							/>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default CreateNote;
