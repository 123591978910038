/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import api from '../services/Api';

const UseInicializer = () => {
	useEffect(() => {
		const fetchdata = async () => {
			await api.instanceUsers.get(`/health/check`);
		};
		fetchdata();
	}, []);
	useEffect(() => {
		const fetchdata = async () => {
			await api.instanceEntities.get(`/health/check`);
		};
		fetchdata();
	}, []);
	useEffect(() => {
		const fetchdata = async () => {
			await api.instanceEvents.get(`/health/check`);
		};
		fetchdata();
	}, []);
};

export default UseInicializer;
