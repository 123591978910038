import { deserialize } from 'class-transformer';
import { FormatSelect } from './FormatSelect';

export const dataTransformer = (data: any) => {
	let i: number = 0;

	let dataArray = data.map((item: any) => {
		const obj = {
			key: i,
			id: item.user_id,
			fullname: `${item.firstname ? `${item.firstname} ` : ''}${
				item.middlename ? `${item.middlename} ` : ''
			}${item.surname ? `${item.surname} ` : ''}${
				item.secondsurname ? `${item.secondsurname}` : ''
			}`,
			Profile_birthday: new Date(item.birthday).toISOString().substring(0, 10),
			gender: item.gender,
			Profile_insurance_carrier: item.insurancecarrier,
			insurancecarrierii: item.insurancecarrierii,
			clinicalmedicalnh: item.clinicalmedicalnh,
			clinicalmedicalrecord: item.clinicalmedicalrecord,
		};

		i += 1;
		return obj;
	});

	let processData = deserialize(FormatSelect, JSON.stringify(dataArray));

	return processData;
};
