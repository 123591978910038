/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { useState, useEffect } from 'react';

const useGetMedicalCondition = (userid: any) => {
	const [data, setData] = useState({});
	const [error, setError] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchdata = async () => {
			setIsLoading(true);

			setIsLoading(false);
		};
	}, []);
	return { data, error, isLoading };
};

export default useGetMedicalCondition;
/**
 *       try {
        const { data: response } = await axios.post(
          `${API_URL_USERS}/user/get-conAllMed-patient/`,
          { id: userid },
          {
            headers: {
              Authorization: "${JSON.parse(user).TokenType} " + localStorage.getItem("token"),
              "Content-Type": "application/json",
              USER_ENTITY_ID: 1,
            },
            withCredentials: true,
          }
        );
        setData(response.data[0]);
      } catch (error: any) {
        //await RefreshToken(API_URL_USERS);
        // //fetchdata();
        setError(error);
      }
 */
