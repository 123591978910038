import { Expose } from 'class-transformer';

export class FormatSelect {
	@Expose({ name: 'id' })
	id: string | undefined | null;
	@Expose({ name: 'legalName' })
	legalname: string | undefined | null;
	@Expose({ name: 'comercialname' })
	comercialname: string | undefined | null;
	@Expose({ name: 'npi' })
	npi: string | undefined | null;
	@Expose({ name: 'ein' })
	ein: string | undefined | null;
	@Expose({ name: 'webPage' })
	webpage: string | undefined | null;
	@Expose({ name: 'email' })
	email: string | undefined | null;
}
