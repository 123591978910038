/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import api from './Api';
import Swal from 'sweetalert2';

const nursingHomeUpdate = async (data: any) => {
	let user: any = localStorage.getItem('user');

	console.log('raw: ', data);

	let {
		nursingHomeId,
		comercialName,
		npi,
		ein,
		webPage,
		email,
		phone,
		address,
		state,
		firstLline,
		secondLline,
		zipCode,
		pobox,
		//country,
		//legalName,
		phoneExt,
		isMobilePatient,
	} = data;

	state = state.label;
	npi = parseInt(npi);
	ein = parseInt(ein);
	phone = phone.replace(/(\D+)/g, '');
	//country = country.label;

	let obj = {
		nursingHomeId,
		comercialName,
		npi,
		ein,
		webPage,
		email,
		phone,
		address,
		state,
		firstLline,
		secondLline,
		zipCode,
		//pobox,
		//country,
		//legalName,
		phoneExt,
		isMobilePatient,
	};

	console.log('formated: ', obj);

	const response = await api.instanceEntities
		.put(`/nursing_home/update`, obj, {
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				// USER_ENTITY_ID: `${localStorage.getItem("role")}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then((response) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'Residente registrado con éxito',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return response.data;
		})
		.catch((error) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Vuelva a intertarlo nuevamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return error;
		});

	// console.log(response);

	return response;
};

const clinicUpdate = async (data: any) => {
	let user: any = localStorage.getItem('user');

	console.log('raw clinic: ', data);

	let {
		clinic_id,
		comercialName,
		npi,
		ein,
		webPage,
		email,
		phone,
		address,
		state,
		firstLline,
		secondLline,
		zipCode,
		//pobox,
		//country,
		//legalName,
		phoneExt,
		isMobilePatient,
	} = data;

	state = state.label;
	npi = parseInt(npi);
	ein = parseInt(ein);
	phone = phone.replace(/(\D+)/g, '');

	let obj = {
		clinicId: clinic_id,
		comercialName,
		npi,
		ein,
		webPage,
		email,
		phone,
		address,
		state,
		firstLline,
		secondLline,
		zipCode,
		//pobox,
		//country,
		//legalName,
		phoneExt,
		isMobilePatient,
	};

	console.log('formated clinic: ', obj);

	const response = await api.instanceEntities
		.put(`/clinic/update`, obj, {
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				// USER_ENTITY_ID: `${localStorage.getItem("role")}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then((response) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'Residente registrado con éxito',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return response.data;
		})
		.catch((error) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Vuelva a intertarlo nuevamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return error;
		});

	// console.log(response);

	return response;
};

export default {
	nursingHomeUpdate,
	clinicUpdate,
};
