/* eslint-disable @typescript-eslint/no-unused-vars */
import Select, { components } from 'react-select';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import registerService from '../../../services/RegisterForms';
import { customStyles } from '../../customStyleNewLabel';
import options from '../../../data/selectOptions';
import Swal from 'sweetalert2';
import useGetUserTypeI from '../../../hooks/useGetUserTypeI';
import AppointmentForm from './AppointmentForm';
import { ViewListIcon } from '@heroicons/react/outline';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
	return (
		<ValueContainer {...props}>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			{React.Children.map(children, (child) =>
				child && child.type !== Placeholder ? child : null,
			)}
		</ValueContainer>
	);
};

const CreateAppointment = () => {
	const [succesfull, setIsLoadingSuccess] = useState(false);
	const [isObject, setIsObject] = useState([{}]);
	const navigate = useNavigate();
	const { state }: any = useLocation();
	let obj: any;

	const {
		dataPatients: dataUser,
		dataPhysician: dataPhysicianList,
		error: errorUser,
		isLoading: isLoadingUser,
	}: any = useGetUserTypeI({ types: [{ id: 4 }, { id: 1 }] });

	useEffect(() => {
		if (state?.value >= 0) {
			setIsObject([state]);
		}
	}, [state]);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
		control,
		watch,
	} = useForm({});

	const onSubmit = async (data: any) => {
		if (obj[0].value > 0) {
			data.patientUserEntityId = obj[0];
		}
		setIsLoadingSuccess(true);

		const service = await registerService.createEvent(data);

		if (service?.code === 200) {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Confirmación',
				text: 'Cita creada con éxito',
				icon: 'success',
				cancelButtonText: 'Volver al formulario',
				confirmButtonText: 'Ver cita',
				showCancelButton: true,
				confirmButtonColor: 'rgb(14 165 233)',
				cancelButtonColor: 'rgb(14 165 233)',
			}).then((result) => {
				if (result.isConfirmed) {
					navigate(`/appointments`);
				} else {
					window.location.reload();
				}
			});
			setIsLoadingSuccess(false);
		} else {
			setIsLoadingSuccess(false);
		}
		setIsLoadingSuccess(false);

		// reset({});
	};

	return (
		<div className="w-full h-full space-y-7">
			<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<div className="flex flex-col md:flex-row gap-1">
					<div className="flex flex-row gap-3">
						<ViewListIcon className={`w-14 my-4`} />
						{/* <img src={ViewListIcon} className={`w-14 my-3`} alt="" /> */}
						<h1 className="w-full self-center text-[#96c952] text-4xl font-extrabold ">
							Crear evento
						</h1>
					</div>
				</div>
			</div>

			<form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
				<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-4 place-items-center flex flex-col">
					<AppointmentForm
						Controller={Controller}
						control={control}
						Select={Select}
						CustomValueContainer={CustomValueContainer}
						customStyles={customStyles}
						errors={errors}
						isLoadingUser={isLoadingUser}
						dataUser={dataUser}
						dataPhysicianList={dataPhysicianList}
						DatePicker={DatePicker}
						register={register}
						options={options}
						obj={obj}
						isObject={isObject}
						watch={watch}
					/>
					<div className="lg:col-span-2 xl:col-span-3 my-5 space-x-4">
						{state?.value >= 0 && (
							<input
								type="button"
								onClick={() => {
									navigate(`/patients`);
								}}
								value="Volver"
								className="lg:col-span-2 xl:col-span-3 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							/>
						)}

						{!succesfull ? (
							<input
								type="submit"
								value="Crear Cita"
								className="lg:col-span-2 xl:col-span-3 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
							/>
						) : (
							<input
								type="submit"
								value="Cargando..."
								disabled
								className="lg:col-span-2 xl:col-span-3 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed"
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default CreateAppointment;
