/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import options from '../../data/selectOptions';
import { customStyles } from '../customStyleNewLabel';
import { clearSpace, emailValidation } from '../../utils/regexExpresion';
import Switch from 'react-switch';
import InformationGeneral from '../../assets/svg/PatientItems/informacionRegistro.svg';
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg';
import Physicians from '../../assets/svg/PatientItems/Physicians.svg';
import { phoneLengthVerification } from '../../utils/phoneLengthVerification';

const StepOne = ({
	Controller,
	Select,
	DatePicker,
	control,
	register,
	errors,
	NumberFormat,
	CalendarIcon,
	ListNursing,
	CustomValueContainer,
	watch,
}: any) => {
	return (
		<React.Fragment>
			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
				<img src={InformationGeneral} className={`w-12`} alt="" />
				<h1 className="text-xl first-line:capitalize">{`Registro`}</h1>
			</div>
			<div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="nursingHomeId"
					rules={{
						required: {
							value: true,
							message: 'Nursing Home es requerida',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							name="nursingHomeId"
							id="nursingHomeId"
							ref={ref}
							isClearable
							className="w-full"
							placeholder="Nursing Home...*"
							styles={customStyles}
							onBlur={onBlur}
							onChange={onChange}
							components={{
								ValueContainer: CustomValueContainer,
							}}
							// options={[{ value: 1, label: "Nursing Home..." }]}
							options={ListNursing}
							value={value}
						/>
					)}
				/>
				{errors.nursingHomeId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.nursingHomeId.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="clinicalMedicalNH"
					className="input-style peer"
					placeholder="Número de residente*"
					{...register('clinicalMedicalNH', {
						required: {
							value: true,
							message: 'Número de residente es requerido',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="clinicalMedicalNH">
					Número de residente*
				</label>
				{errors.clinicalMedicalNH && (
					<p className="text-sm text-red-600 mt-2">
						{errors.clinicalMedicalNH.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="entryDate"
					rules={{
						required: {
							value: true,
							message: 'La fecha de ingreso es requerida',
						},
						validate: {
							validateDate: (date: Date) => {
								return date > new Date()
									? 'Fecha invalida, no debe ser superior a la actual'
									: null;
							},
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<label
							className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="entryDate">
							<DatePicker
								id="entryDate"
								name="entryDate"
								className="w-full focus:outline-none bg-transparent"
								dateFormat="MM/dd/yyyy"
								placeholderText="Fecha de Ingreso*"
								onBlur={onBlur}
								selected={value}
								isClearable
								onChange={onChange}
								showYearDropdown
								yearDropdownItemNumber={120}
								scrollableYearDropdown
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
							<CalendarIcon className="h-7" />
						</label>
					)}
				/>
				{errors.entryDate && (
					<p className="text-sm text-red-600 mt-2">
						{errors.entryDate.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="nursingHomeSourceId"
					rules={{
						required: {
							value: true,
							message: 'Nursing Home es requerida',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							id="nursingHomeSourceId"
							name="nursingHomeSourceId"
							className="w-full"
							placeholder="Hogar procedencia...*"
							styles={customStyles}
							onBlur={onBlur}
							isClearable
							ref={ref}
							onChange={onChange}
							options={[
								{ value: 'void', label: 'Ninguna' },
								{ value: 'other', label: 'Otro...' },
								...ListNursing,
							]}
							value={value}
							components={{
								ValueContainer: CustomValueContainer,
							}}
						/>
					)}
				/>
				{errors.nursingHomeSourceId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.nursingHomeSourceId.message}
					</p>
				)}
			</div>

			<div
				className={`w-11/12 lg:w-64 h-full ${
					watch('nursingHomeSourceId')?.value === 'other'
						? 'relative'
						: 'hidden'
				}`}>
				<input
					type="text"
					id="otherSource"
					name="otherSource"
					className="input-style peer"
					placeholder="Nombre del hogar"
					{...register('otherSource')}
				/>
				<label className="input-style-label" htmlFor="otherSource">
					Nombre del hogar
				</label>
			</div>

			{/* <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div> */}
			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
				<img src={InformationPersonal} className={`w-12`} alt="" />
				<h1 className="text-xl first-line:capitalize">{`Personal`}</h1>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="firstname"
					className="input-style peer"
					placeholder="Primer Nombre*"
					{...register('firstname', {
						required: {
							value: true,
							message: 'El Nombre es requerido',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="firstname">
					Primer Nombre*
				</label>
				{errors.firstname && (
					<p className="text-sm text-red-600 mt-2">
						{errors.firstname.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="middlename"
					className="input-style peer"
					placeholder="Segundo Nombre"
					{...register('middlename', {})}
				/>
				<label className="input-style-label" htmlFor="middlename">
					Segundo Nombre
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="surname"
					className="input-style peer"
					placeholder="Primer Apellido*"
					{...register('surname', {
						required: {
							value: true,
							message: 'El primer apellido es requerido',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="surname">
					Primer Apellido*
				</label>
				{errors.surname && (
					<p className="text-sm text-red-600 mt-2">{errors.surname.message}</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="second_surname"
					className="input-style peer"
					placeholder="Segundo Apellido"
					{...register('second_surname', {})}
				/>
				<label className="input-style-label" htmlFor="second_surname">
					Segundo Apellido
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="birthday"
					rules={{
						required: {
							value: true,
							message: 'La fecha de nacimiento es requerida',
						},
						validate: {
							validateDate: (date: Date) => {
								return date > new Date()
									? 'Fecha invalida, no debe ser superior a la actual'
									: null;
							},
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<label
							className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="birthday">
							<DatePicker
								id="birthday"
								name="birthday"
								className="w-full focus:outline-none bg-transparent"
								dateFormat="MM/dd/yyyy"
								placeholderText="Fecha de nacimiento*"
								onBlur={onBlur}
								selected={value}
								isClearable
								onChange={onChange}
								showYearDropdown
								yearDropdownItemNumber={120}
								scrollableYearDropdown
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
							<CalendarIcon className="h-7" />
						</label>
					)}
				/>
				{errors.birthday && (
					<p className="text-sm text-red-600 mt-2">{errors.birthday.message}</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="socialSecurityId"
					rules={{
						required: {
							value: true,
							message: 'El seguro social es requerido',
						},
						validate: {
							validateLength: (data: string) => {
								return data.replace(clearSpace, '').replace(' ', '').length < 11
									? `¡Error, debe tener los 9 dígitos!`
									: null;
							},
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="socialSecurityId"
							name="socialSecurityId"
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							placeholder="Seguro social*"
							displayType={'input'}
							format="###-##-####"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="socialSecurityId">
					{' '}
					Seguro social*
				</label>
				{errors.socialSecurityId && (
					<p className="text-sm text-red-600 mt-2">
						{errors.socialSecurityId.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<label htmlFor="countryOrigin">
					<Controller
						control={control}
						name="countryOrigin"
						rules={{
							required: {
								value: true,
								message: 'País de origen es requerido',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<Select
								className="w-full"
								placeholder="País de origen...*"
								styles={customStyles}
								isClearable
								onBlur={onBlur}
								onChange={onChange}
								options={[{ value: 0, label: 'Puerto Rico' }]}
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
						)}
					/>
				</label>
				{errors.countryOrigin && (
					<p className="text-sm text-red-600 mt-2">
						{errors.countryOrigin.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<label htmlFor="gender">
					<Controller
						control={control}
						name="gender"
						rules={{
							required: {
								value: true,
								message: 'Género es requerido',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<Select
								className="w-full"
								placeholder="Género...*"
								styles={customStyles}
								onBlur={onBlur}
								isClearable
								onChange={onChange}
								options={options.genderOptions}
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
						)}
					/>
				</label>
				{errors.gender && (
					<p className="text-sm text-red-600 mt-2">{errors.gender.message}</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<label htmlFor="statusSex">
					<Controller
						control={control}
						name="statusSex"
						rules={{
							required: {
								value: true,
								message: 'Sexo es requerido',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<Select
								className="w-full"
								placeholder="Sexo...*"
								styles={customStyles}
								onBlur={onBlur}
								isClearable
								onChange={onChange}
								options={options.sexOptions}
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
						)}
					/>
				</label>
				{errors.statusSex && (
					<p className="text-sm text-red-600 mt-2">
						{errors.statusSex.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<label htmlFor="maritalStatus">
					<Controller
						control={control}
						name="maritalStatus"
						// rules={{
						// 	required: {
						// 		value: false,
						// 		message: 'Estado civil es requerido',
						// 	},
						// }}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<Select
								className="w-full"
								placeholder="Estado civil...*"
								isClearable
								styles={customStyles}
								onBlur={onBlur}
								onChange={onChange}
								options={options.maritalOptions}
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
						)}
					/>
				</label>
				{/* {errors.maritalStatus && (
					<p className="text-sm text-red-600 mt-2">
						{errors.maritalStatus.message}
					</p>
				)} */}
			</div>
			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="phone"
					// rules={{
					// 	validate: {
					// 		validateLength: (data: string) => {
					// 			return data.replace(clearSpace, '').replace(' ', '').length < 10
					// 				? `¡Error, solo hay ${
					// 						data.replace(clearSpace, '').replace(' ', '').length
					// 				  } de 10 dígitos!`
					// 				: null;
					// 		},
					// 	},
					// }}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="phone"
							name="phone"
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							placeholder="Número Celular"
							displayType={'input'}
							format="### ### ####"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="phone">
					Número Celular
				</label>
				{phoneLengthVerification(watch, clearSpace, 'phone') < 10 && phoneLengthVerification(watch, clearSpace, 'phone') !== 0 ? (
					<p className="text-sm text-red-600 mt-2">
						{`¡Error, solo hay ${phoneLengthVerification(
							watch,
							clearSpace,
							'phone',
						)} de 10 dígitos!`}
					</p>
				) : null}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="email"
					className="input-style peer"
					placeholder="Correo electrónico"
					{...register('email', {
						pattern: {
							value: emailValidation,
							message: 'Correo electrónico Invalido',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="email">
					Correo electrónico
				</label>
				{errors.email && (
					<p className="text-sm text-red-600 mt-2">{errors.email.message}</p>
				)}
			</div>

			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
				<img src={Physicians} className={`w-12`} alt="" />
				<h1 className="text-xl first-line:capitalize">{`Médica`}</h1>
			</div>

			{/* <div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="isMobilePhone"
					render={({
						field: { onChange, onBlur, value = false, ref },
					}: any) => (
						<label
							className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px] cursor-pointer"
							htmlFor="isMobilePhone"
						>
							<Switch
								id="isMobilePhone"
								name="isMobilePhone"
								onChange={onChange}
								height={16}
								width={30}
								checked={value}
								checkedIcon={false}
								uncheckedIcon={false}
								className={`mr-5`}
							/>
							<span>Es Celular</span>
						</label>
					)}
				/>
			</div> */}
			<div className="w-11/12 lg:w-64 h-full">
				<label htmlFor="bloodtype">
					<Controller
						control={control}
						name="bloodtype"
						rules={{
							required: {
								value: true,
								message: 'Tipo de sangre es requerido',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<Select
								className="w-full"
								placeholder="Grupo sanguíneo...*"
								styles={customStyles}
								isClearable
								onBlur={onBlur}
								onChange={onChange}
								options={options.bloodOptions}
								value={value}
								components={{
									ValueContainer: CustomValueContainer,
								}}
							/>
						)}
					/>
				</label>
				{errors.bloodtype && (
					<p className="text-sm text-red-600 mt-2">
						{errors.bloodtype.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="insuranceCarrier"
					className="input-style peer"
					placeholder="Plan Médico 1*"
					{...register('insuranceCarrier', {
						required: {
							value: true,
							message: 'Plan medico 1 es requerido',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="insuranceCarrier">
					Plan Médico 1*
				</label>
				{errors.insuranceCarrier && (
					<p className="text-sm text-red-600 mt-2">
						{errors.insuranceCarrier.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="insuranceCarrierII"
					className="input-style peer"
					placeholder="Plan Médico 2"
					{...register('insuranceCarrierII', {
						// required: {
						// 	value: true,
						// 	message: 'Plan medico 1 es requerido',
						// },
					})}
				/>
				<label className="input-style-label" htmlFor="insuranceCarrierII">
					Plan Médico 2
				</label>
				{/* {errors.insuranceCarrierII && (
					<p className="text-sm text-red-600 mt-2">
						{errors.insuranceCarrierII.message}
					</p>
				)} */}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="preferenceHospital"
					className="input-style peer"
					placeholder="Clinca de Preferencia"
					{...register('preferenceHospital', {})}
				/>
				<label className="input-style-label" htmlFor="preferenceHospital">
					Clinca de Preferencia
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="tytocareId"
					className="input-style peer"
					placeholder="Identificación Tytocare*"
					{...register('tytocareId', {})}
				/>
				<label className="input-style-label" htmlFor="tytocareId">
					Identificación Tytocare
				</label>
			</div>
		</React.Fragment>
	);
};

export default StepOne;
