/* eslint-disable @typescript-eslint/no-unused-vars */
import useGetAllergyConditionSurgery from '../../hooks/useGetAllergyConditionSurgery';
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer';
import { PreviousSectionButton } from './Buttons/PreviousSectionButton';
import ConditionAllergySurgery from './ConditionAllergySurgery/index';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { FinishSectionButton } from './Buttons/FinishSectionButton';
import useGetListNursing from '../../hooks/useGetListNursing';
import registerService from '../../services/RegisterForms';
import { CalendarIcon } from '@heroicons/react/outline';
import Resident from '../../assets/svg/residentes.svg';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useDropzone } from 'react-dropzone';
import PersonalInfo from './PersonalInfo';
import MedicineInfo from './MedicineInfo';
import DatePicker from 'react-datepicker';
import VaccineInfo from './VaccineInfo';
import React, { useState } from 'react';
import DoctorInfo from './DoctorInfo';
import TutorInfo from './TutorInfo';
import PhotoData from './PhotoData';
import Swal from 'sweetalert2';

const RegisterPatientForm = () => {
	const [formStep, setFormStep] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	const {
		register,
		watch,
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	});

	const {
		fields: medicineFields,
		append: medicineAppend,
		remove: medicineRemove,
	} = useFieldArray({
		name: 'medicine',
		control,
	});

	const {
		fields: tutorFields,
		append: tutorAppend,
		remove: tutorRemove,
	} = useFieldArray({
		name: 'tutores',
		control,
	});

	const {
		fields: specialtyDoctorFields,
		append: specialtyDoctorAppend,
		remove: specialtyDoctorRemove,
	} = useFieldArray({
		name: 'specialtyDoctor',
		control,
	});

	const {
		fields: vaccineFields,
		append: vaccineAppend,
		remove: vaccineRemove,
	} = useFieldArray({
		name: 'vaccines',
		control,
	});

	const {
		fields: conditionFields,
		append: conditionAppend,
		remove: conditionRemove,
	} = useFieldArray({
		name: 'condition',
		control,
	});

	const {
		fields: allergyFields,
		append: allergyAppend,
		remove: allergyRemove,
	} = useFieldArray({
		name: 'allergy',
		control,
	});

	const {
		fields: surgeryFields,
		append: surgeryAppend,
		remove: surgeryRemove,
	} = useFieldArray({
		name: 'surgery',
		control,
	});

	const returnHome = () => {
		navigate('/home');
	};

	const handleStepCompletion = () => {
		setFormStep((cur) => cur + 1);
	};

	const handleGoBackToPreviousStep = () => {
		setFormStep((cur) => cur - 1);
	};

	const specifyValidator: any = (
		val: any,
		condition: any,
		allergy: any,
		surgery: any,
	) => {
		console.log(condition);

		// if (
		// 	val ||
		// 	condition?.length > 0 ||
		// 	allergy?.length > 0 ||
		// 	surgery?.length > 0
		// ) {
		// 	return true;
		// } else if (
		// 	condition[0].item !== null ||
		// 	condition[0].item !== undefined ||
		// 	allergy[0].item !== null ||
		// 	allergy[0].item !== undefined ||
		// 	surgery[0].item !== null ||
		// 	surgery[0].item !== undefined
		// ) {
		// 	return false;
		// }
		return false;
	};

	const { dataNursing, error }: any = useGetListNursing();
	const onSubmit = async (values: any) => {
		//let { photoURL, socialSecurityCardURL, driverLicecseURL } = values;
		//let photos = { photoURL, socialSecurityCardURL, driverLicecseURL };

		//photos.photoURL = convertToBase64(photoURL[0]) || '';
		// photos.socialSecurityCardURL =
		//   convertToBase64(socialSecurityCardURL[0]) || "";
		// photos.driverLicecseURL = convertToBase64(driverLicecseURL[0]) || "";

		setIsLoading(false);
		let responses = await registerService.registerPatient(values);

		if (responses.code >= 201 && responses.code <= 299) {
			setIsLoading(true);
			reset({
				clinicalMedicalNH: null,
				otherSource: null,
				firstname: null,
				middlename: null,
				surname: null,
				second_surname: null,
				email: null,
				insuranceCarrier: null,
				insuranceCarrierII: null,
				preferenceHospital: null,
				tytocareId: null,
				nursingHomeId: null,
				entryDate: null,
				nursingHomeSourceId: null,
				birthday: null,
				socialSecurityId: null,
				countryOrigin: null,
				gender: null,
				statusSex: null,
				maritalStatus: null,
				phone: null,
				bloodtype: null,
				medicine: [],
				tutores: [],
				specialtyDoctor: [],
				vaccines: [],
				condition: [],
				allergy: [],
				surgery: [],
				photoURL: null,
				socialSecurityCardURL: null,
				driverLicecseURL: null,
				insuranceCardURL: null,
			});

			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Confirmación',
				text: 'Residente registrado con éxito',
				icon: 'success',
				cancelButtonText: 'Volver al formulario',
				confirmButtonText: 'Ver perfil',
				showCancelButton: true,
				confirmButtonColor: 'rgb(14 165 233)',
				cancelButtonColor: 'rgb(14 165 233)',
			})
				.then((result) => {
					setIsLoading(true);

					if (result.isConfirmed) {
						navigate(`/patientprofile/${responses.data[0].user_id}`);
					} else {
						setIsLoading(true);
						setFormStep(0);
					}
				})
				.catch(() => {
					setIsLoading(true);
				});
		} else if (responses.error) {
			setIsLoading(true);
		}
	};

	let {
		listData: acsListData,
		error: acsError,
		isLoading: acsIsLoading,
	}: any = useGetAllergyConditionSurgery();
	// const { data, error, isLoading }: any = useGetListCity();

	return (
		<>
			<div className="w-full space-y-4 ">
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					<img src={Resident} className={`w-20`} alt="" />
					<h1 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
						Registro de residente
					</h1>
				</div>
				<div className="max-w-4xl mx-auto">
					<div
						style={{ width: `${(formStep / 6) * 100}%` }}
						className="h-2 bg-[#0284C7] transform duration-300 ease-out"></div>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					{/* ################################################# Informacion Personal ################################################# */}
					{formStep >= 0 && (
						<section className={`${formStep === 0 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5">
								Información Personal
							</h2>
							<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col">
								<PersonalInfo
									Controller={Controller}
									Select={Select}
									DatePicker={DatePicker}
									control={control}
									register={register}
									errors={errors}
									NumberFormat={NumberFormat}
									CustomValueContainer={CustomValueContainer}
									CalendarIcon={CalendarIcon}
									ListNursing={dataNursing}
									watch={watch}
								/>

								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row ">
									<PreviousSectionButton
										isDisabled={!isValid}
										onClick={returnHome}>
										Ir a Home
									</PreviousSectionButton>

									<FinishSectionButton
										id="next"
										onClick={handleStepCompletion}
										isDisabled={!isValid}>
										Siguiente
									</FinishSectionButton>
									{/* <ReactTooltip id="next" place="top" effect="solid">
										Tooltip for the register button
									</ReactTooltip> */}
								</div>
							</div>
						</section>
					)}

					{/* ############################################### Fin Informacion Personal ############################################### */}
					{/* ############################################### Informacion Responsable ################################################ */}

					{formStep >= 1 && (
						<section className={`${formStep === 1 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5">
								Registro Responsable
							</h2>
							<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7">
								<TutorInfo
									Controller={Controller}
									Select={Select}
									control={control}
									register={register}
									tutorAppend={tutorAppend}
									tutorFields={tutorFields}
									tutorRemove={tutorRemove}
									NumberFormat={NumberFormat}
									CustomValueContainer={CustomValueContainer}
									errors={errors}
									watch={watch}
								/>

								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row">
									<PreviousSectionButton
										isDisabled={!isValid}
										onClick={handleGoBackToPreviousStep}>
										Volver
									</PreviousSectionButton>
									<FinishSectionButton
										onClick={handleStepCompletion}
										isDisabled={!isValid}>
										Siguiente
									</FinishSectionButton>
								</div>
							</div>
						</section>
					)}

					{/* ############################################# Fin Informacion Responsable ############################################## */}
					{/* ################################################## Informacion Medica ################################################## */}

					{formStep >= 2 && (
						<section className={`${formStep === 2 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5">
								Doctores/as informativo/as
							</h2>
							{/* <div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col"> */}
							<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7">
								<DoctorInfo
									Controller={Controller}
									Select={Select}
									control={control}
									register={register}
									errors={errors}
									NumberFormat={NumberFormat}
									CustomValueContainer={CustomValueContainer}
									specialtyDoctorFields={specialtyDoctorFields}
									specialtyDoctorRemove={specialtyDoctorRemove}
									specialtyDoctorAppend={specialtyDoctorAppend}
									watch={watch}
								/>

								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row ">
									<PreviousSectionButton
										isDisabled={!isValid}
										onClick={handleGoBackToPreviousStep}>
										Volver
									</PreviousSectionButton>
									<FinishSectionButton
										onClick={handleStepCompletion}
										isDisabled={!isValid}>
										Siguiente
									</FinishSectionButton>
								</div>
							</div>
						</section>
					)}

					{/* {Informacion de alergias} */}
					{formStep >= 3 && (
						<section className={`${formStep === 3 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5 first-line:capitalize">
								Lista de condiciones, Alergias y cirugías
							</h2>
							<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7">
								<ConditionAllergySurgery
									Controller={Controller}
									control={control}
									Select={Select}
									register={register}
									conditionFields={conditionFields}
									conditionRemove={conditionRemove}
									conditionAppend={conditionAppend}
									allergyFields={allergyFields}
									allergyAppend={allergyAppend}
									allergyRemove={allergyRemove}
									surgeryFields={surgeryFields}
									surgeryAppend={surgeryAppend}
									surgeryRemove={surgeryRemove}
									acsListData={acsListData}
									acsError={acsError}
									acsIsLoading={acsIsLoading}
									CustomValueContainer={CustomValueContainer}
								/>

								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row ">
									<PreviousSectionButton
										isDisabled={specifyValidator(
											!isValid,
											watch('condition'),
											watch('allergy'),
											watch('surgery'),
										)}
										onClick={handleGoBackToPreviousStep}>
										Volver
									</PreviousSectionButton>

									<FinishSectionButton
										onClick={handleStepCompletion}
										text={
											'Por favor complete uno de los campos para continuar, si estan vacios eliminelo y continue.'
										}
										isDisabled={!isValid}>
										Siguiente
									</FinishSectionButton>
								</div>
							</div>
						</section>
					)}

					{/* {fin informacion de alergias} */}
					{/* ############################################## Medicacion ############################################## */}

					{formStep >= 4 && (
						<section className={`${formStep === 4 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5">Medicamentos</h2>
							<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7">
								<MedicineInfo
									Controller={Controller}
									Select={Select}
									control={control}
									register={register}
									medicineFields={medicineFields}
									medicineRemove={medicineRemove}
									medicineAppend={medicineAppend}
									CustomValueContainer={CustomValueContainer}
									errors={errors}
								/>
								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row ">
									<PreviousSectionButton
										isDisabled={!isValid}
										onClick={handleGoBackToPreviousStep}>
										Volver
									</PreviousSectionButton>

									<FinishSectionButton
										onClick={handleStepCompletion}
										isDisabled={!isValid}>
										Siguiente
									</FinishSectionButton>
								</div>
							</div>
						</section>
					)}

					{/* ############################################## Fin Medicacion ############################################## */}

					{formStep >= 5 && (
						<section className={`${formStep === 5 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5">Vacunas</h2>
							<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col">
								<VaccineInfo
									Controller={Controller}
									DatePicker={DatePicker}
									control={control}
									register={register}
									vaccineFields={vaccineFields}
									vaccineRemove={vaccineRemove}
									vaccineAppend={vaccineAppend}
									CalendarIcon={CalendarIcon}
									CustomValueContainer={CustomValueContainer}
									errors={errors}
								/>

								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row ">
									<PreviousSectionButton
										isDisabled={!isValid}
										onClick={handleGoBackToPreviousStep}>
										Volver
									</PreviousSectionButton>

									<FinishSectionButton
										onClick={handleStepCompletion}
										isDisabled={!isValid}>
										Siguiente
									</FinishSectionButton>
								</div>
							</div>
						</section>
					)}
					{/* ################################################ Vacunas ################################################ */}

					{formStep >= 6 && (
						<section className={`${formStep === 6 ? 'block' : 'hidden'} `}>
							<h2 className="text-center text-2xl my-5">Adjuntar Documentos</h2>
							<p className="text-center text-base my-5">
								Nota: Las fotos no pueden superar un tamaño de 5 MB
							</p>
							<div className="lg:w-fit mx-auto place-items-center flex flex-col">
								<PhotoData
									Controller={Controller}
									control={control}
									register={register}
									useDropzone={useDropzone}
									watch={watch}
									errors={errors}
								/>

								<div className="lg:col-span-2 xl:col-span-3 my-5 pb-4 flex flex-row ">
									<PreviousSectionButton
										isDisabled={!isValid}
										onClick={handleGoBackToPreviousStep}>
										Volver
									</PreviousSectionButton>

									{isLoading ? (
										<React.Fragment>
											<input
												type="submit"
												value="Registrar"
												className="mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 "
											/>
										</React.Fragment>
									) : (
										<React.Fragment>
											<input
												type="submit"
												disabled
												value="Registrando..."
												className="mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-black hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
											/>
										</React.Fragment>
									)}
								</div>
							</div>
						</section>
					)}
				</form>
			</div>
		</>
	);
};

export default RegisterPatientForm;
