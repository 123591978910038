export const customStyles = {
	// container: (provided:any, state:any) => ({
	//   ...provided,
	//   marginTop: 50,
	// }),
	valueContainer: (provided: any, state: any) => {
		// console.log("Provided en ValueContainer", provided);

		return {
			...provided,
			overflow: 'visible',
		};
	},
	option: (provided: any, state: any) => {
		// console.log('Provided en option: ', provided);

		return {
			...provided,
			// borderBottom: "1px dotted pink",
			// color: state.isSelected
			//   ? "red"
			//   : "rgb(14 165 233 / var(1))",
			// padding: 20,
			width: '100%',
		};
	},
	control: (base: any, state: any) => {
		// console.log("Base en Control: ", base);

		return {
			// none of react-select's styles are passed to <Control />
			...base,
			borderColor: 'rgba(133, 133, 133, 0.5)',
			border: '1',
			boxShadow: '1px 1px #00000010',
			borderRadius: '50px',
			heigth: '2.5rem',
			// width: "100%",
		};
	},
	singleValue: (provided: any, state: any) => {
		// console.log("Provided en SingleValue: ", provided);

		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...provided,
			opacity,
			transition,
		};
	},
	placeholder: (provided: any, state: any) => {
		// console.log("Provided en Placeholder: ", provided);

		return {
			...provided,
			backgroundColor: 'white',
			padding: '0px 5px',
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -13 : '13%',
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
		};
	},
};
