/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { number } from 'yup/lib/locale';
import api from '../services/Api';
import { dataTransformer } from '../utils/DataTransformer/entitylist/dataTransformer';

const useGetEntityII = () => {
	const [homeList, setHomeList] = useState<any>(null);
	const [clinicList, setClinicList] = useState<any>(null);
	const [error, setError] = useState([]);
	const [loading, setIsLoading] = useState(true);
	const [value, setValue] = useState<number>();

	useEffect(() => {
		const fetchdata = async () => {
			let user: any = localStorage.getItem('user');
			setIsLoading(true);

			let home: any[] = [];
			await api.instanceEntities
				.get(`/nursing_home/list/`, {
					headers: {
						Authorization: `${JSON.parse(user).TokenType} ${
							JSON.parse(user).IdToken
						}`,
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				})
				.then((response: any) => {
					response.data.data[0].map((item: any) => {
						home.push({
							value: item.id,
							label: item.comercialname,
							type: 'HN',
						});
					});
				})
				.catch((err) => {
					setHomeList(null);

					if ([401].includes(err?.response?.status) && homeList === null) {
						fetchdata();
					}
					setIsLoading(false);
				});

			let clinic: any[] = [];
			await api.instanceEntities
				.get(`/clinic/list/`, {
					headers: {
						Authorization: `${JSON.parse(user).TokenType} ${
							JSON.parse(user).IdToken
						}`,
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				})
				.then((response: any) => {
					response.data.data[0].map((item: any) => {
						clinic.push({
							value: item.id,
							label: item.comercialname,
							type: 'C',
						});
					});
				})
				.catch((err) => {
					setClinicList(null);
					if ([401].includes(err?.response?.status) && clinicList === null) {
						fetchdata();
					}
					setIsLoading(false);
				});

			setHomeList(home);
			setClinicList(clinic);
			setIsLoading(false);
		};

		fetchdata();
	}, []);
	return { homeList, clinicList, error, loading };
};

export default useGetEntityII;

/**
 */
