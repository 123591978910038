import { actions } from '../actions';

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case actions.setUpdateEntityForm: //eaaldark
			return {
				...state,
				updateEntityForm: action.payload,
			};
		case actions.clearUpdateEntityForm: //eaaldark
			return {
				...state,
				updateEntityForm: action.payload,
			};
		case actions.setUpdatePatientInfoForm: //Jhonathan
			return {
				...state,
				updatePatientInfoForm: action.payload,
			};
		case actions.clearUpdatePatientInfoForm: //Jhonathan
			return {
				...state,
				updatePatientInfoForm: action.payload,
			};
		case actions.setUpdatePatientTutorForm: //Jhonathan
			return {
				...state,
				updatePatientTutorForm: action.payload,
			};
		case actions.clearUpdatePatientTutorForm: //Jhonathan
			return {
				...state,
				updatePatientTutorForm: action.payload,
			};
		case actions.setUpdatePatientDoctorForm: //Jhonathan
			return {
				...state,
				updatePatientDoctorForm: action.payload,
			};
		case actions.clearUpdatePatientDoctorForm: //Jhonathan
			return {
				...state,
				updatePatientDoctorForm: action.payload,
			};
		case actions.setUpdatePatientAllerProfCondForm: //Jhonathan
			return {
				...state,
				updatePatientAllerProfCondForm: action.payload,
			};
		case actions.clearUpdatePatientAllerProfCondForm: //Jhonathan
			return {
				...state,
				updatePatientAllerProfCondForm: action.payload,
			};
		case actions.setUpdatePatientMedicationForm: //Jhonathan
			return {
				...state,
				updatePatientMedicationForm: action.payload,
			};
		case actions.clearUpdatePatientMedicationForm: //Jhonathan
			return {
				...state,
				updatePatientMedicationForm: action.payload,
			};
		case actions.setUpdatePatientVaccineForm: //Jhonathan
			return {
				...state,
				updatePatientVaccineForm: action.payload,
			};

		case actions.clearUpdatePatientVaccineForm: //Jhonathan
			return {
				...state,
				updatePatientVaccineForm: action.payload,
			};
		case actions.setUpdateProfileUserForm: //eaaldark
			return {
				...state,
				updateProfileUserForm: action.payload,
			};
		case actions.setSidebarCurrentUser: //eaaldark
			return {
				...state,
				currentUser: action.payload,
			};
		case actions.previewMonthCalendar: //eaaldark
			return {
				...state,
				calendar: action.payload,
			};
		case actions.nextMonthCalendar: //eaaldark
			return {
				...state,
				calendar: action.payload,
			};
		case actions.setEntitySelectedByUser: //eaaldark
			return {
				...state,
				currentEntitySelected: action.payload,
			};
		case actions.clearEntitySelectedByUser: //eaaldark
			return {
				...state,
				currentEntitySelected: action.payload,
			};

		case actions.setUpdatePatientPhotoForm: //Jhonathan
			return {
				...state,
				updatePatientPhotoForm: action.payload,
			};
		case actions.clearUpdatePatientPhotoForm: //Jhonathan
			return {
				...state,
				updatePatientPhotoForm: action.payload,
			};
		case actions.setAllowOptionsByRole: //eaaldark
			return {
				...state,
				accessRole: action.payload,
			};
		case actions.setEntitiesId: //eaaldark
			return {
				...state,
				entitiesId: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
