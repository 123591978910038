/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../services/Authentication';
import Swal from 'sweetalert2';
// import "sweetalert2/src/sweetalert2.scss";

const Login = () => {
	const navigate = useNavigate();
	const [dataLogin, setDataLogin] = useState(false);

	const [passwordShown, setPasswordShown] = useState(false);
	const { register, handleSubmit } = useForm();

	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	const onSubmit = async (onSubmitData: any) => {
		if (onSubmitData.email.length > 0 && onSubmitData.password.length > 0) {
			AuthService.login(onSubmitData).then(
				(response: any) => {
					const sesion = response.data[0].session ? true : false;

					if (
						response.code === 200 &&
						response.message === 'Request Succesfull' &&
						!sesion
					) {
						navigate('/home', { replace: true });
						window.location.reload();
					} else if (
						response.code === 200 &&
						response.data[0].result === false
					) {
						setDataLogin(true);
						alert('Error al intentar iniciar sesión');
					} else if (response.code === 200 && sesion) {
						navigate('/firstlogin', {
							state: {
								session: response.data[0].session,
								email: onSubmitData.email,
							},
						});
					}
					// setDataLogin(false);
					// navigate('/home', { replace: true });
					// window.location.reload();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					// console.log(resMessage);

					Swal.fire({
						customClass: {
							confirmButton: 'bg-red-500 text-white',
						},
						title: 'Error Interno!',
						text: 'Vuelva a intertarlo nuevamente',
						// icon: "error",
						confirmButtonText: 'Aceptar',
					});
					setDataLogin(true);
				},
			);
			setDataLogin(true);
		} else {
			alert('Debe completar los dos campos para iniciar sesión');
			setDataLogin(false);
		}
	};

	return (
		<div className="w-full min-h-screen bg-gradient-to-t from-[#33c2bf] to-[#294fa9]">
			<div className="flex flex-col justify-between py-16 space-y-9">
				<div className="bg-telemedicineLogo bg-no-repeat bg-center h-52"></div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="place-items-center flex flex-col space-y-4">
					<input
						className="w-64 h-12 text-[20px] rounded-md px-3 py-4 shadow-lg border-[1px] focus:outline-none hover:border-sky-500 hover:ring-sky-500 hover:ring-2"
						placeholder="Correo electrónico"
						type={'email'}
						{...register('email')}
					/>
					<label className="w-64 h-12 rounded-md px-3 py-4 shadow-lg border-[1px] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-2">
						<input
							className="w-full h-10 focus:outline-none text-[20px]"
							placeholder="Contraseña"
							type={passwordShown ? 'text' : 'password'}
							{...register('password')}
						/>
						{!passwordShown ? (
							<EyeIcon
								onClick={togglePasswordVisiblity}
								className="h-5 md:h-7 hover:cursor-pointer"
							/>
						) : (
							<EyeOffIcon
								onClick={togglePasswordVisiblity}
								className="h-5 md:h-7 hover:cursor-pointer"
							/>
						)}
					</label>
					<p className="text-white font-semibold">
						<Link to="/forgotpassword">¿Olvido su contraseña?</Link>
					</p>
					{dataLogin ? (
						<p className="float-right rounded-lg px-4 py-2 bg-sky-500 text-white shadow-md duration-300 border-[1px] border-slate-300 font-semibold hover:cursor-pointer">
							Iniciando Sesión...
						</p>
					) : (
						<input
							className="float-right rounded-lg px-4 py-2 bg-white shadow-md duration-300 border-[1px] border-slate-300 font-semibold hover:cursor-pointer hover:bg-sky-500 hover:text-white hover:border-sky-700"
							type={'submit'}
							value={'Iniciar Sesión'}
						/>
					)}
				</form>
				<div className="bg-applivioLogo bg-no-repeat bg-center h-16"></div>
			</div>
		</div>
	);
};

export default Login;
