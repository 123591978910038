import { deserialize } from 'class-transformer';
import { FormatSelect } from '../FormatSelect';

export const dataTransformer = (data: any) => {
	let dataArray = (Array.isArray(data) ? data : [data]).map((item: any) => {
		return {
			type: 'Clinica',
			...item,
		};
	});

	let result = deserialize(FormatSelect, JSON.stringify(dataArray));

	return result;
};
