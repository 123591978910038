export const customStyles = {
	option: (provided: any, state: any) => ({
		...provided,
		// borderBottom: "1px dotted pink",
		// color: state.isSelected ? "red" : "blue",
		// padding: 20,
		width: '100%',
	}),
	control: (base: any) => ({
		// none of react-select's styles are passed to <Control />
		...base,
		border: 0,
		boxShadow: 'none',
		// width: "100%",
	}),
	singleValue: (provided: any, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...provided,
			opacity,
			transition,
		};
	},
};
