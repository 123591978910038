/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import * as DTOClinic from '../utils/DataTransformer/limitList/clinics/dataTransformer';
import * as DTONursingHome from '../utils/DataTransformer/limitList/homes/dataTransformer';
import api from '../services/Api';

const useGetCurrentUSer = (string: any) => {
	const [data, setData] = useState<any>(null);
	const [dataClinic, setDataClinic] = useState<any>(null);
	const [dataHome, setDataHome] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [clinicIDList, setClinicIDList] = useState<any>(null);
	const [homeIDList, setHomeIDList] = useState<any>(null);
	const [listSelectClinic, setListSelectClinic] = useState<any>(null);
	const [listSelectNursingHome, setListSelectNursingHome] = useState<any>(null);

	useEffect(() => {
		if (string === 'get') {
			setIsLoading(true);
			const fetchdata = async () => {
				let user: any = localStorage.getItem('user');
				let clinicIds: any[] = [];
				let homeIds: any[] = [];

				await api.instanceUsers
					.get(`/user/basic-info/`, {
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
						},
					})
					.then(async (response: any) => {
						localStorage.setItem('userId', response.data.data[0].id);
						localStorage.setItem(
							'clinicId',
							response.data.data[0].roles[0].clinicId,
						);
						localStorage.setItem(
							'nursing_home_id',
							JSON.stringify(response.data.data[0].roles[0].nursingHomeId),
						);
						localStorage.setItem(
							'role',
							JSON.stringify(response.data.data[0].roles[0].id),
							// JSON.stringify(response.data.data[0].roles[0].roleId),
						);

						setData(response.data.data[0]);

						// setData({
						// 	firstname: 'Fake',
						// 	middlename: 'Fake',
						// 	surname: 'Fake',
						// 	secondSurname: 'Fake',
						// 	email: 'fake@fake.fake',
						// 	id: 106,
						// 	statusId: 1,
						// 	statusName: 'Habilitado',
						// 	roles: [
						// 		{
						// 			id: 94,
						// 			projectId: null,
						// 			clinicId: 1,
						// 			nursingHomeId: null,
						// 			trustId: null,
						// 			roleId: 1,
						// 			roleName: 'Administrador de la clinica',
						// 		},
						// 	],
						// 	npi: '1234567896',
						// });

						response.data.data[0].roles.map((item: any) => {
							if (item.clinicId !== null) {
								if (!clinicIds.includes(item.clinicId)) {
									clinicIds.push(item.clinicId);
								}
							}
							if (item.nursingHomeId !== null) {
								if (!homeIds.includes(item.nursingHomeId)) {
									homeIds.push(item.nursingHomeId);
								}
							}
						});

						if (homeIds?.length > 0) {
							await api.instanceEntities
								.post(
									'/nursing_home/limitList',
									{
										nursingHomeIds: homeIds,
									},
									{
										headers: {
											Authorization: `${JSON.parse(user).TokenType} ${
												JSON.parse(user).IdToken
											}`,
											'Content-Type': 'application/json',
										},
										withCredentials: true,
									},
								)
								.then((response: any) => {
									setListSelectNursingHome(
										DTONursingHome.dataTransformer(response.data.data[0][0]),
									);
								});
						}
						if (clinicIds?.length > 0) {
							await api.instanceEntities
								.post(
									'/clinic/limitList',
									{
										clinicIds: clinicIds,
									},
									{
										headers: {
											Authorization: `${JSON.parse(user).TokenType} ${
												JSON.parse(user).IdToken
											}`,
											'Content-Type': 'application/json',
										},
										withCredentials: true,
									},
								)
								.then((response: any) => {
									setListSelectClinic(
										DTOClinic.dataTransformer(response.data.data[0][0]),
									);
								});
						}

						setClinicIDList(clinicIds);
						setHomeIDList(homeIds);

						if (
							response?.data?.data[0]?.roles[0]?.clinicId !== null &&
							response?.data?.data[0]?.roles[0]?.clinicId !== 0
						) {
							await api.instanceEntities
								.post(
									`/clinic/get-profile-clinic-by-id`,
									{
										clinicId: response?.data?.data[0]?.roles[0]?.clinicId,
									},
									{
										headers: {
											Authorization: `${JSON.parse(user).TokenType} ${
												JSON.parse(user).IdToken
											}`,
											'Content-Type': 'application/json',
										},
										withCredentials: true,
									},
								)
								.then((response: any) => {
									setDataClinic(response.data.data[0][0]);
								});
						}

						if (
							response?.data?.data[0]?.roles[0]?.nursingHomeId !== null &&
							response?.data?.data[0]?.roles[0]?.nursingHomeId !== 0
						) {
							await api.instanceEntities
								.post(
									`/nursing_home/get-profile-home-by-id`,
									{
										clinicId: response?.data?.data[0]?.roles[0]?.nursingHomeId,
									},
									{
										headers: {
											Authorization: `${JSON.parse(user).TokenType} ${
												JSON.parse(user).IdToken
											}`,
											'Content-Type': 'application/json',
										},
										withCredentials: true,
									},
								)
								.then((response: any) => {
									setDataHome(response.data.data[0][0]);
									// console.log(response);
								});
						}

						setIsLoading(false);
					})
					.catch((err) => {
						if ([401].includes(err.response.status)) {
							setData(null);
						}
						// console.log(err.response);
						// console.log(err.response.status);
						if ([401].includes(err.response.status) && data === null) {
							fetchdata();
						}
						setIsLoading(false);
					});
				// console.log(response.data.data[0]);
			};

			fetchdata();
		}
	}, []);

	return {
		data,
		dataClinic,
		dataHome,
		isLoading,
		clinicIDList,
		homeIDList,
		listSelectClinic,
		listSelectNursingHome,
	};
};

export default useGetCurrentUSer;
