import { PencilAltIcon } from '@heroicons/react/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setUpdatePatientDoctorForm } from '../../actions';
import { connect } from 'react-redux';
import Physicians from '../../assets/svg/PatientItems/Physicians.svg';

const PhysicianPatient = (props: any) => {
	const {
		firstname,
		surname,
		specialty,
		jobAddress,
		zipCode,
		email,
		clinicalMedicalRecord,
		clinicalName,
		npi,
		JobAddressAlt,
		JobState,
		JobCountry,
		phone,
		id,
		patient,
		clinicPhone,
		doctorLocalPhone,
	} = props;
	const navigate = useNavigate();
	return (
		<div className="w-full text-left md:px-20 space-y-4 text-lg pb-4 border-y-2 ">
			<div className="md:flex md:flex-row md:items-center md:justify-start border-b-2 ">
				<img src={Physicians} className={`w-12`} alt="" />
				<h1 className="text-2xl font-bold">
					<p className="text-black font-bold first-line:capitalize">
						{`${surname}` ? `${firstname} ${surname}` : `${firstname}`}
					</p>
				</h1>
			</div>
			<div className="w-full md:text-right space-y-4 text-lg m-0">
				{/* <p className="text-sky-500 font-bold ">
					{`Record Clínico: ${clinicalMedicalRecord}`}
				</p> */}
				<p>
					<b className="text-sky-500 font-bold ">Record Clínico:</b>
					{clinicalMedicalRecord ? ` ${clinicalMedicalRecord}` : 'N/A'}
				</p>
			</div>
			<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
				<div className="w-fit md:ml-24 min-w-sm	 ">
					{/* <div className="md:gap-1.5 md:flex md:flex-row ">
						<p className="text-sky-500 font-bold">Record Clínico: </p>
						<p>
							{clinicalMedicalRecord}
						</p>
					</div> */}
					{/* <div className="md:gap-1.5 md:flex md:flex-row ">
						<p className="text-sky-500 font-bold">Nombre: </p>
						<p>
							{`${surname}` ? `${firstname} ${surname}` : `${firstname}`}
						</p>
					</div> */}
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Especialidad: </p>
						<p>{specialty ? specialty : 'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Celular: </p>
						<p>{phone?.number?phone?.number:'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row whitespace-normal ">
						<p className="text-sky-500 font-bold  w-[164.906px] break-keep">
							Correo electrónico:{' '}
						</p>
						<p>{email ? email : 'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Clínica: </p>
						<p>{clinicalName ? clinicalName : 'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Telefono de Clínica: </p>
						<p>
							{`${
								doctorLocalPhone?.number ? doctorLocalPhone?.number : 'N/A'
							} `}
							<b className="text-sky-500 font-bold ">Ext:</b>
							{doctorLocalPhone?.extension
								? ` ${doctorLocalPhone.extension}`
								: 'N/A'}
						</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Dirección 1: </p>
						<p>{`${jobAddress ? jobAddress : 'N/A'}`}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Dirección 2: </p>
						<p>{`${JobAddressAlt ? JobAddressAlt : 'N/A'}`}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Municipio/Estado: </p>
						<p>{`${JobState ? JobState : 'N/A'}`}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Código postal: </p>
						<p>{zipCode ? zipCode : 'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">País: </p>
						<p>{`${JobCountry ? JobCountry : 'N/A'}`}</p>
					</div>
				</div>
			</div>
			<div className="lg:col-span-2 xl:col-span-3 my-5">
				<div className="flex flex-row gap-10">
					<div className={`sm:flex-1 flex flex-row`} />
					<div
						className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
						onClick={() => {
							navigate('/doctorupdate');
							props.setUpdatePatientDoctorForm({
								firstname,
								surname,
								specialty,
								jobAddress,
								zipCode,
								email,
								clinicalMedicalRecord,
								clinicalName,
								npi,
								JobAddressAlt,
								JobState,
								JobCountry,
								phone,
								id,
								patient,
								clinicPhone,
								doctorLocalPhone,
							});
						}}>
						<PencilAltIcon className="w-8 h-8 " />
						<div>Editar datos</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapDispatchToProps = {
	setUpdatePatientDoctorForm,
};

export default connect(null, mapDispatchToProps)(PhysicianPatient);
