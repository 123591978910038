import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUpdatePatientVaccineForm } from '../../actions';
import Vaccines from '../../assets/svg/PatientItems/Vaccines.svg';

const VaccinesPatient = (props: any) => {
	const {
		vaccineName,
		dosage,
		doseDate,
		frequency,
		pharmaceuticalCompany,
		patient,
		id,
	} = props;

	const navigate = useNavigate();

	return (
		<div className="w-full text-left md:px-20 space-y-4 text-lg py-2 border-y-2 ">
			<div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
				<img src={Vaccines} className="w-9 h-9" alt="" />

				<h1 className="text-xl font-bold">
					<p className="text-black font-bold first-line:capitalize  ">
						{vaccineName?vaccineName:'N/A'}
					</p>
					<p></p>
				</h1>
			</div>
			<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
				<div className="w-fit md:ml-24 min-w-sm	 ">
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Dosis:</p>
						<p>{dosage?dosage:'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Fecha Dosis:</p>
						<p>{doseDate?doseDate:'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Frecuencia:</p>
						<p>{frequency?frequency:'N/A'}</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-sky-500 font-bold">Farmaceutica:</p>
						<p>{pharmaceuticalCompany?pharmaceuticalCompany:'N/A'}</p>
					</div>
				</div>
			</div>

			<div className="flex flex-row ">
				<div className={`lg:flex-1 flex flex-row`} />
				<div
					className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
					onClick={() => {
						navigate('/vaccine/update');
						props.setUpdatePatientVaccineForm({
							vaccineName,
							dosage,
							doseDate,
							frequency,
							pharmaceuticalCompany,
							patient,
							id,
						});
					}}
				>
					<PencilAltIcon className="w-8 h-8 " />
					<div>Editar datos</div>
				</div>
			</div>
		</div>
	);
};
const mapDispatchToProps = {
	setUpdatePatientVaccineForm,
};
export default connect(null, mapDispatchToProps)(VaccinesPatient);
