import api from './Api';

const ListNotes = async (obj: any) => {
	const { id, noteDate } = obj;
	let user: any = localStorage.getItem('user');
	let noticas: [] = [];
	let citaciones: [] = [];

	await api.instanceUsers
		.post(
			`/user/get-resident-note-to-date/`,
			{ id, noteDate },
			{
				headers: {
					Authorization: `${JSON.parse(user).TokenType} ${
						JSON.parse(user).IdToken
					}`,
					USER_ENTITY_ID: `${localStorage.getItem('role')}`,
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			},
		)
		.then((response) => {
			noticas = response.data.data[0].residentNote;
		})
		.catch(() => {});

	await api.instanceEvents
		.post(
			`/event/resident-event-list`,
			{ residentId: id, beginAt: noteDate },
			{
				headers: {
					Authorization: `${JSON.parse(user).TokenType} ${
						JSON.parse(user).IdToken
					}`,
					USER_ENTITY_ID: `${localStorage.getItem('role')}`,
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			},
		)
		.then((response) => {
			citaciones = response.data.data[0];
		})
		.catch(() => {});

	let mergeElements = noticas.concat(citaciones);

	return mergeElements;
};

const ServiceNotes = {
	ListNotes,
};

export default ServiceNotes;
