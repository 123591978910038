/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import appointmentServices from '../../services/Appointment';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { customStyles } from '../customStyle';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import AppointmenItem from './AppointmentItem';
import useGetUserType from '../../hooks/useGetUserTypeI';
import { CalendarIcon } from '@heroicons/react/outline';

const Appointments = (props: any) => {
	const [appointments, setAppointments] = useState<any>([]);

	const {
		dataPatients: listPatients,
		dataPhysician: dataPhysicianList,
		error,
		isLoading,
	}: any = useGetUserType({ types: [{ id: 4 }, { id: 1 }] });

	const {
		register,
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = useForm({
		mode: 'onChange',
	});

	const onSubmit = async (dataForm: any) => {
		const appointmentResult = await appointmentServices.appointmentList(
			dataForm,
		);
		setAppointments(appointmentResult);
	};

	return (
		<div className="flex flex-col space-y-5 p-3 w-full">
			<div className="text-center flex flex-col space-y-2">
				<h1 className="text-xl font-bold text-[#96c952]">Lista de citas</h1>
			</div>

			<form
				className="w-full flex flex-col md:flex-row"
				onSubmit={handleSubmit(onSubmit)}
			>
				<Controller
					control={control}
					name="physicianIdentified"
					rules={{
						required: {
							value: false,
							message: 'Seleccione un médico para la busqueda',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<label
							className="w-full h-12 mx-1 my-1 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="physicianIdentified"
						>
							<Select
								className="w-full"
								placeholder={`${
									isLoading ? 'Cargando Medicos...' : 'Seleccione un Médico...'
								}`}
								styles={customStyles}
								onBlur={onBlur}
								onChange={onChange}
								options={dataPhysicianList}
								isDisabled={isLoading ? true : false}
								value={value}
							/>
						</label>
					)}
				/>

				<Controller
					control={control}
					name="entryDate"
					rules={{
						required: {
							value: false,
							message: 'La fecha de ingreso es requerida',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<label
							className="w-full h-12 mx-1 my-1 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="entryDate"
						>
							<DatePicker
								className="w-full focus:outline-none bg-transparent"
								dateFormat="MM/dd/yyyy"
								placeholderText="Dia de citas"
								onBlur={onBlur}
								selected={value}
								onChange={onChange}
								showYearDropdown
								yearDropdownItemNumber={120}
								scrollableYearDropdown
								value={value}
							/>
							<CalendarIcon className="w-6 h-6 ml-2" />
						</label>
					)}
				/>

				<Controller
					control={control}
					name="entryEndDate"
					rules={{
						required: {
							value: false,
							message: 'La fecha de ingreso es requerida',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<label
							className="w-full h-12 mx-1 my-1 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="entryEndDate"
						>
							<DatePicker
								className="w-full focus:outline-none bg-transparent"
								dateFormat="MM/dd/yyyy"
								placeholderText="Dia de citas"
								onBlur={onBlur}
								selected={value}
								onChange={onChange}
								showYearDropdown
								yearDropdownItemNumber={120}
								scrollableYearDropdown
								value={value}
							/>
							<CalendarIcon className="w-6 h-6 ml-2" />
						</label>
					)}
				/>

				<input
					type="submit"
					value="Buscar"
					className="rounded-md px-4 py-2 shadow-md duration-300 border-[1px] font-semibold hover:cursor-pointer"
				/>
			</form>

			<div className="w-full px-2 lg:mx-auto grid md:grid-cols-2 sm:grid-cols-1 2xl:grid-cols-3 gap-3">
				{appointments.length > 0 ? (
					<React.Fragment>
						{appointments.map((appointment: any) => {
							// eslint-disable-next-line array-callback-return
							listPatients.map((patient: any) => {
								if (appointment.patientUserEntityId === patient.value) {
									appointment.patientFullName = patient.label;
								}
							});

							return <AppointmenItem key={appointment.id} {...appointment} />;
						})}
					</React.Fragment>
				) : (
					<div>No se encontro citas</div>
				)}
			</div>
		</div>
	);
};

export default Appointments;
