/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetMedicalCondition from '../../hooks/useGetMedicalCondition';
import MedicalConditionsItem from './MedicalConditionsItem';

const MedicalConditions = (props: any) => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data, error, isLoading }: any = useGetMedicalCondition(id);

	return (
		<div className="w-full p-5 sm:p-7 flex flex-col space-y-7">
			<h1 className="text-center  text-2xl text-[#F6A01E] font-semibold">
				Condiciones y Alergias
			</h1>
			<div className="space-y-2">
				{isLoading ? (
					<div>Cargando...</div>
				) : (
					<React.Fragment>
						{data?.ConditionMedicine?.map((data: any) => (
							<React.Fragment>
								<MedicalConditionsItem
									className="border-2 border-gray-300"
									key={data.id}
									{...data}
								/>
							</React.Fragment>
						))}
					</React.Fragment>
				)}
			</div>
			<div className="w-full text-center">
				<input
					type={'button'}
					className="w-fit px-4 py-1 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 cursor-pointer"
					onClick={() => {
						navigate(`/patientprofile/${id}`);
					}}
					value="Volver"
				/>
			</div>
		</div>
	);
};

export default MedicalConditions;
