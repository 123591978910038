/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IdentificationIcon,
	OfficeBuildingIcon,
	MapIcon,
} from '@heroicons/react/outline';
import React, { Fragment } from 'react';
import MapPin from '../../assets/svg/MapPin.svg';
import Entities from '../../assets/svg/entidades.svg';

const FormEntity = (props: any) => {
	const {
		Controller,
		Select,
		options,
		customStyles,
		NumberFormat,
		emailValidation,
		websiteValidation,
		Switch,
		register,
		errors,
		control,
		setIsRequired,
		watch,
		isRequired,
		mode,
		CustomValueContainer,
	} = props;

	return (
		<Fragment>
			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
				<img src={Entities} className={`w-12`} alt="" />

				<h1 className="text-xl first-line:capitalize">Información Comercial</h1>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="entityType"
					rules={{
						required: {
							value: true,
							message: 'Seleccione el tipo de entidad que registrara',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => {
						return (
							<Select
								components={{
									ValueContainer: CustomValueContainer,
								}}
								placeholder={`${mode === 'register' ? 'Tipo de entidad*' : ''}`}
								isClearable
								styles={customStyles}
								ref={ref}
								isDisabled={mode === 'register' ? false : true}
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								options={options.entityType}
							/>
						);
					}}
				/>
				{errors.entityType && (
					<p className="text-sm text-red-600 mt-2">
						{errors.entityType.message}
					</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative ">
				<input
					id="legalName"
					name="legalName"
					type="text"
					className={`input-style peer ${
						mode !== 'register'
							? 'disabled:bg-[#e4e4e4] disabled:cursor-not-allowed opacity-50'
							: ''
					}`}
					placeholder="Nombre Legal*"
					disabled={mode === 'register' ? false : true}
					{...register(`legalName`, {
						required: {
							value: true,
							message: 'Nombre legal es requerido',
						},
					})}
				/>
				<label
					htmlFor="legalName"
					className={` ${
						mode !== 'register'
							? 'disabled:bg-gray-300 disabled:cursor-not-allowed hidden'
							: 'input-style-label'
					}`}>
					Nombre Legal*
				</label>
				{errors.legalName && (
					<span className="text-red-600">{errors.legalName.message}</span>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					id="comercialName"
					name="comercialName"
					type="text"
					className="input-style peer"
					placeholder="Nombre Comercial*"
					{...register(`comercialName`, {
						required: {
							value: true,
							message: 'Nombre Comercial es requerido',
						},
					})}
				/>
				<label htmlFor="comercialName" className="input-style-label">
					Nombre Comercial*
				</label>
				{errors.comercialName && (
					<span className="text-red-600">{errors.comercialName.message}</span>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="ein"
					rules={{
						required: {
							// value: watch('entityType')?.value === 1 ? true : false,
							value: true,
							message: 'EIN es requerido',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="ein"
							name="ein"
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							ref={ref}
							// placeholder={`EIN ${watch('entityType')?.value === 1 ? '*' : ''}`}
							placeholder={`EIN*`}
							displayType={'input'}
							format="##########"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="ein">
					{/* {`EIN ${watch('entityType')?.value === 1 ? '*' : ''}`} */}
					{`EIN*`}
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="npi"
					id="npi"
					rules={{
						required: {
							value: watch('entityType')?.value === 1 ? true : false,
							message: 'NPI es requerido',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="npi"
							name="npi"
							className="input-style peer"
							thousandSeparator={true}
							ref={ref}
							onChange={onChange}
							onBlur={onBlur}
							placeholder={`NPI ${watch('entityType')?.value === 1 ? '*' : ''}`}
							displayType={'input'}
							format="##########"
							value={value}
						/>
					)}
				/>
				<label htmlFor="npi" className="input-style-label">
					{`NPI ${watch('entityType')?.value === 1 ? '*' : ''}`}
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					id="webPage"
					name="webPage"
					type="text"
					className="input-style peer"
					placeholder="Web - www.example.com"
					{...register(`webPage`, {
						// required: {
						// 	value: true,
						// 	message: 'Pagina web es requerida',
						// },
						pattern: {
							value: websiteValidation,
							message: 'Formato de web incorrecto',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="webPage">
					Web www.ej.ejm
				</label>
				{errors.webPage && (
					<span className="text-red-600">{errors.webPage.message}</span>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					type="text"
					id="email"
					className="input-style peer"
					placeholder="Correo electrónico*"
					{...register('email', {
						required: {
							value: true,
							message: 'Correo electrónico es requerido',
						},
						pattern: {
							value: emailValidation,
							message: 'Correo electrónico Invalido',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="email">
					Correo electrónico*
				</label>
				{errors.email && (
					<p className="text-sm text-red-600 mt-2">{errors.email.message}</p>
				)}
			</div>

			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
				{/* <img src={`${}`} className={`w-12`} alt="" /> */}
				<IdentificationIcon
					strokeWidth={1.3}
					color="#294fa9"
					className={`w-12 `}
				/>
				<h1 className="text-xl first-line:capitalize">Contacto</h1>
			</div>
			{/* <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div> */}

			{/* <div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="isMobilePatient"
					render={({
						field: { onChange, onBlur, value = false, ref },
					}: any) => (
						<label
							className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="isMobilePatient">
							<Switch
								id="isMobilePatient"
								name="isMobilePatient"
								onChange={onChange}
								height={16}
								width={30}
								checked={value}
								checkedIcon={false}
								uncheckedIcon={false}
								className={`mr-5`}
							/>
							<span>Es Celular</span>
						</label>
					)}
				/>
			</div> */}

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="phoneTwo"
					// rules={{
					// 	required: { value: true, message: 'Celular es requerido' },
					// }}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="phoneTwo"
							name="phoneTwo"
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							ref={ref}
							placeholder="Celular"
							displayType={'input'}
							format="### ### ####"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="phoneTwo">
					Celular
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="phone"
					rules={{
						required: { value: true, message: 'Teléfono es requerido' },
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="phone"
							name="phone"
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							ref={ref}
							placeholder="Teléfono fijo*"
							displayType={'input'}
							format="### ### ####"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="phone">
					Teléfono fijo*
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="phoneExt"
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							name="phoneExt"
							id="phoneExt"
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							placeholder="Extension"
							displayType={'input'}
							format="####"
							value={value}
							ref={ref}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="phoneExt">
					Extension
				</label>
			</div>

			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
				{/* <img src={`${}`} className={`w-12`} alt="" /> */}
				{/* <img src={MapPin} alt={``} className={`w-12`} /> */}
				<MapIcon strokeWidth={1.3} className={`w-12 text-[#294fa9] `} />
				<h1 className="text-xl first-line:capitalize">Dirección física</h1>
			</div>
			{/* <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div> */}

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					id="firstLline"
					name="firstLline"
					type="text"
					className="input-style peer"
					placeholder="Dirección Linea 1*"
					{...register(`firstLline`, {
						required: {
							value: true,
							message: 'Drireccion Linea 1 es requerida',
						},
					})}
				/>
				<label className="input-style-label" htmlFor="firstLline">
					Dirección Linea 1*
				</label>

				{errors.firstLline && (
					<span className="text-red-600">{errors.firstLline.message}</span>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<input
					id="secondLline"
					name="secondLline"
					type="text"
					className="input-style peer"
					placeholder="Dirección Linea 2"
					{...register(`secondLline`, {})}
				/>
				<label className="input-style-label" htmlFor="secondLline">
					Dirección Linea 2
				</label>
				{/* {errors.secondLline && (
					<span className="text-red-600">{errors.secondLline.message}</span>
				)} */}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name={'state'}
					rules={{
						required: {
							value: true,
							message: 'Pueblo/Municipio es requerido',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							isClearable
							className="w-full"
							//className="input-style peer"
							placeholder="Pueblo/Municipio*"
							styles={customStyles}
							onBlur={onBlur}
							ref={ref}
							onChange={onChange}
							options={options.city}
							value={value}
							components={{
								ValueContainer: CustomValueContainer,
							}}
						/>
					)}
				/>
				{errors.state && (
					<p className="text-sm text-red-600 mt-2">{errors.state.message}</p>
				)}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="zipCode"
					// rules={{
					// 	required: { value: true, message: 'Código Postal es requerido' },
					// }}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="zipCode"
							name="zipCode"
							ref={ref}
							className="input-style peer" //className="w-full h-10 focus:outline-none bg-transparent"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							placeholder="Código Postal"
							displayType={'input'}
							format="#####"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="zipCode">
					Código Postal
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="country"
					rules={{
						required: {
							value: true,
							message: 'Pais es requerido',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							components={{
								ValueContainer: CustomValueContainer,
							}}
							isClearable
							placeholder="Pais*"
							styles={customStyles}
							ref={ref}
							onBlur={onBlur}
							onChange={onChange}
							options={[{ value: 1, label: 'Puerto Rico' }]}
							value={value}
						/>
					)}
				/>
				{errors.country && (
					<p className="text-sm text-red-600 mt-2">{errors.country.message}</p>
				)}
			</div>

			<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
				{/* <img src={`${}`} className={`w-12`} alt="" /> */}
				{/* <img src={MapPin} alt={``} className={`w-12`} /> */}
				<MapIcon strokeWidth={1.3} className={`w-12 text-[#294fa9] `} />
				<h1 className="text-xl ">Po box</h1>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="pobox"
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							className="input-style peer"
							thousandSeparator={true}
							onChange={onChange}
							onBlur={onBlur}
							ref={ref}
							placeholder="Pobox"
							displayType={'input'}
							format="#######"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="pobox">
					Pobox
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name={'poboxState'}
					// rules={{
					// 	required: {
					// 		value: false,
					// 		message: 'Seleccione el tipo de entidad que registrara',
					// 	},
					// }}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							id={'poboxState'}
							name={'poboxState'}
							isClearable
							className="w-full"
							//className="input-style peer"
							placeholder="Pueblo/Municipio"
							styles={customStyles}
							onBlur={onBlur}
							ref={ref}
							onChange={onChange}
							options={options.city}
							value={value}
							components={{
								ValueContainer: CustomValueContainer,
							}}
						/>
					)}
				/>
				{/* {errors.poboxState && (
					<p className="text-sm text-red-600 mt-2">
						{errors.poboxState.message}
					</p>
				)} */}
			</div>

			<div className="w-11/12 lg:w-64 h-full relative">
				<Controller
					control={control}
					name="poboxZipCode"
					// rules={{
					// 	required: { value: true, message: 'Código Postal es requerido' },
					// }}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<NumberFormat
							id="poboxZipCode"
							name="poboxZipCode"
							className="input-style peer" //className="w-full h-10 focus:outline-none bg-transparent"
							thousandSeparator={true}
							onChange={onChange}
							ref={ref}
							onBlur={onBlur}
							placeholder="Código Postal"
							displayType={'input'}
							format="#####"
							value={value}
						/>
					)}
				/>
				<label className="input-style-label" htmlFor="poboxZipCode">
					Código Postal
				</label>
			</div>

			<div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="poboxCountry"
					// rules={{
					// 	required: {
					// 		value: false,
					// 		message: 'Pais es requerido',
					// 	},
					// }}
					render={({ field: { onChange, onBlur, value, ref } }: any) => (
						<Select
							id="poboxCountry"
							name="poboxCountry"
							components={{
								ValueContainer: CustomValueContainer,
							}}
							isClearable
							placeholder="Pais"
							styles={customStyles}
							ref={ref}
							onBlur={onBlur}
							onChange={onChange}
							options={[{ value: 1, label: 'Puerto Rico' }]}
							value={value}
						/>
					)}
				/>
				{/* {errors.poboxCountry && (
					<p className="text-sm text-red-600 mt-2">
						{errors.poboxCountry.message}
					</p>
				)} */}
			</div>
		</Fragment>
	);
};

export default FormEntity;
