const entityType = [
	{ value: 0, label: 'Nursing Home' },
	{ value: 1, label: 'Clinica' },
];

const roleList = [
	{ value: 7, label: 'Administrador/a del hogar' },
	{ value: 8, label: 'Administrador/a de la clinica' },
	{ value: 10, label: 'Asistente del proyecto' },
	{ value: 6, label: 'Cuidador/a' },
	{ value: 9, label: 'Director/a del proyecto' },
	{ value: 1, label: 'Doctor/a' },
	{ value: 3, label: 'Enfermero/a' },
	{ value: 11, label: 'Evaluador del proyecto' },
	{ value: 2, label: 'Secretario/a' },
	{ value: 5, label: 'Super Administrador' },
];

const specialtyOptions = [
	{ value: 17, label: 'Medicina física y rehabilitación' },
	{ value: 18, label: 'Medicina forense' },
	{ value: 19, label: 'Medicina intensiva' },
	{ value: 20, label: 'Medicina interna' },
	{ value: 21, label: 'Medicina preventiva y salud pública' },
	{ value: 22, label: 'Medicina del trabajo' },
	{ value: 23, label: 'Nefrología' },
	{ value: 24, label: 'Neumología' },
	{ value: 25, label: 'Neurología' },
	{ value: 26, label: 'Nutriología' },
	{ value: 27, label: 'Oncología médica' },
	{ value: 28, label: 'Oncología radioterápica' },
	{ value: 29, label: 'Pediatría' },
	{ value: 30, label: 'Psiquiatría' },
	{ value: 31, label: 'Reumatología' },
	{ value: 32, label: 'Toxicología' },
	{ value: 34, label: 'Alergología' },
	{ value: 33, label: 'Anestesiología' },
	{ value: 35, label: 'Angiología' },
	{ value: 36, label: 'Cardiología' },
	{ value: 37, label: 'Endocrinología' },
	{ value: 38, label: 'Estomatología' },
	{ value: 39, label: 'Farmacología Clínica' },
	{ value: 40, label: 'Gastroenterología' },
	{ value: 41, label: 'Genética' },
	{ value: 42, label: 'Geriatría' },
	{ value: 43, label: 'Hematología' },
	{ value: 44, label: 'Hepatología' },
	{ value: 45, label: 'Infectología' },
	{ value: 46, label: 'Medicina aeroespacial' },
	{ value: 47, label: 'Medicina del deporte' },
	{ value: 48, label: 'Medicina familiar y comunitaria' },
];

const relationshipOptions = [
	{ value: 'son', label: 'Hijo' },
	{ value: 'stepson', label: 'Hijastro' },
	{ value: 'daughter', label: 'Hija' },
	{ value: 'stepdaughter', label: 'Hijastra' },
	{ value: 'father', label: 'Padre' },
	{ value: 'stepfather', label: 'Padrastro' },
	{ value: 'mother', label: 'Madre' },
	{ value: 'stepmother', label: 'Madrastra' },
	{ value: 'cousin', label: 'Primo' },
	{ value: 'uncle', label: 'Tio' },
	{ value: 'aunt', label: 'Tia' },
	{ value: 'grandfather', label: 'Abuelo' },
	{ value: 'grandmother', label: 'Abuela' },
	{ value: 'husband', label: 'Esposo' },
	{ value: 'wife', label: 'Esposa' },
	{ value: 'brother', label: 'Hermano' },
	{ value: 'sister', label: 'Hermana' },
	{ value: 'sibling', label: 'Sobrino' },
	{ value: 'nephew', label: 'Suegro' },
	{ value: 'niece', label: 'Suegra' },
	{ value: 'grandson', label: 'Nieto' },
	{ value: 'granddaughter', label: 'Nieta' },
	{ value: 'friend', label: 'Amigo' },
	{ value: 'neighbor', label: 'Vecino' },
];

const bloodOptions = [
	{ value: 'A+', label: 'A+' },
	{ value: 'A-', label: 'A-' },
	{ value: 'B+', label: 'B+' },
	{ value: 'B-', label: 'B-' },
	{ value: 'AB+', label: 'AB+' },
	{ value: 'AB-', label: 'AB-' },
	{ value: 'O+', label: 'O+' },
	{ value: 'O-', label: 'O-' },
];

const maritalOptions = [
	{ value: '7', label: 'Casado/a' },
	{ value: '8', label: 'Soltero/a' },
	{ value: '9', label: 'Viudo/a' },
	{ value: '10', label: 'Divorciado/a' },
	{ value: '11', label: 'Separado/a' },
	{ value: '12', label: 'Conviviente' },
];

const genderOptions = [
	{ value: 5, label: 'Femenino' },
	{ value: 4, label: 'Masculino' },
	{ value: 6, label: 'Intersexual' },
];

const sexOptions = [
	{ value: 13, label: 'Se identifica como Hombre' },
	{ value: 14, label: 'Se identifica como Mujer' },
	{
		value: 15,
		label: 'Mujer a Hombre /Hombre Transgénero/Hombre Trans',
	},
	{
		value: 16,
		label: 'Hombre a Mujer /Mujer Transgénero/Mujer Trans',
	},
];

const measurementUnitOptions = [
	{ value: 'mg', label: 'Miligramo (mg)' },
	{ value: 'ml', label: 'Mililitro (ml)' },
	{ value: 'cc', label: 'Centímetro cúbico (cc)' },
];

const country = [
	{ value: 0, label: 'Puerto Rico' },
	{ value: 1, label: 'Estados Unidos' },
];

const state = [{ value: 1, label: 'Puerto Rico' }];

const city = [
	{
		value: 1,
		label: 'Adjuntas',
	},
	{
		value: 2,
		label: 'Aguada',
	},
	{
		value: 3,
		label: 'Aguadilla',
	},
	{
		value: 4,
		label: 'Aguas Buenas',
	},
	{
		value: 5,
		label: 'Aibonito',
	},
	{
		value: 6,
		label: 'Añasco',
	},
	{
		value: 7,
		label: 'Arecibo',
	},
	{
		value: 8,
		label: 'Arroyo',
	},
	{
		value: 9,
		label: 'Barceloneta',
	},
	{
		value: 10,
		label: 'Barranquitas',
	},
	{
		value: 11,
		label: 'Bayamón',
	},
	{
		value: 12,
		label: 'Cabo Rojo',
	},
	{
		value: 13,
		label: 'Caguas',
	},
	{
		value: 14,
		label: 'Camuy',
	},
	{
		value: 15,
		label: 'Canóvanas',
	},
	{
		value: 16,
		label: 'Carolina',
	},
	{
		value: 17,
		label: 'Cataño',
	},
	{
		value: 18,
		label: 'Cayey',
	},
	{
		value: 19,
		label: 'Ceiba',
	},
	{
		value: 20,
		label: 'Ciales',
	},
	{
		value: 21,
		label: 'Cidra',
	},
	{
		value: 22,
		label: 'Coamo',
	},
	{
		value: 23,
		label: 'Comerío',
	},
	{
		value: 24,
		label: 'Corozal',
	},
	{
		value: 25,
		label: 'Culebra',
	},
	{
		value: 26,
		label: 'Dorado',
	},
	{
		value: 27,
		label: 'Fajardo',
	},
	{
		value: 28,
		label: 'Florida',
	},
	{
		value: 29,
		label: 'Guánica',
	},
	{
		value: 30,
		label: 'Guayama',
	},
	{
		value: 31,
		label: 'Guayanilla',
	},
	{
		value: 32,
		label: 'Guaynabo',
	},
	{
		value: 33,
		label: 'Gurabo',
	},
	{
		value: 34,
		label: 'Hatillo',
	},
	{
		value: 35,
		label: 'Hormigueros',
	},
	{
		value: 36,
		label: 'Humacao',
	},
	{
		value: 37,
		label: 'Isabela',
	},
	{
		value: 38,
		label: 'Jayuya',
	},
	{
		value: 39,
		label: 'Juana Díaz',
	},
	{
		value: 40,
		label: 'Juncos',
	},
	{
		value: 41,
		label: 'Lajas',
	},
	{
		value: 42,
		label: 'Lares',
	},
	{
		value: 43,
		label: 'Las Marías',
	},
	{
		value: 44,
		label: 'Las Piedras',
	},
	{
		value: 45,
		label: 'Loíza',
	},
	{
		value: 46,
		label: 'Luquillo',
	},
	{
		value: 47,
		label: 'Manatí',
	},
	{
		value: 48,
		label: 'Maricao',
	},
	{
		value: 49,
		label: 'Maunabo',
	},
	{
		value: 50,
		label: 'Mayagüez',
	},
	{
		value: 51,
		label: 'Moca',
	},
	{
		value: 52,
		label: 'Morovis',
	},
	{
		value: 53,
		label: 'Naguabo',
	},
	{
		value: 54,
		label: 'Naranjito',
	},
	{
		value: 55,
		label: 'Orocovis',
	},
	{
		value: 56,
		label: 'Patillas',
	},
	{
		value: 57,
		label: 'Peñuelas',
	},
	{
		value: 58,
		label: 'Ponce',
	},
	{
		value: 59,
		label: 'Quebradillas',
	},
	{
		value: 60,
		label: 'Rincón',
	},
	{
		value: 61,
		label: 'Río Grande',
	},
	{
		value: 62,
		label: 'Sabana Grande',
	},
	{
		value: 63,
		label: 'Salinas',
	},
	{
		value: 64,
		label: 'San Germán',
	},
	{
		value: 65,
		label: 'San Juan (capital)',
	},
	{
		value: 66,
		label: 'San Lorenzo',
	},
	{
		value: 67,
		label: 'San Sebastián',
	},
	{
		value: 68,
		label: 'Santa Isabel',
	},
	{
		value: 69,
		label: 'Toa Alta',
	},
	{
		value: 70,
		label: 'Toa Baja',
	},
	{
		value: 71,
		label: 'Trujillo Alto',
	},
	{
		value: 72,
		label: 'Utuado',
	},
	{
		value: 73,
		label: 'Vega Alta',
	},
	{
		value: 74,
		label: 'Vega Baja',
	},
	{
		value: 75,
		label: 'Vieques',
	},
	{
		value: 76,
		label: 'Villalba',
	},
	{
		value: 77,
		label: 'Yabucoa',
	},
	{
		value: 78,
		label: 'Yauco',
	},
];

//Selects for create events
const priorities = [
	{ value: 1, label: 'Alta' },
	{ value: 2, label: 'Media' },
	{ value: 3, label: 'Baja' },
];

const status = [
	{ value: 4, label: 'Confirmado' },
	{ value: 5, label: 'Cancelada' },
	{ value: 6, label: 'Pendiente' },
	{ value: 7, label: 'Reagendada' },
	{ value: 8, label: 'Completada' },
];

const assistance = [
	{ value: 9, label: 'En el hogar' },
	{ value: 10, label: 'En el consultorio' },
	{ value: 11, label: 'Llamada' },
	{ value: 12, label: 'Telemedicina TytoCare' },
];

const options = {
	relationshipOptions,
	bloodOptions,
	maritalOptions,
	genderOptions,
	sexOptions,
	measurementUnitOptions,
	country,
	state,
	city,
	priorities,
	status,
	assistance,
	specialtyOptions,
	roleList,
	entityType,
};

export default options;
