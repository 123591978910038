/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	BanIcon,
	CheckCircleIcon,
	ExclamationCircleIcon,
	UsersIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Select from 'react-select';
import { customStyles } from './customStyle';

import Usuario from '../assets/svg/UserSVG.svg';
let testing: string = '';

const LinkUsers = ({ ...props }: any) => {
	const [showData, setShowData] = useState(true);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid, isDirty },
		control,
	} = useForm({ mode: 'onChange' });

	const selectionType = (value: any) => {
		// console.log(value?.value);

		setShowData(true);

		return value;
	};

	return (
		<div className="w-full h-full ">
			<div className="space-y-2">
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					{/* <img src={Usuario} className={`w-20`} alt="" /> */}
					<UsersIcon color="#294fa9" className="h-20 w-20" />
					<h1 className="text-center sm:text-4xl font-extrabold text-xl text-[#96c952]">
						Vincular / Desvincular usuarios
					</h1>
				</div>
				{/*React hook form maneja un select mas dinamico y personalizable este es un ejemplo*/}
				<p>Entidad actual</p>
				<div className="w-full h-full">
					<label
						className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
						htmlFor="entitySelected">
						<Controller
							control={control}
							name="entitySelected"
							render={({ field: { onChange, onBlur, value, ref } }: any) => (
								<Select
									ref={ref}
									className="w-full"
									placeholder={`${testing}`}
									styles={customStyles}
									onBlur={onBlur}
									isDisabled
									onChange={onChange}
									options={[{ value: 1, label: 'Entidad seleccionada' }]}
									value={value}
								/>
							)}
						/>
					</label>
				</div>
				<p>Tipo de usuario que va a añadir a la entidad</p>
				<div className="w-full h-full">
					<label
						className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
						htmlFor="user_type">
						<Controller
							control={control}
							name="user_type"
							render={({ field: { onChange, onBlur, value, ref } }: any) => (
								<Select
									ref={ref}
									className="w-full"
									placeholder="Tipo de usuario.."
									styles={customStyles}
									onBlur={onBlur}
									isClearable
									onChange={onChange}
									options={[
										{ value: 1, label: 'Doctor/a' },
										{ value: 2, label: 'Secretario/a' },
										{ value: 3, label: 'Enfermero/a' },
									]}
									value={value}
								/>
							)}
						/>
					</label>
				</div>
				{/*Habra un evento cuando se seleccione el tipo de usuario dira en variable si es doctor secretario enfermero*/}
				<form className={`space-y-2 ${showData ? `block` : `hidden`}`}>
					<p className="px-2">
						{watch('user_type')?.label.length > 0
							? `Lista de ${watch('user_type')?.label} Disponibles`
							: ''}
					</p>
					{watch('user_type')?.label.length > 0 && (
						<>
							{/* <div className="w-full h-full">
								<label
									className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
									htmlFor="user">
									<Controller
										control={control}
										name="user"
										rules={{
											required: {
												value: false,
												message: 'Tipo de usuario es requerido',
											},
										}}
										render={({
											field: { onChange, onBlur, value, ref },
										}: any) => (
											<Select
												ref={ref}
												className="w-full"
												placeholder="Tipo de usuario.."
												styles={customStyles}
												onBlur={onBlur}
												onChange={onChange}
												options={[
													{ value: 1, label: 'nombre 1' },
													{ value: 2, label: 'Nombre 2' },
													{ value: 3, label: 'Nombre 3' },
												]}
												value={value}
											/>
										)}
									/>
								</label>
								{errors.user && (
									<p className="text-sm text-red-600 mt-2">
										{errors.user.message}
									</p>
								)}
							</div> */}
							<div>
								<div className="grid w-full gap-3 rounded-md border-[1px] px-3 py-2 shadow-sm bg-white">
									<label className="flex w-full flex-row gap-2">
										<input type="checkbox" name="uno" disabled id="uno" />
										<p className="w-full">Nombre apellido</p>
										<BanIcon className="h-6 w-6" />
									</label>
									<label className="flex w-full flex-row gap-2">
										<input type="checkbox" checked name="dos" id="dos" />
										<p className="w-full">Nombre apellido</p>
										<CheckCircleIcon className="h-6 w-6" />
									</label>
									<label className="flex w-full flex-row gap-2">
										<input type="checkbox" name="tres" id="tres" />
										<p className="w-full">Nombre apellido</p>
										<ExclamationCircleIcon className="h-6 w-6" />
									</label>
									<label className="flex w-full flex-row gap-2">
										<input type="checkbox" name="cuatro" id="cuatro" />
										<p className="w-full">Nombre apellido</p>
										<ExclamationCircleIcon className="h-6 w-6" />
									</label>
								</div>
								<div className="my-5 w-full text-center">
									<input
										type="submit"
										defaultValue="Guardar Cambios"
										className="center mb-5 rounded-lg border-[1px] px-4 py-2 font-medium shadow-md duration-300 hover:cursor-pointer"
									/>
								</div>
							</div>
						</>
					)}
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	testing = state.currentEntitySelected;

	return {
		state: {
			currentEntitySelected: state.currentEntitySelected,
		},
	};
};
const mapDispatchToProps = {};

// export default Sidebar;
export default connect(mapStateToProps, null)(LinkUsers);
