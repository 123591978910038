/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import UserIcon from '../../assets/svg/UserSVGBlue.svg';
import Aseguradora from '../../assets/svg/PatientItems/PhotosIcon/icono-aseguradora.svg';
import Licence from '../../assets/svg/PatientItems/PhotosIcon/icono-licenciadeconducir.svg';
import Salud from '../../assets/svg/PatientItems/PhotosIcon/icono-salud.svg';

const PhotoData = (props: any) => {
	const { Controller, control, register, watch, errors } = props;
	const [photoURL, setPhotoURL] = useState<any>(null);
	const [socialSecurityCardURL, setSocialSecurityCardURL] = useState<any>(null);
	const [driverLicecseURL, setDriverLicecseURL] = useState<any>(null);
	const [insuranceCardURL, setInsuranceCardURL] = useState<any>(null);

	// const existFile = (files: any) => {
	// 	let exist = files[0].length > 0;

	// 	if (exist) {
	// 		return true;
	// 	}
	// };

	// const lessThan4MB = (files: any) => {
	// 	let fileSize = files[0]?.size < 4194304;

	// 	if (fileSize) {
	// 		return 'Este archivo supera los 4MB';
	// 	} else {
	// 		return 'Formato de archivo correcto';
	// 	}
	// };

	// const acceptedFormats = (files: any) => {
	// 	let format = ['image/jpeg', 'image/png', 'image/gif'].includes(
	// 		files[0]?.type,
	// 	);

	// 	if (format) {
	// 		return 'Formato de archivo correcto';
	// 	} else {
	// 		return 'Solo se admite los siguientes formatos: PNG, JPEG y GIF';
	// 	}
	// };

	return (
		<div className="grid w-full grid-cols-2 place-items-center gap-5">
			<div className="space-y-5 text-center flex h-full w-full flex-col items-center rounded-md border-[1px] bg-white px-1 py-4 shadow-sm hover:border-sky-500 hover:ring-[1px] hover:ring-sky-500">
				<p>Foto residente</p>
				{photoURL ? (
					<label htmlFor="photoURL" className="cursor-pointer">
						<img src={photoURL} className={`w-48`} alt={`Foto de perfil`} />
					</label>
				) : (
					<label htmlFor="photoURL" className="cursor-pointer">
						{/* <PlusCircleIcon className="h-16 w-16" /> */}
						<img src={UserIcon} className=" h-60" alt="" />
					</label>
				)}
				<input
					className="hidden"
					type="file"
					id="photoURL"
					{...register('photoURL', {
						validate: {
							//   lessThan4MB: lessThan4MB,
							//   acceptedFormats: acceptedFormats,
							//   void: existFile,
							showPhoto: (files: any) => {
								if (Object.entries(files).length === 0) {
									setPhotoURL(``);
								} else {
									const reader = new FileReader();
									reader.onloadend = () => {
										setPhotoURL(`${reader.result}`);
									};
									reader.readAsDataURL(files[0]);
								}
							},
						},
					})}
				/>
				{!watch('photoURL') || watch('photoURL').length === 0 ? (
					''
				) : (
					<strong>{watch('photoURL')[0].name}</strong>
				)}
				{errors.photoURL && (
					<p className="text-sm text-red-600 mt-2">{errors.photoURL.message}</p>
				)}
			</div>

			<div className="space-y-5 text-center flex h-full w-full flex-col items-center rounded-md border-[1px] bg-white px-1 py-4 shadow-sm hover:border-sky-500 hover:ring-[1px] hover:ring-sky-500">
				<p>Foto seguro social</p>
				{socialSecurityCardURL ? (
					<label htmlFor="socialSecurityCardURL" className="cursor-pointer">
						<img
							src={socialSecurityCardURL}
							className={`w-48`}
							alt={`Foto de perfil`}
						/>
					</label>
				) : (
					<label htmlFor="socialSecurityCardURL" className="cursor-pointer">
						<img src={Aseguradora} className="h-60" alt="" />
					</label>
				)}
				<input
					className="hidden"
					type="file"
					id="socialSecurityCardURL"
					{...register('socialSecurityCardURL', {
						validate: {
							//   lessThan4MB: lessThan4MB,
							//   acceptedFormats: acceptedFormats,
							//   void: existFile,
							showPhoto: (files: any) => {
								if (Object.entries(files).length === 0) {
									setSocialSecurityCardURL(``);
								} else {
									const reader = new FileReader();
									reader.onloadend = () => {
										setSocialSecurityCardURL(`${reader.result}`);
									};
									reader.readAsDataURL(files[0]);
								}
							},
						},
					})}
				/>
				{!watch('socialSecurityCardURL') ||
				watch('socialSecurityCardURL').length === 0 ? (
					''
				) : (
					<strong>{watch('socialSecurityCardURL')[0].name}</strong>
				)}
				{errors.socialSecurityCardURL && (
					<p className="text-sm text-red-600 mt-2">
						{errors.socialSecurityCardURL.message}
					</p>
				)}
			</div>

			<div className="space-y-5 text-center flex h-full w-full flex-col items-center rounded-md border-[1px] bg-white px-1 py-4 shadow-sm hover:border-sky-500 hover:ring-[1px] hover:ring-sky-500">
				<p>Foto licencia de conducir</p>
				{driverLicecseURL ? (
					<label htmlFor="driverLicecseURL" className="cursor-pointer">
						<img src={driverLicecseURL} width={`200`} alt={`Foto de perfil`} />
					</label>
				) : (
					<label htmlFor="driverLicecseURL" className="cursor-pointer">
						<img src={Licence} className="h-60" alt="" />
					</label>
				)}
				<input
					className="hidden"
					type="file"
					id="driverLicecseURL"
					{...register('driverLicecseURL', {
						validate: {
							//   lessThan4MB: lessThan4MB,
							//   acceptedFormats: acceptedFormats,
							//   void: existFile,
							showPhoto: (files: any) => {
								if (Object.entries(files).length === 0) {
									setDriverLicecseURL(``);
								} else {
									const reader = new FileReader();
									reader.onloadend = () => {
										setDriverLicecseURL(`${reader.result}`);
									};
									reader.readAsDataURL(files[0]);
								}
							},
						},
					})}
				/>
				{!watch('driverLicecseURL') ||
				watch('driverLicecseURL').length === 0 ? (
					''
				) : (
					<strong>{watch('driverLicecseURL')[0].name}</strong>
				)}
				{errors.driverLicecseURL && (
					<p className="text-sm text-red-600 mt-2">
						{errors.driverLicecseURL.message}
					</p>
				)}
			</div>

			<div className="space-y-5 text-center flex h-full w-full flex-col items-center rounded-md border-[1px] bg-white px-1 py-4 shadow-sm hover:border-sky-500 hover:ring-[1px] hover:ring-sky-500">
				<p>Foto Aseguradora</p>
				{insuranceCardURL ? (
					<label htmlFor="insuranceCardURL" className="cursor-pointer">
						<img src={insuranceCardURL} width={`200`} alt={`Foto de perfil`} />
					</label>
				) : (
					<label htmlFor="insuranceCardURL" className="cursor-pointer">
						<img
							src={Salud}
							className="h-60 "
							alt=""
						/>
					</label>
				)}
				<input
					className="hidden"
					type="file"
					id="insuranceCardURL"
					{...register('insuranceCardURL', {
						validate: {
							//   lessThan4MB: lessThan4MB,
							//   acceptedFormats: acceptedFormats,
							//   void: existFile,
							showPhoto: (files: any) => {
								if (Object.entries(files).length === 0) {
									setInsuranceCardURL(``);
								} else {
									const reader = new FileReader();
									reader.onloadend = () => {
										setInsuranceCardURL(`${reader.result}`);
									};
									reader.readAsDataURL(files[0]);
								}
							},
						},
					})}
				/>
				{!watch('insuranceCardURL') ||
				watch('insuranceCardURL').length === 0 ? (
					''
				) : (
					<strong>{watch('insuranceCardURL')[0].name}</strong>
				)}
				{errors.insuranceCardURL && (
					<p className="text-sm text-red-600 mt-2">
						{errors.insuranceCardURL.message}
					</p>
				)}
			</div>
		</div>
	);
};

export default PhotoData;
