import api from './Api';
import TokenService from './Token';

export const clinicProfile = async (id: number) => {
	const user = TokenService.getUser();
	// console.log('capturado ', id);

	const response = await api.instanceEntities.post(
		`/clinic/get-profile-clinic-by-id`,
		{
			clinicId: id,
		},
		{
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		},
	);

	return response;
};

export const homeProfile = async (id: number) => {
	const user = TokenService.getUser();
	// console.log('capturado ', id);

	const response = await api.instanceEntities.post(
		`/nursing_home/get-profile-home-by-id`,
		{
			clinicId: id,
		},
		{
			headers: {
				Authorization: `${JSON.parse(user).TokenType} ${
					JSON.parse(user).IdToken
				}`,
				'Content-Type': 'application/json',
			},
		},
	);

	return response;
};
