/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { clearUpdatePatientMedicationForm } from '../../actions';
import { connect } from 'react-redux';
import options from '../../data/selectOptions';
import UpdatePatientMedication from '../../services/UpdatePatientMedication';
import MedicineInfo from '../RegisterPatient/MedicineInfo';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';

const UpdateMedicationForm = (props: any) => {
	const [user, initUser] = useState<any>(null);

	const navigate = useNavigate();
	////console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
	const {
		register,
		watch,
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
	} = useForm({
		mode: 'onChange',
	});

	const {
		fields: medicineFields,
		remove: medicineRemove,
		append: medicineAppend,
	} = useFieldArray({
		name: 'medicine',
		control,
	});
	//console.log(`props:\n${JSON.stringify(props, null, 4)}`);

	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				initUser({
					nameMedicine: props?.updatePatientMedicationForm?.medicine,
					dosage: props?.updatePatientMedicationForm?.dosage,
					frequency: props?.updatePatientMedicationForm?.frequency,
					rxNumber: props?.updatePatientMedicationForm?.rxNumber,
					indications: props?.updatePatientMedicationForm?.indications,
					measurementUnit: options.measurementUnitOptions
						.filter((item: any) => {
							////console.log(`perico: ${item?.value}`);

							if (
								item?.label ===
								props?.updatePatientMedicationForm?.measurementUnit
							) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],
				});
			}, 1000);
		}
	});

	//console.log(`user: \n${JSON.stringify(user)}`);
	useEffect(() => {
		reset({ medicine: [user] });
	}, [user]);

	const onSubmit = async (values: any) => {
		values.medicine[0].id = props?.updatePatientMedicationForm?.patient;
		values.medicine[0].medicineId =
			props?.updatePatientMedicationForm?.medicineId;

		//console.log(`valores data: \n ${JSON.stringify(values.medicine[0], null, 4)}`);
		const dataUpdate: any =
			await UpdatePatientMedication.UpdatePatientMedication(values.medicine[0]);
		if (dataUpdate.code === 200) {
			//alert(JSON.stringify(values, null, 4));
			clearUpdatePatientMedicationForm(null);
			reset({
				nameMedicine: '',
				dosage: '',
				frequency: '',
				rxNumber: '',
				indications: '',
				measurementUnit: {},
			});

			initUser({});
			navigate(
				`/patientprofile/${props?.updatePatientMedicationForm?.patient}/`,
			);
		} else {
			navigate(
				`/patientprofile/${props?.updatePatientMedicationForm?.patient}/`,
			);
		}
	};

	const trashSubmit = async (values: any) => {
		//console.log(`values ${JSON.stringify(values, null, 4)}`);
		const dataUpdate: any =
			await UpdatePatientMedication.UpdatePatientMedication(values);
		if (dataUpdate.code === 200) {
			navigate(
				`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
			);
		} else {
			navigate(
				`/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
			);
		}
	};

	const { ValueContainer, Placeholder } = components;

	const CustomValueContainer = ({ children, ...props }: any) => {
		return (
			<ValueContainer {...props}>
				<Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
					child && child.type !== Placeholder ? child : null,
				)}
			</ValueContainer>
		);
	};

	return (
		<div className="w-full space-y-4 ">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					<PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
					<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
						Editando Medicamento
					</h2>
				</div>
				<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col">
					<MedicineInfo
						Controller={Controller}
						Select={Select}
						control={control}
						register={register}
						medicineFields={medicineFields}
						medicineRemove={medicineRemove}
						medicineAppend={medicineAppend}
						CustomValueContainer={CustomValueContainer}
						mode={'update'}
					/>

					<div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<input
							type={'button'}
							value="Volver"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							onClick={() => {
								navigate(
									`/patientprofile/${props?.updatePatientMedicationForm?.patient}/`,
								);
							}}
						/>
						<input
							type="submit"
							value="Guardar"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};
const mapStateToProps = (state: any) => {
	return {
		updatePatientMedicationForm: state.updatePatientMedicationForm,
	};
};
const mapDispatchToProps = {
	clearUpdatePatientMedicationForm,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UpdateMedicationForm);
