/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { clearUpdatePatientVaccineForm } from '../../actions';
import { connect } from 'react-redux';
import VaccineInfo from '../RegisterPatient/VaccineInfo';
import { CalendarIcon } from '@heroicons/react/outline';
import DatePicker from 'react-datepicker';
import UpdatePatientVaccine from '../../services/UpdatePatientVaccine';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';

const UpdateVaccineForm = (props: any) => {
	const [user, initUser] = useState<any>(null);

	const navigate = useNavigate();
	////console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
	const {
		register,
		watch,
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
	} = useForm({
		mode: 'onChange',
	});

	const {
		fields: vaccineFields,
		append: vaccineAppend,
		remove: vaccineRemove,
	} = useFieldArray({
		name: 'vaccines',
		control,
	});
	//console.log(`props:\n${JSON.stringify(props, null, 4)}`);

	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				initUser({
					vaccineName: props?.updatePatientVaccineForm?.vaccineName,
					dosage: props?.updatePatientVaccineForm?.dosage,
					doseDate: new Date(props?.updatePatientVaccineForm?.doseDate),
					frequency: props?.updatePatientVaccineForm?.frequency,
					pharmaceuticalCompany:
						props?.updatePatientVaccineForm?.pharmaceuticalCompany,
				});
			}, 1000);
		}
	});

	//console.log(`user: \n${JSON.stringify(user)}`);
	useEffect(() => {
		reset({ vaccines: [user] });
	}, [user]);

	const onSubmit = async (values: any) => {
		values.vaccines[0].id = props?.updatePatientVaccineForm?.patient;
		values.vaccines[0].vaccineId = props?.updatePatientVaccineForm?.id;

		//console.log(`valores data: \n ${JSON.stringify(values.vaccines[0], null, 4)}`);
		const dataUpdate: any = await UpdatePatientVaccine.UpdatePatientVaccine(
			values.vaccines[0],
		);
		if (dataUpdate.code === 200) {
			//alert(JSON.stringify(values, null, 4));
			clearUpdatePatientVaccineForm(null);
			reset({
				vaccineName: '',
				dosage: '',
				doseDate: '',
				frequency: '',
				pharmaceuticalCompany: '',
			});

			initUser({});
			navigate(`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`);
		} else {
			navigate(`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`);
		}
	};
	const trashSubmit = async (values: any) => {
		console.log(`values ${JSON.stringify(values, null, 4)}`);
		const dataUpdate: any = await UpdatePatientVaccine.DeletePatientVaccine(
			values,
		);
		if (dataUpdate.code === 200) {
			navigate(`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`);
		} else {
			navigate(`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`);
		}
	};
	return (
		<div className="w-full space-y-4 ">
			<form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					<PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
					<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
						Editando Vacuna
					</h2>
				</div>

				<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col p-7">
					<VaccineInfo
						Controller={Controller}
						DatePicker={DatePicker}
						control={control}
						register={register}
						vaccineFields={vaccineFields}
						vaccineRemove={vaccineRemove}
						vaccineAppend={vaccineAppend}
						CalendarIcon={CalendarIcon}
						mode={'update'}
					/>

					<div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<input
							type={'button'}
							value="Volver"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							onClick={() => {
								navigate(
									`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`,
								);
							}}
						/>
						<input
							type="submit"
							value="Guardar"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
						/>
						<div
							className="md:w-32 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() => trashSubmit(props?.updatePatientVaccineForm)}>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
const mapStateToProps = (state: any) => {
	return {
		updatePatientVaccineForm: state.updatePatientVaccineForm,
	};
};
const mapDispatchToProps = {
	clearUpdatePatientVaccineForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVaccineForm);
