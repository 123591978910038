const SumaryEntityRegisterData = ({
	setModalOn,
	setChoice,
	onSubmit,
	setIsLoading,
	formData,
}: any) => {
	const handleOKClick = () => {
		onSubmit();
		setChoice(true);
		setModalOn(false);
	};
	const handleCancelClick = () => {
		setIsLoading(true);
		setChoice(false);
		setModalOn(false);
	};

	console.log(formData);

	return (
		<div className=" opacity-100 fixed inset-0 z-50">
			<div className="flex h-screen justify-center items-center ">
				<div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
					<div className="flex flex-col text-lg text-zinc-600 max-w-xl space-y-4">
						<p className="font-bold text-2xl">Resumen del registro</p>
						<p className="font-bold text-base text-center">
							¿Esta seguro de registrar este usuario?
						</p>
						<div className="flex flex-col">
							<div className="flex flex-row gap-1">
								<p className="font-bold">Tipo de entidad:</p>
								<p>{formData?.entityType?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Nombre Legal:</p>
								<p>{formData?.legalName}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Nombre Comercial:</p>
								<p>{formData?.comercialName}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Primer Nombre:</p>
								<p>{formData?.firstLline}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Segundo Nombre:</p>
								<p>{formData?.secondLline}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">EIN:</p>
								<p>{formData?.ein}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">NPI:</p>
								<p>{formData?.npi}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Sitio Web:</p>
								<p>{formData?.webPage}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Correo:</p>
								<p>{formData?.email}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Celular:</p>
								<p>{formData?.phoneTwo}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Telefono Fijo:</p>
								<p>{formData?.phone}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Extension:</p>
								<p>{formData?.phoneExt}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Pueblo/Municipio:</p>
								<p>{formData?.state?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Codigo Postal:</p>
								<p>{formData?.zipCode}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Pais:</p>
								<p>{formData?.country?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Pobox:</p>
								<p>{formData?.pobox}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Pueblo/Municipio pobox:</p>
								<p>{formData?.poboxState?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Codigo Postal Pobox:</p>
								<p>{formData?.poboxZipCode}</p>
							</div>
							<div className="flex flex-row gap-1">
								<p className="font-bold">Pais Pobox:</p>
								<p>{formData?.poboxCountry?.label}</p>
							</div>
						</div>
					</div>
					<div className="flex">
						<div className="flex-1" />
						<button
							onClick={handleOKClick}
							className=" rounded px-4 py-2 text-white bg-green-400 ">
							Si
						</button>
						<button
							onClick={handleCancelClick}
							className="rounded px-4 py-2 ml-4 text-white bg-blue-500 ">
							No
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SumaryEntityRegisterData;
