/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import DoctorInfo from '../RegisterPatient/DoctorInfo';
import NumberFormat from 'react-number-format';
import { clearUpdatePatientDoctorForm } from '../../actions';
import { connect } from 'react-redux';
import options from '../../data/selectOptions';
import UpdatePatientDoctor from '../../services/updatePatientDoctor';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';

const UpdateDoctorForm = (props: any) => {
	// const [stateData, setStateData] = useState<any>();
	// const location: any = useLocation();
	// const [isRequired, setIsLoading] = useState(false);
	const [user, initUser] = useState<any>(null);

	const navigate = useNavigate();
	//console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
	const {
		register,
		watch,
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
	} = useForm({
		mode: 'onChange',
	});

	const {
		fields: specialtyDoctorFields,
		remove: specialtyDoctorRemove,
		append: specialtyDoctorAppend,
	} = useFieldArray({
		name: 'specialtyDoctor',
		control,
	});
	//console.log(`props:\n${JSON.stringify(props, null, 4)}`);

	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				initUser({
					doctorFirstname: props?.updatePatientDoctorForm?.firstname,
					doctorSurname: props?.updatePatientDoctorForm?.surname,
					clinicalName: props?.updatePatientDoctorForm?.clinicalName,
					doctorJobAddress: props?.updatePatientDoctorForm?.jobAddress,
					doctorJobAddressAlt: props?.updatePatientDoctorForm?.JobAddressAlt,
					doctorZipCode: props?.updatePatientDoctorForm?.zipCode,
					doctorPhone: props?.updatePatientDoctorForm?.phone?.number,
					extDoctorPhone: props?.updatePatientDoctorForm?.phone?.extension,

					doctorLocalPhone:
						props?.updatePatientDoctorForm?.doctorLocalPhone?.number,
					extDoctorLocalPhone:
						props?.updatePatientDoctorForm?.doctorLocalPhone?.extension,

					doctorEmail: props?.updatePatientDoctorForm?.email,
					clinicalMedicalRecord:
						props?.updatePatientDoctorForm?.clinicalMedicalRecord,

					doctorJobCountry: options.state
						.filter((item: any) => {
							if (item?.label === props?.updatePatientDoctorForm?.JobCountry) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],
					doctorJobState: options.city
						.filter((item: any) => {
							if (item?.label === props?.updatePatientDoctorForm?.JobState) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],
					doctorSpecialty: options.specialtyOptions
						.filter((item: any) => {
							if (item?.label === props?.updatePatientDoctorForm?.specialty) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],
				});
			}, 1000);
		}
	});

	//console.log(`user: \n${JSON.stringify(user)}`);
	useEffect(() => {
		reset({ specialtyDoctor: [user] });
	}, [user]);

	const onSubmit = async (values: any) => {
		values.specialtyDoctor[0].id = props?.updatePatientDoctorForm?.patient;
		values.specialtyDoctor[0].doctorId = props?.updatePatientDoctorForm?.id;

		//console.log(`valores data: \n ${JSON.stringify(values.specialtyDoctor[0], null, 4)}`,);
		const dataUpdate: any = await UpdatePatientDoctor.UpdatePatientDoctor(
			values.specialtyDoctor[0],
		);
		if (dataUpdate.code === 200) {
			//alert(JSON.stringify(values, null, 4));
			clearUpdatePatientDoctorForm(null);
			reset({
				ctorFirstname: '',
				doctorSurname: '',
				clinicalName: '',
				doctorJobAddress: '',
				doctorJobAddressAlt: '',
				doctorZipCode: '',
				doctorPhone: '',
				extDoctorPhone: '',
				doctorEmail: '',
				clinicalMedicalRecord: '',
				doctorLocalPhone: '',
				extDoctorLocalPhone: '',
				doctorJobCountry: {},
				doctorJobState: {},
				doctorSpecialty: {},
			});

			initUser({});
			navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`);
		} else {
			navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`);
		}
	};
	const trashSubmit = async (values: any) => {
		//console.log(`values ${JSON.stringify(values, null, 4)}`);
		//values.specialtyDoctor[0].userId = props?.updatePatientDoctorForm?.id;
		const dataUpdate: any = await UpdatePatientDoctor.DeletePatientDoctor(
			values,
		);
		if (dataUpdate.code === 200) {
			navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`);
		} else {
			navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`);
		}
	};

	const { ValueContainer, Placeholder } = components;

	const CustomValueContainer = ({ children, ...props }: any) => {
		return (
			<ValueContainer {...props}>
				<Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
					child && child.type !== Placeholder ? child : null,
				)}
			</ValueContainer>
		);
	};

	return (
		<div className="w-full space-y-4 ">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					<PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
					<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
						Editando Doctor
					</h2>
				</div>
				<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7 p-7">
					<DoctorInfo
						Controller={Controller}
						NumberFormat={NumberFormat}
						Select={Select}
						control={control}
						errors={errors}
						register={register}
						specialtyDoctorFields={specialtyDoctorFields}
						specialtyDoctorRemove={specialtyDoctorRemove}
						specialtyDoctorAppend={specialtyDoctorAppend}
						CustomValueContainer={CustomValueContainer}
						mode={'update'}
					/>

					<div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<input
							type={'button'}
							value="Volver"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							onClick={() => {
								navigate(
									`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`,
								);
							}}
						/>
						<input
							type="submit"
							value="Guardar"
							className="md:w-32 h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
						/>
						<div
							className="md:w-32 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() => trashSubmit(props?.updatePatientDoctorForm)}>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
const mapStateToProps = (state: any) => {
	return {
		updatePatientDoctorForm: state.updatePatientDoctorForm,
	};
};
const mapDispatchToProps = {
	clearUpdatePatientDoctorForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDoctorForm);
