/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/Api';

const useProfilePatient = (userid: any) => {
	const [data, setData] = useState<any>();
	const [dataImage, setDataImage] = useState<any>();
	const [isLoading, setIsLoading] = useState(true);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const navigator = useNavigate();

	useEffect(() => {
		const fetchdata = async () => {
			let user: any = localStorage.getItem('user');

			setIsLoading(true);
			await api.instanceUsers
				.post(
					`/user/get-profile-patient/`,
					{ user_id: userid },
					{
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
							USER_ENTITY_ID: `${localStorage.getItem('role')}`,
						},
						withCredentials: true,
					},
				)
				.then((response) => {
					setData(response.data.data[0]);
				})
				.catch((err) => {
					setData(null);
					//console.log(err.response);
					//console.log(err?.response?.status);
					if ([401].includes(err?.response?.status) && data === null) {
						fetchdata();
					} else {
						navigator('/patients');
					}
				});

			api.instanceUsers
				.post(
					`/profile/load-images-docs/`,
					{ userId: userid },
					{
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
							USER_ENTITY_ID: `${localStorage.getItem('role')}`,
						},
						withCredentials: true,
					},
				)
				.then((response) => {
					// console.log(
					// 	`comentario response img: ${JSON.stringify(response, null, 4)} `,
					// );

					setDataImage(response.data.data[0]);
				})
				.catch((err) => {
					setDataImage(null);
					//console.log(err.response);
					//console.log(err?.response?.status);
					if ([401].includes(err?.response?.status) && data === null) {
						fetchdata();
					}
				});

			setIsLoading(false);
		};
		fetchdata();
	}, [userid]);
	// console.log(data);
	return { data: { ...data, ...dataImage }, isLoading };
};

export default useProfilePatient;
