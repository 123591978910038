/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { number } from 'yup/lib/locale';
import api from '../services/Api';
import { dataTransformer } from '../utils/DataTransformer/entitylist/dataTransformer';

const useGetEntity = (obj: any) => {
	const [dataHomeList, setDataHomeList] = useState<any>(null);
	const [dataClinicList, setDataClinicList] = useState<any>(null);
	const [error, setError] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [value, setValue] = useState<number>();

	useEffect(() => {
		const fetchdata = async () => {
			let user: any = localStorage.getItem('user');
			setIsLoading(true);
			// let homes = {
			// 	data: {
			// 		code: 200,
			// 		message: 'Request Succesfull',
			// 		data: [
			// 			[
			// 				{
			// 					id: 1,
			// 					label: 'Pericas astrales',
			// 					type: 'HN',
			// 				},
			// 				{
			// 					id: 2,
			// 					label: 'Hippies del olimpo',
			// 					type: 'HN',
			// 				},
			// 			],
			// 		],
			// 	},
			// };

			let home: any[] = [];

			if (obj?.entityActionClinic) {
				await api.instanceEntities
					.get(`/nursing_home/list/`, {
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					})
					.then((response: any) => {
						response.data.data[0].map((item: any) => {
							home.push({
								id: item?.id,
								label: item?.comercialname,
								type: 'HN',
								state: item?.state,
								ein: item?.ein,
								npi: item?.npi,
							});
						});
						// console.log('Lista de Nursing Homes', response);
					})
					.catch((err) => {
						setDataHomeList(null);
						//console.log(err.response);
						//console.log(err?.response?.status);
						if (
							[401].includes(err?.response?.status) &&
							dataHomeList === null
						) {
							fetchdata();
						}
						setIsLoading(false);
					});
			}
			// let clinics = {
			// 	data: {
			// 		code: 200,
			// 		message: 'Request Succesfull',
			// 		data: [
			// 			[
			// 				{
			// 					id: 3,
			// 					label: 'Amarille logitech',
			// 					type: 'C',
			// 				},
			// 				{
			// 					id: 4,
			// 					label: 'Los saramanbiche',
			// 					type: 'C',
			// 				},
			// 			],
			// 		],
			// 	},
			// };

			let clinic: any[] = [];
			if (obj?.entityActionHome) {
				await api.instanceEntities
					.get(`/clinic/list/`, {
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					})
					.then((response: any) => {
						response.data.data[0].map((item: any) => {
							clinic.push({
								id: item?.id,
								label: item?.comercialname,
								type: 'C',
								state: item?.state,
								ein: item?.ein,
								npi: item?.npi,
							});
						});
						// console.log('Lista de Clinicas', response);
					})
					.catch((err) => {
						setDataClinicList(null);
						//console.log(err.response);
						//console.log(err?.response?.status);
						if (
							[401].includes(err?.response?.status) &&
							dataClinicList === null
						) {
							fetchdata();
						}
						setIsLoading(false);
					});
			}
			setDataHomeList(dataTransformer(home));
			setDataClinicList(dataTransformer(clinic));

			setIsLoading(false);
		};

		fetchdata();
	}, []);
	return { dataHomeList, dataClinicList, error, isLoading };
};

export default useGetEntity;

/**
 */
