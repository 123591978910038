import React from 'react';
import Vaccines from '../../assets/svg/PatientItems/Vaccines.svg';

const StepFour = ({
	Controller,
	DatePicker,
	control,
	register,
	vaccineFields,
	vaccineRemove,
	vaccineAppend,
	CalendarIcon,
	errors,
	mode,
}: any) => {
	return (
		<React.Fragment>
			<div className="w-11/12 lg:w-full h-full p-2 lg:col-span-2 xl:col-span-3">
				{vaccineFields.map((field: any, index: any) => {
					return (
						<div className="flex flex-col my-3" key={field.id}>
							<div className="w-full mx-auto grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
								<div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
									<img src={Vaccines} className={`w-12`} alt="" />
									<h1 className="text-xl">{`Información de Vacuna`}</h1>
								</div>
								<div className="w-full lg:w-64 h-full relative">
									<input
										type="text"
										id={`vaccines.${index}.vaccineName`}
										className="input-style peer"
										placeholder="Nombre de vacuna*"
										{...register(`vaccines.${index}.vaccineName`, {
											required: {
												value: true,
												message: 'Nombre de la vacuna es requerido',
											},
										})}
									/>
									<label
										htmlFor={`vaccines.${index}.vaccineName`}
										className="input-style-label">
										Nombre de vacuna*
									</label>
									{errors.vaccines?.[index]?.vaccineName && (
										<p className="text-sm text-red-600 mt-2">
											{errors.vaccines?.[index]?.vaccineName?.message}
										</p>
									)}
								</div>
								<div className="w-full lg:w-64 h-full relative">
									<input
										type="text"
										id={`vaccines.${index}.dosage`}
										className="input-style peer"
										placeholder="Dosis"
										{...register(`vaccines.${index}.dosage`)}
									/>
									<label
										htmlFor={`vaccines.${index}.dosage`}
										className="input-style-label">
										Dosis
									</label>
								</div>

								<div>
									<Controller
										control={control}
										name={`vaccines.${index}.doseDate`}
										rules={{
											required: {
												value: false,
												message: 'La fecha de nacimiento es requerida',
											},
										}}
										render={({
											field: { onChange, onBlur, value, ref },
										}: any) => (
											<label
												className="w-full lg:w-64 h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
												htmlFor={`vaccines.${index}.doseDate`}>
												<DatePicker
													id={`vaccines.${index}.doseDate`}
													name={`vaccines.${index}.doseDate`}
													dateFormat="MM/dd/yyyy"
													placeholderText="Fecha de dosis"
													onBlur={onBlur}
													className="w-full focus:outline-none bg-transparent"
													selected={value}
													onChange={onChange}
													showYearDropdown
													yearDropdownItemNumber={120}
													scrollableYearDropdown
												/>
												<CalendarIcon className="h-7" />
											</label>
										)}
									/>
								</div>

								<div className="w-full lg:w-64 h-full relative">
									<input
										type="text"
										id={`vaccines.${index}.frequency`}
										className="input-style peer"
										placeholder="Frecuencia de aplicacion"
										{...register(`vaccines.${index}.frequency`)}
									/>
									<label
										htmlFor={`vaccines.${index}.frequency`}
										className="input-style-label">
										Frecuencia de aplicacion
									</label>
								</div>

								<div className="w-full lg:w-64 h-full relative">
									<input
										type="text"
										id={`vaccines.${index}.pharmaceuticalCompany`}
										className="input-style peer"
										placeholder="Farmaceutica de la Vacuna"
										{...register(`vaccines.${index}.pharmaceuticalCompany`)}
									/>
									<label
										htmlFor={`vaccines.${index}.pharmaceuticalCompany`}
										className="input-style-label">
										Farmaceutica de la Vacuna
									</label>
								</div>

								<input
									type="button"
									className={`${
										mode === 'update' ? 'hidden' : ''
									}  w-full lg:w-64 h-10 bg-red-500 rounded-lg text-white`}
									onClick={() => vaccineRemove(index)}
									value="Eliminar"
								/>
							</div>
						</div>
					);
				})}
				<div className="w-full text-center">
					<input
						className={`${
							mode === 'update' ? 'hidden' : ''
						} mx-auto w-11/12 lg:w-64 rounded-lg px-4 py-2 bg-lime-600 shadow-md duration-300 font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700`}
						type="button"
						value={`Adicionar vacuna`}
						onClick={() =>
							vaccineAppend({
								vaccineName: '',
								dosage: '',
								doseDate: '',
								frequency: '',
								pharmaceuticalCompany: '',
							})
						}
					/>
				</div>
			</div>

			{/*  */}
		</React.Fragment>
	);
};

export default StepFour;
