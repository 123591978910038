/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { FilterDataACS } from '../utils/listACS/FilterDataACS';
import api from '../services/Api';

const useGetUserEntity = (idEntity: any) => {
	const [listData, setListData] = useState<any>(null);
	const [error, setError] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const selected = idEntity?.home !== undefined ? idEntity : idEntity;
	useEffect(() => {
		const fetchdata = async () => {
			let user: any = localStorage.getItem('user');
			setIsLoading(false);

			await api.instanceUsers
				.post(`/user/get-user-entity/`, selected, {
					headers: {
						Authorization: `${JSON.parse(user).TokenType} ${
							JSON.parse(user).IdToken
						}`,
						'Content-Type': 'application/json',
						USER_ENTITY_ID: `${localStorage.getItem('role')}`,
					},
					withCredentials: true,
				})
				.then((response) => {
					setListData(response.data.data[0]);
					setIsLoading(true);
				})
				.catch((err) => {
					setListData(null);
					//console.log(err.response);
					//console.log(err?.response?.status);
					if ([401].includes(err?.response?.status) && listData === null) {
						fetchdata();
						setIsLoading(true);
					}
				});

			setIsLoading(true);
		};

		fetchdata();
	}, []);
	return { listData, error, isLoading };
};

export default useGetUserEntity;
