/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	useCallback,
	useMemo,
	useState,
	forwardRef,
	useEffect,
} from 'react';
import {
	Calendar,
	momentLocalizer,
	Views,
	//DateLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';
import { connect } from 'react-redux';
import { customStyles } from '../customStyleNewLabel';
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer';
import Select from 'react-select';
import useGetUserTypeI from '../../hooks/useGetUserTypeI';
import logicalRolSidebar from '../../utils/MenuAccess/logicalRolSidebar';
import { rolesAccess } from '../../utils/Interfaces/accessRoles';
import options from '../../data/selectOptions';
import AppointmentForm from './Appointment/AppointmentForm';
import CreateAppointment from './Appointment/CreateAppointment';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar: any = withDragAndDrop(Calendar as any);

const formatName = (name: any, count: any) => `${name} ID ${count}`;

const ReactBigCalendar = ({ ...props }: any) => {
	const [myEvents, setMyEvents] = useState<any>([]);
	const [draggedEvent, setDraggedEvent] = useState<any>();
	const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
	const [counters, setCounters] = useState<any>({ item1: 0, item2: 0 });
	const [showViewCalendar, setShowViewCalendar] = useState(true);
	const [access, setAccess] = useState<any>(false);
	const [user, setUser] = useState<any>(null);

	// console.log(props);

	// const access: rolesAccess = logicalRolSidebar(user);

	//cuando la variable user sea null, busque en el estado el usuario actual y asignelo a user
	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				setUser(props?.state?.currentUser);
			}, 500);
		}
	});

	//cuando user no sea null, asigne los roles al estado local
	useEffect(() => {
		if (user !== null) {
			setTimeout(() => {
				setAccess(logicalRolSidebar(user));
			}, 500);
		}
	});

	const {
		dataPatients: dataUser,
		dataPhysician: dataPhysicianList,
		error: errorUser,
		isLoading: isLoadingUser,
	}: any = useGetUserTypeI({ types: [{ id: 0 }, { id: 1 }] });

	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors },
	} = useForm({});

	const clickedShowData = (event: any) => {};

	const handleDragStart: any = useCallback(
		(event: any) => setDraggedEvent(event),
		[],
	);

	const handleDisplayDragItemInCell = useCallback(
		() => setDisplayDragItemInCell((prev) => !prev),
		[],
	);

	const eventPropGetter = useCallback(
		(event: any) => ({
			...(event.isDraggable
				? { className: 'isDraggable' }
				: { className: 'nonDraggable' }),
		}),
		[],
	);

	const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

	const customOnDragOver = useCallback(
		(dragEvent: any) => {
			// check for undroppable is specific to this example
			// and not part of API. This just demonstrates that
			// onDragOver can optionally be passed to conditionally
			// allow draggable items to be dropped on cal, based on
			// whether event.preventDefault is called
			if (draggedEvent !== 'undroppable') {
				// console.log('preventDefault');
				dragEvent.preventDefault();
			}
		},
		[draggedEvent],
	);

	//Movimiento de los eventos entre columnas
	const moveEvent = useCallback(
		({ event, start, end, isAllDay: droppedOnAllDaySlot = false }: any) => {
			const { allDay } = event;
			if (!allDay && droppedOnAllDaySlot) {
				event.allDay = true;
			}
			setMyEvents((prev: any) => {
				const existing = prev.find((ev: any) => ev === event);
				const filtered = prev.filter((ev: any) => ev !== event);
				return [...filtered, { ...existing, start, end, allDay }];
			});
		},
		[setMyEvents],
	);

	//Al hacer click va a crear un nuevo evento y si ya hay existentes los va a añadir tambien
	const newEvent = useCallback(
		({ start, end }: any) => {
			setShowViewCalendar(!showViewCalendar);
			// const title = window.prompt('New Event name');
			// if (title) {
			//setear evento nuevo conservando los ya existentes
			setMyEvents((prev: any) => {
				return [
					...prev,
					{
						start,
						end,
						title: 'dtyjdtyjdt',
						details: { info: 'Paticorto' },
					},
				];
			});
			// }
		},
		[setMyEvents],
		// (event: any) => {
		//   setMyEvents((prev: any) => {
		//     const idList = prev.map((item: any) => item.id);
		//     const newId = Math.max(...idList) + 1;
		//     return [...prev, { ...event, id: newId }];
		//   });
		// },
		// [setMyEvents]
	);

	// se usa cuando arrastro un elemento en el calendario y lo suelto en otra caja
	const onDropFromOutside = useCallback(
		({ start, end, allDay: isAllDay }: any) => {
			if (draggedEvent === 'undroppable') {
				setDraggedEvent(null);
				return;
			}

			const { name }: any = draggedEvent;
			const event = {
				title: formatName(name, counters[name]),
				start,
				end,
				isAllDay,
			};
			setDraggedEvent(null);
			setCounters((prev: any) => {
				const { [name]: count }: any = prev;
				return {
					...prev,
					[name]: count + 1,
				};
			});
			newEvent(event);
		},
		[draggedEvent, counters, setDraggedEvent, setCounters, newEvent],
	);

	//Permite la redimension del tiempo de un evento
	const resizeEvent = useCallback(
		({ event, start, end }: any) => {
			setMyEvents((prev: any) => {
				const existing = prev.find((ev: any) => ev === event) ?? {};
				const filtered = prev.filter((ev: any) => ev !== event);
				return [...filtered, { ...existing, start, end }];
			});
		},
		[setMyEvents],
	);

	const handleSelectEvent = useCallback((event: any) => {
		clickedShowData(event);
		// return window.alert(JSON.stringify(event, null, 4));
	}, []);

	const defaultDate = useMemo(() => new Date(), []); //Fecha predeterminada a mostrar

	const onSubmit = (data: any) => {
		setShowViewCalendar(!showViewCalendar);
	};

	const onSubmitDoctor = (data: any) => {
		console.log('Eventico: ', data);
	};

	const customDayPropGetter = (date: any) => {
		if (date.getDate() === 7 || date.getDate() === 15)
			return {
				className: 'specialDay',
				style: {
					border: 'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa'),
				},
			};
		else return {};
	};

	const customSlotPropGetter = (date: any) => {
		// // console.log('customSlotPropGetter ', date);
		// // if (date.getDate() === 7 || date.getDate() === 15)
		// // 	return {
		// // 		className: 'specialDay',
		// // 	};
		// // else return {};
		// const currentSlot: Date = new Date(date);
		// let restriccion: {
		// 	start: Date;
		// 	end: Date;
		// }[] = [
		// 	{
		// 		start: new Date('2022-08-21T08:30:00'),
		// 		end: new Date('2022-08-27T17:00:00'),
		// 	},
		// ];
		// for (let index = 0; index <= restriccion.length; index++) {
		// 	if (
		// 		restriccion[index].start.getHours() <= currentSlot.getHours() &&
		// 		restriccion[index].end.getHours() - 1 >= currentSlot.getHours() &&
		// 		restriccion[index].start.getDay() <= currentSlot.getDay()
		// 	) {
		// 		return {
		// 			className: 'h-5 bg-green-900/30',
		// 		};
		// 	} else {
		// 		return { className: 'h-5 bg-black/5' };
		// 	}
		// }
	};

	const timeSlotWrapper = (props: any) => {
		// console.log(props);

		// const currentSlot: Date = new Date(props.value);

		// let restriccion: {
		// 	start: Date;
		// 	end: Date;
		// }[] = [
		// 	{
		// 		start: new Date('2022-08-22T08:30:00'),
		// 		end: new Date('2022-08-22T17:00:00'),
		// 	},
		// 	{
		// 		start: new Date('2022-08-23T10:30:00'),
		// 		end: new Date('2022-08-23T17:00:00'),
		// 	},
		// ];

		// console.log('Longitud: ', restriccion.length);

		// for (let index = 0; index < restriccion.length; index++) {
		// 	console.log('Pase:', index);

		// 	if (
		// 		restriccion[index].start.getTime() <= currentSlot.getTime() &&
		// 		restriccion[index].end.getTime() - 30 >= currentSlot.getTime() &&
		// 		restriccion[index].start.getDay() === currentSlot.getDay()
		// 	) {
		// 		return (
		// 			<div className="h-5 bg-green-900/30">
		// 				{/* <div className="" style={{ backgroundColor: "#000" }}> */}
		// 				{props.children}
		// 			</div>
		// 		);
		// 	} else {
		// 		return (
		// 			<div className="h-5 bg-black/5">
		// 				{/* <div className="" style={{ backgroundColor: "#000" }}> */}
		// 				{props.children}
		// 			</div>
		// 		);
		// 	}
		// }

		return (
			<div className="h-5">
				{/* <div className="" style={{ backgroundColor: "#000" }}> */}
				{props.children}
			</div>
		);
		//debe cumplirse la condicion tanto para el dia como la hora para poder marcar el espacio a utilizar, funciona como el plano cartesiano
		// if (
		// 	dias.includes(props.value.getDate()) &&
		// 	perica.includes(props.value.getHours())
		// ) {
		// 	return (
		// 		<div className="h-5 bg-slate-500">
		// 			{/* <div className="" style={{ backgroundColor: "#000" }}> */}
		// 			{props.children}
		// 		</div>
		// 	);
		// 	// ){
		// 	//   className: "specialDay",
		// 	// };
		// } else
		// 	return (
		// 		<div className="h-5">
		// 			{/* <div className="" style={{ backgroundColor: "#000" }}> */}
		// 			{props.children}
		// 		</div>
		// 	);

		// return (
		//   <div className="h-5">
		//     {/* <div className="" style={{ backgroundColor: "#000" }}> */}
		//     {props.children}
		//   </div>
		// );
	};

	const showCalendar = (onChange: any) => {
		setAccess(true);
		return onChange;
	};

	return (
		<div className="w-full h-screen">
			{access?.calendarOption && (
				<div className="w-full p-4 flex flex-col gap-3">
					<p className="font-bold">
						Seleccione un medico para visualizar su calendario con las citas y
						reservas correspondientes
					</p>
					<form
						className="w-full flex flex-row gap-3"
						onSubmit={handleSubmit(onSubmitDoctor)}>
						<div className="w-full h-full">
							<Controller
								control={control}
								name="calendarDoctor"
								rules={{
									required: {
										value: true,
										message:
											'Seleccione un doctor para poder visualizar el calendario',
									},
								}}
								render={({ field: { onChange, onBlur, value, ref } }: any) => (
									<Select
										name="nursingHomeId"
										id="nursingHomeId"
										ref={ref}
										isClearable
										isDisabled={isLoadingUser ? true : false}
										className="w-full"
										placeholder="Nursing Home...*"
										styles={customStyles}
										onBlur={onBlur}
										onChange={onChange}
										components={{
											ValueContainer: CustomValueContainer,
										}}
										// options={[{ value: 1, label: "Nursing Home..." }]}
										options={dataPhysicianList}
										value={value}
									/>
								)}
							/>
							{errors.calendarDoctor && (
								<p className="text-sm text-red-600 mt-2">
									{errors.calendarDoctor.message}
								</p>
							)}
						</div>
						<input
							type="submit"
							value="Mostrar Calendario"
							className="px-4 rounded-lg border-[1px] border-[#044382] bg-[#044382] font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-sky-700 hover:bg-sky-500"
						/>
					</form>
				</div>
			)}
			{access?.calendarView && (
				<>
					{showViewCalendar && (
						<div className="w-full h-full p-4 ">
							<DragAndDropCalendar
								defaultDate={defaultDate}
								defaultView={Views.WEEK}
								dragFromOutsideItem={
									displayDragItemInCell ? dragFromOutsideItem : undefined
								}
								draggableAccessor={() => {
									return true;
								}}
								eventPropGetter={eventPropGetter}
								localizer={localizer} //Localizador para obtener el calendario
								events={myEvents} //eventos que vienen de algun lado
								onDropFromOutside={onDropFromOutside} //Soltar el evento en otro espacio
								onDragOver={customOnDragOver} //funcion de arrastrar
								onEventDrop={moveEvent} //cuando se suenta un evento a otro cuadro
								onEventResize={resizeEvent} //cambiar el rango de un evento, ya sea con fechas u horas
								onSelectSlot={newEvent} //Permite la creacion de un nuevo evento cuando se arrastra y se suelta en cajas y cuando hacemos clic en las cajas
								onSelectEvent={handleSelectEvent}
								resizable //Permitir redimensionar por medio de la accion del usuario
								//selectable //Poder hacer clic en los eventos
								// dayPropGetter={customDayPropGetter}
								slotPropGetter={customSlotPropGetter} //Asigna color a los dias
								step={15}
								timeslots={4}
								components={{
									timeSlotWrapper,
								}}
							/>
						</div>
					)}
					{!showViewCalendar && (
						<div>
							{JSON.stringify(myEvents, null, 4)}
							<CreateAppointment />
							<div
								className="px-4 py-2 border bg-black font-bold text-white"
								onClick={onSubmit}>
								Volver
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		state: {
			currentUser: state.currentUser,
		},
	};
};

const mapDispatchToProps = {};

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(ReactBigCalendar);
