/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { customStyles } from '../../customStyleNewLabel';
import options from '../../../data/selectOptions';
import ConditionForm from './Condition';
import AllergyForm from './Allergy';
import SurgeryForm from './Surgery';

const StepSeven = ({
	Controller,
	control,
	Select,
	register,
	conditionFields,
	conditionRemove,
	conditionAppend,
	allergyFields,
	allergyAppend,
	allergyRemove,
	surgeryFields,
	surgeryAppend,
	surgeryRemove,
	acsListData,
	acsError,
	acsIsLoading,
	CustomValueContainer,
}: any) => {
	return (
		<React.Fragment>
			<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7 border-b-8">
				<ConditionForm
					Controller={Controller}
					control={control}
					Select={Select}
					register={register}
					conditionFields={conditionFields}
					conditionRemove={conditionRemove}
					conditionAppend={conditionAppend}
					acsError={acsError}
					acsIsLoading={acsIsLoading}
					customStyles={customStyles}
					acsListData={acsListData}
					CustomValueContainer={CustomValueContainer}
				/>
			</div>
			{/* Alergias */}
			<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7 border-b-8">
				<AllergyForm
					Controller={Controller}
					control={control}
					Select={Select}
					register={register}
					allergyFields={allergyFields}
					allergyAppend={allergyAppend}
					allergyRemove={allergyRemove}
					acsListData={acsListData}
					acsError={acsError}
					acsIsLoading={acsIsLoading}
					customStyles={customStyles}
					CustomValueContainer={CustomValueContainer}
				/>
			</div>
			{/** Cirugías */}
			<div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7 border-b-8">
				<SurgeryForm
					Controller={Controller}
					control={control}
					Select={Select}
					register={register}
					surgeryFields={surgeryFields}
					surgeryAppend={surgeryAppend}
					surgeryRemove={surgeryRemove}
					acsError={acsError}
					acsIsLoading={acsIsLoading}
					customStyles={customStyles}
					acsListData={acsListData}
					CustomValueContainer={CustomValueContainer}
				/>
			</div>
		</React.Fragment>
	);
};

export default StepSeven;
