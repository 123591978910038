export const emailValidation: RegExp =
	/^[A-Z0-9._%+-]+@[A-Z0-9._.-]+\.[A-Z]{2,5}$/i;

export const clearSpace: RegExp = /( +?|\t)(?=(([^"]*"){2})*[^"]*$)/g;

//export const onlyNumbers = /[^a-z ] *([.0-9])*\d/g;
export const onlyNumbers: RegExp = /(^[\d]+$){1}/g;

export const npiValidation: RegExp = /^[0-9]{10}$/g;

export const websiteValidation: RegExp =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
