/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer';
import { emailValidation, websiteValidation } from '../../utils/regexExpresion';
import registerHomeService from '../../services/RegisterForms';
import { customStyles } from '../customStyleNewLabel';
import { Controller, useForm } from 'react-hook-form';
import Entities from '../../assets/svg/entidades.svg';
import Select, { components } from 'react-select';
import options from '../../data/selectOptions';
import NumberFormat from 'react-number-format';
import React, { useState } from 'react';
import EntityForm from './FormEntity';
import Switch from 'react-switch';
import Swal from 'sweetalert2';
import SumaryEntityRegisterData from '../Modals/SumaryEntityRegisterData';

const RegisterEntity = ({ ...props }: any) => {
	const [isRequired, setIsRequired] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [formData, setFormdata] = useState<any>(null);
	const [choice, setChoice] = useState<any>(false);
	const [modalOn, setModalOn] = useState<any>(false);

	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty },
		control,
	} = useForm({
		mode: 'onChange',
	});

	const onSubmit = async () => {
		setIsLoading(false);
		const response = await registerHomeService.registerEntity(formData);

		if (response?.code >= 200 && response?.code <= 299) {
			reset({
				legalName: null,
				comercialName: null,
				webPage: null,
				email: null,
				firstLline: null,
				secondLline: null,
				entityType: null,
				ein: '',
				npi: '',
				phoneTwo: '',
				phone: '',
				phoneExt: '',
				state: null,
				zipCode: '',
				country: null,
				pobox: '',
				poboxState: null,
				poboxZipCode: '',
				poboxCountry: null,
			});
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Confirmación',
				text: 'Entidad registrada con éxito',
				icon: 'success',
				// icon: "error",
				cancelButtonText: 'Volver al formulario',
				confirmButtonText: 'Ver perfil',
				showCancelButton: true,
				confirmButtonColor: 'rgb(14 165 233)',
				cancelButtonColor: 'rgb(14 165 233)',
			}).then((result) => {
				setIsLoading(true);
				if (result.isConfirmed) {
					console.log(response.data[0].id);
				} else {
					setIsLoading(true);
					reset({});
				}
			});
			setIsLoading(true);
		} else {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Se ha presentado un error interno',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
		}
		console.log(response);
	};

	const beforeRegisterEntity = (data: any) => {
		setModalOn(true);
		setFormdata(data);
		setIsLoading(true);
	};

	return (
		<>
			{modalOn && (
				<SumaryEntityRegisterData
					formData={formData}
					setChoice={setChoice}
					setModalOn={setModalOn}
					onSubmit={onSubmit}
					setIsLoading={setIsLoading}
				/>
			)}
			<div className="w-full space-y-7 ">
				<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
					<img src={Entities} className={`w-20`} alt="" />

					<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
						Registro de Entidades
					</h2>
				</div>
				<form onSubmit={handleSubmit(beforeRegisterEntity)} onReset={reset}>
					<section className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col py-4">
						<EntityForm
							Controller={Controller}
							Select={Select}
							options={options}
							customStyles={customStyles}
							NumberFormat={NumberFormat}
							emailValidation={emailValidation}
							websiteValidation={websiteValidation}
							Switch={Switch}
							register={register}
							errors={errors}
							isValid={isValid}
							isDirty={isDirty}
							control={control}
							isRequired={isRequired}
							setIsRequired={setIsRequired}
							CustomValueContainer={CustomValueContainer}
							mode={'register'}
							watch={watch}
						/>
						<div className="lg:col-span-2 xl:col-span-3 my-5">
							{isLoading ? (
								<div id="next" className="has-tooltip w-fit h-fit">
									{!isValid ? (
										<span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
											Para continuar, debe completar los campos requeridos
											señalados con *
										</span>
									) : null}
									<input
										type="submit"
										// disabled={!isValid}
										value="Registrar Entidad"
										className="mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
									/>
								</div>
							) : (
								<input
									type="submit"
									disabled
									value="Registrando..."
									className="mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
								/>
							)}
						</div>
					</section>
				</form>
			</div>
		</>
	);
};

export default RegisterEntity;

//labels en la parte superior colores de labels y inputs
