import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/outline';
const Home = () => {
	return (
		<div className="w-full min-h-screen flex flex-col ">
			<div className="w-full shadow-lg shadow-slate-20 my-1 py-3 md:py-7 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row gap-4">
				<HomeIcon className={`w-11 text-[#294fa9]`} />
				<h1 className=" font-bold text-[#294fa9]  md:text-3xl py-2">
					Menú Principal
				</h1>
			</div>
			<div className="center w-full py-7 text-center">
				<h1 className="text-3xl font-extrabold text-[#1E93C6] md:text-4xl">
					Bienvenidos
				</h1>
				<p className="md:text-xl text-[#8f8f8f]">
					Selecciona la opción que desea utilizar
				</p>
			</div>

			<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-x-5 gap-y-5 w-fit mx-auto">
				<Link to="/patients">
					<div className="home-button">
						<div className="home-button-icon">
							<div className="w-16 h-16 bg-ResidenteSearchSVG brightness-[1]" />
						</div>
						<p className="text-lg font-semibold text-[#0573bb]">
							Buscar residentes
						</p>
					</div>
				</Link>

				<Link to="/registerpatient">
					<div className="home-button">
						<div className="home-button-icon">
							<div className="w-16 h-16 bg-ResidenteSVG brightness-[1]" />
						</div>
						<p className="text-lg font-semibold text-[#96c952]">
							Registrar Residentes
						</p>
					</div>
				</Link>

				<Link to="/appointments/create">
					<div className="home-button">
						<div className="home-button-icon">
							<div className="w-16 h-16 bg-AddAppointmentSVG brightness-[1]" />
						</div>
						<p className="text-lg font-semibold text-[#0573bb]">Crear cita</p>
					</div>
				</Link>

				<Link to="/appointments">
					<div className="home-button">
						<div className="home-button-icon">
							<div className="w-16 h-16 bg-AppointmentsSVG brightness-[1]" />
						</div>
						<p className="text-lg font-semibold text-[#96c952]">
							Listado de citas
						</p>
					</div>
				</Link>
				<Link to="/calendar">
					<div className="home-button">
						<div className="home-button-icon">
							<div className="w-16 h-16 bg-CalendarioSVG brightness-[1]" />
						</div>
						<p className="text-lg font-semibold text-[#0573bb]">Calendario</p>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Home;
