/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import React, { Fragment, useRef, useState } from 'react';
import registerService from '../services/RegisterForms';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isRecovery, setIsRecovery] = useState(false);
	const [hash, setHash] = useState('');

	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isDirty },
		control,
		reset,
		watch,
	} = useForm({ mode: 'onChange' });

	const navigate = useNavigate();

	const onSubmitRecovery = async (data: any) => {
		setIsLoading(false);

		try {
		} catch (error) {
			alert('Intente nuevamente');
			setIsLoading(true);
		}

		setIsLoading(true);
	};

	const onSubmit = async (data: any) => {
		setIsLoading(false);
		try {
			if (!isRecovery) {
				const result: any = await registerService.forgotPassword(data);

				if (result.status === 200 && isRecovery === false) {
					setHash(result.data.data[0].hash);
					setIsRecovery(true);
				}
			} else {
				data.hash = hash;
				let response: any = await registerService.recoveryPassword(data);
				if (response.data.code === 200) {
					Swal.fire({
						customClass: {
							confirmButton: 'bg-red-500 text-white',
						},
						icon: 'success',
						title: 'Cambio de contraseña éxitoso',
						text: 'Ahora podra iniciar sesion con su nueva contraseña',
						// icon: "error",
						confirmButtonText: 'Aceptar',
					});
					reset({});
					setIsRecovery(false);
				}
			}
			setIsLoading(true);
		} catch (error) {
			alert('Ocurrio un error reintente en unos minutos');
		}
		setIsLoading(true);
	};
	return (
		<div className="w-full p-7">
			<form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
				<section className="w-full space-y-7">
					<p className="text-center mb-5 md:w-[40rem] mx-auto">
						{isRecovery ? (
							<Fragment>
								Dirijase a la bandeja de su correo electronico, le hemos enviado
								un codigo de Confirmación para restablecer su contraseña
							</Fragment>
						) : (
							<Fragment>
								Ingrese su correo electrónico para recuperar su contraseña,
								tambien revise su bandeja de entrada o en la carpeta de spam
							</Fragment>
						)}
					</p>
					<div className="sm:w-72 h-full mx-auto space-y-3">
						{isLoading ? (
							<Fragment>
								<label
									htmlFor="email"
									className="w-full h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]">
									<input
										className="w-full h-10 focus:outline-none bg-transparent"
										placeholder="Correo electrónico"
										{...register('email')}
										disabled={isRecovery}
									/>
								</label>
							</Fragment>
						) : null}

						{isRecovery ? (
							<Fragment>
								<label
									htmlFor="new_password"
									className="w-full h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]">
									<input
										className="w-full h-10 focus:outline-none bg-transparent"
										placeholder="Contraseña nueva"
										{...register('new_password')}
									/>
								</label>

								<label
									htmlFor="confirmation_code"
									className="w-full h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]">
									<input
										className="w-full h-10 focus:outline-none bg-transparent"
										placeholder="Codigo de confirmación"
										{...register('confirmation_code')}
									/>
								</label>
							</Fragment>
						) : (
							<Fragment></Fragment>
						)}

						{isLoading ? (
							<div className="space-y-4 flex flex-col  items-center">
								<input
									type={`submit`}
									value={isRecovery ? `Enviar` : `Recuperar`}
									className="w-full rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1px] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 "
								/>
								<input
									type="button"
									value={'Volver'}
									className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
									onClick={() => {
										navigate(`/login`);
									}}
								/>
							</div>
						) : (
							<input
								type={`button`}
								value={`Enviando...`}
								className="w-full mx-auto rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1px] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 "
							/>
						)}
					</div>
				</section>
			</form>
		</div>
	);
};

export default ForgotPassword;
