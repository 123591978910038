/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { dataTransformer } from '../utils/DataTransformer/filterPatients/dataTransformer';
import api from '../services/Api';

const useGetUserTypeI = (userType: any) => {
	const [dataPatients, setDataPatients] = useState<any>(null);
	const [dataPhysician, setDataPhysician] = useState<any>(null);
	const [error, setError] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchdata = async () => {
			let user: any = localStorage.getItem('user');

			setIsLoading(true);

			if (userType.types[1].id !== 0) {
				await api.instanceUsers
					.post(`/user/get-user-type/`, userType.types[1], {
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
							USER_ENTITY_ID: `${localStorage.getItem('role')}`,
						},
						withCredentials: true,
					})
					.then((response: any) => {
						setDataPhysician(dataTransformer(response.data.data[0]));
					})
					.catch((err) => {
						// console.log(err.response);
						// console.log(err?.response?.status);
						if (
							[401].includes(err?.response?.status) &&
							dataPhysician === null
						) {
							fetchdata();
						}
					});
			} else {
				setDataPhysician({});
			}

			if (userType.types[0].id !== 0) {
				await api.instanceUsers
					.post(`/user/get-user-type/`, userType.types[0], {
						headers: {
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
							USER_ENTITY_ID: `${localStorage.getItem('role')}`,
						},
						withCredentials: true,
					})
					.then((response: any) => {
						setDataPatients(dataTransformer(response.data.data[0]));
					})
					.catch((err) => {
						// console.log(err.response);
						// console.log(err?.response?.status);
						if (
							[401].includes(err?.response?.status) &&
							dataPatients === null
						) {
							fetchdata();
						}
					});
			} else {
				setDataPatients({});
			}

			setIsLoading(false);
		};
		fetchdata();
	}, []);
	return { dataPatients, dataPhysician, error, isLoading };
};

export default useGetUserTypeI;
