/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import api from '../services/Api';

const useGetUserProfile = (userProfileId: any) => {
	const [userProfile, setUserProfile] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState<any>();

	useEffect(() => {
		const fetchdata = async () => {
			setIsLoading(true);
			let user: any = localStorage.getItem('user');

			await api.instanceUsers
				.post(
					'/user/get-profile-info',
					{
						id: userProfileId,
					},
					{
						headers: {
							USER_ENTITY_ID: `${localStorage.getItem('role')}`,
							Authorization: `${JSON.parse(user).TokenType} ${
								JSON.parse(user).IdToken
							}`,
							'Content-Type': 'application/json',
						},
					},
				)
				.then((response) => {
					// console.log('Perfil del usuario', response.data.data[0]);
					setUserProfile(response.data.data[0]);
				})
				.catch((err) => {
					//console.log(err.response);
					//console.log(err?.response?.status);
					if ([401].includes(err?.response?.status)) {
						fetchdata();
					}
				});
			setIsLoading(false);
		};

		fetchdata();
	}, []);

	// console.log(data);
	return { userProfile, isError, isLoading };
};

export default useGetUserProfile;
