/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import options from '../../data/selectOptions';
import NumberFormat from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonalInfo from '../RegisterPatient/PersonalInfo';
import { CalendarIcon } from '@heroicons/react/outline';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import { clearUpdatePatientInfoForm } from '../../actions';
import { connect } from 'react-redux';
import { formatDate } from '../../utils/formatDate';
import useGetListNursing from '../../hooks/useGetListNursing';
import { dataTransformer } from '../../utils/DataTransformer/listNursing/dataTransformer';
import UpdatePatientInfo from '../../services/UpdatePatientInfo';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';

const UpdatePatientForm = (props: any) => {
	const [stateData, setStateData] = useState<any>();
	const location: any = useLocation();
	const [isRequired, setIsLoading] = useState(false);
	const [user, initUser] = useState<any>(null);
	const { dataNursing, error, isLoading }: any = useGetListNursing();

	const navigate = useNavigate();

	const {
		register,
		watch,
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
	} = useForm({});

	useEffect(() => {
		if (user === null) {
			setTimeout(() => {
				initUser({
					entryDate: formatDate(props?.updatePatientInfoForm?.entryDate),
					clinicalMedicalNH: props?.updatePatientInfoForm?.clinicalMedicalNH,

					firstname: props?.updatePatientInfoForm?.name,
					middlename: props?.updatePatientInfoForm?.middle,
					surname: props?.updatePatientInfoForm?.surname,
					second_surname: props?.updatePatientInfoForm?.secondSurname,

					birthday: formatDate(props?.updatePatientInfoForm?.birthday),

					isMobilePhone: props?.updatePatientInfoForm?.phones?.isMobile,
					phone: props?.updatePatientInfoForm?.phones?.number,
					extPhone: props?.updatePatientInfoForm?.phones?.extension,

					countryOrigin: options.state
						.filter((item: any) => {
							if (item?.label === props?.updatePatientInfoForm?.countryOrigin) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],

					gender: options.genderOptions
						.filter((item: any) => {
							if (item?.label === props?.updatePatientInfoForm?.gender) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],

					statusSex: options.sexOptions
						.filter((item: any) => {
							if (item?.label === props?.updatePatientInfoForm?.statusSex) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],

					bloodtype: options.bloodOptions
						.filter((item: any) => {
							if (item?.label === props?.updatePatientInfoForm?.bloodtype) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],

					maritalStatus: options.maritalOptions
						.filter((item: any) => {
							if (item?.label === props?.updatePatientInfoForm?.maritalStatus) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						})[0],
					nursingHomeId: dataNursing
						.filter((item: any) => {
							if (item?.value === props?.updatePatientInfoForm?.nursingHomeId) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						}),

					nursingHomeSourceId: dataNursing
						.filter((item: any) => {
							// console.log('filtro ', item);
							// console.log(
							// 	'Pericas filtro ',
							// 	props?.updatePatientInfoForm?.nursingHomeSourceId,
							// );

							if (
								parseInt(item?.value) ===
								parseInt(props?.updatePatientInfoForm?.nursingHomeSourceId)
							) {
								return item;
							}
						})
						.map((item: any) => {
							return item;
						}),

					socialSecurityId: props?.updatePatientInfoForm?.socialSecurityId,
					email: props?.updatePatientInfoForm?.email,
					insuranceCarrier: props?.updatePatientInfoForm?.insuranceCarrier,
					insuranceCarrierII: props?.updatePatientInfoForm?.insuranceCarrierII,
					tytocareId: props?.updatePatientInfoForm?.tytocareId,
					preferenceHospital: props?.updatePatientInfoForm?.preferenceHospital,
				});
			}, 1000);
		}
	});

	useEffect(() => {
		reset(user);
	}, [user]);

	const onSubmit = async (values: any) => {
		//setIsLoading(!isLoading);
		values.id = props?.updatePatientInfoForm?.id;
		//console.log(`valores data: \n ${JSON.stringify(values, null, 4)}`);
		const dataUpdate: any = await UpdatePatientInfo.UpdatePatientInfo(values);
		if (dataUpdate.code === 200) {
			//alert(JSON.stringify(values, null, 4));
			clearUpdatePatientInfoForm(null);
			reset({
				entryDate: '',
				clinicalMedicalNH: '',
				firstname: '',
				middlename: '',
				surname: '',
				second_surname: '',
				birthday: '',
				isMobilePhone: false,
				phone: '',
				extPhone: '',
				socialSecurityId: '',
				email: '',
				insuranceCarrier: '',
				insuranceCarrierII: '',
				tytocareId: '',
				preferenceHospital: '',
				nursingHomeId: {},
				countryOrigin: {},
				gender: {},
				statusSex: {},
				bloodtype: {},
				maritalStatus: {},
			});

			initUser({});
			navigate(`/patientprofile/${props?.updatePatientInfoForm?.id}/`);
		} else {
			navigate(`/patientprofile/${props?.updatePatientInfoForm?.id}/`);
		}
	};

	const { ValueContainer, Placeholder } = components;

	const CustomValueContainer = ({ children, ...props }: any) => {
		return (
			<ValueContainer {...props}>
				<Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
					child && child.type !== Placeholder ? child : null,
				)}
			</ValueContainer>
		);
	};
	return (
		<div className="w-full space-y-4 ">
			<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
				<h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
					Editando información del residente
				</h2>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
				<div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col">
					<PersonalInfo
						Controller={Controller}
						Select={Select}
						DatePicker={DatePicker}
						control={control}
						register={register}
						errors={errors}
						NumberFormat={NumberFormat}
						CalendarIcon={CalendarIcon}
						genderOptions={options.genderOptions}
						sexOptions={options.sexOptions}
						bloodOptions={options.bloodOptions}
						maritalOptions={options.maritalOptions}
						components={components}
						ListNursing={[]}
						CustomValueContainer={CustomValueContainer}
						nursingHomeId={stateData?.nursingHomeId}
						watch={watch}
					/>
					<div className="lg:col-span-2 xl:col-span-3 my-5">
						<input
							type={'button'}
							value="Volver"
							className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
							onClick={() => {
								navigate(
									`/patientprofile/${props?.updatePatientInfoForm?.id}/`,
								);
							}}
						/>
						<input
							type="submit"
							value="Guardar"
							className="mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};
const mapStateToProps = (state: any) => {
	return {
		updatePatientInfoForm: state.updatePatientInfoForm,
	};
};
const mapDispatchToProps = {
	clearUpdatePatientInfoForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePatientForm);
