import { IdentificationIcon, PencilAltIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import Usuario from '../../../assets/svg/usuarioSearch.svg';
import useGetUserProfile from '../../../hooks/useGetUserProfile';
import InformationLaboral from '../../../assets/svg/PatientItems/informacionLaboral.svg';
import { useLocation, useNavigate } from 'react-router-dom';

const ShowProfile = ({ ...prop }: any) => {
	const [dataProfile, setDataProfile] = useState<any>(null);
	const location: any = useLocation();
	const navigate = useNavigate();

	const { userProfile, isError, isLoading } = useGetUserProfile(
		location?.state?.showProfile,
	);

	useEffect(() => {
		if (dataProfile === null) {
			setTimeout(() => {
				setDataProfile(userProfile);
			}, 500);
		}
	}, [userProfile]);

	return (
		<div className="w-full h-full ">
			<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<img
					//src={Usuario}
					src={Usuario}
					className={`w-20 p-1 self-center rounded-full bg-slate-500`}
					alt=""
				/>
				<div className="">
					<h1 className="text-2xl text-[#96c952] font-semibold my-5 justify-start">
						{`${userProfile?.Info?.user_firstname} ${userProfile?.Info?.user_middlename} ${userProfile?.Info?.user_surname} ${userProfile?.Info?.user_second_surname}`}
					</h1>
				</div>
			</div>

			{isLoading ? (
				<div>Cargando...</div>
			) : (
				<div className="p-5">
					<div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
						{/* <img src={Allergy} className={`w-12`} alt="" /> */}
						<IdentificationIcon
							strokeWidth={1.3}
							className="w-12 h-12 text-[#294fa9]"
						/>
						<h1 className="text-sky-500 text-xl font-bold first-line:capitalize">
							{'Informacion Personal'}
						</h1>
					</div>

					<div className="w-full text-left md:px-20 space-y-2 text-lg py-2 p-3	">
						<div className="flex flex-col">
							{/* <p>{userProfile?.Info?.user_id}</p> */}
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Primer nombre: </b>
								<p>{userProfile?.Info?.user_firstname}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Segundo nombre:</b>
								<p>{userProfile?.Info?.user_middlename}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Primer apellido:</b>
								<p>{userProfile?.Info?.user_surname}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Segundo apellido:</b>
								<p>{userProfile?.Info?.user_second_surname}</p>
							</div>
							{/* <div className="flex flex-row gap-1">
							<b>:</b>
							<p>{userProfile?.Info?.UserEntity_clinic_id}</p>
						</div> */}
							{/* <div className="flex flex-row gap-1">
							<b>:</b>
							<p>{userProfile?.Info?.UserEntity_nursing_home_id}</p>
						</div> */}
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">NPI:</b>
								<p>{userProfile?.Info?.Profile_legal_identifier}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Especialidad:</b>
								<p>{userProfile?.Info?.Profile_specialty}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Fecha de nacimiento: </b>
								<p>
									{userProfile?.Info?.Profile_birthday
										? new Date(userProfile?.Info?.Profile_birthday)
												.toISOString()
												.substring(0, 10)
										: null}
								</p>
							</div>
							{/* <div className="flex flex-row gap-1">
							<b>:</b>
							<p>{userProfile?.Info?.status_gender_id}</p>
						</div> */}
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Genero: </b>
								<p>{userProfile?.Info?.gender}</p>
							</div>
						</div>
					</div>

					<div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
						<img src={InformationLaboral} className={`w-12`} alt="" />
						<h1 className="text-sky-500 text-xl font-bold ">
							{'Información de contacto'}
						</h1>
					</div>

					<div className="w-full text-left md:px-20 space-y-2 text-lg py-2  p-3	">
						<div className="flex flex-col">
							<div className="flex flex-row gap-1">
								<b className="text-sky-500 font-bold">Correo electronico:</b>
								<p>{userProfile?.Info?.user_email}</p>
							</div>
						</div>
					</div>

					<div className="items-center gap-2 justify-center lg:col-span-2 xl:col-span-3 my-5 flex flex-row">
						<button
							onClick={() => {
								navigate('/register/user');
							}}
							className="h-12 flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-1">
							Volver
						</button>

						<div className="flex flex-row gap-10">
							<div
								className="h-12 flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer"
								onClick={() => {
									navigate('/profile/update', {
										state: {
											updateUserForm: {
												user_type: null,
												specialty: null,
												firstname: userProfile?.Info?.user_firstname,
												middlename: userProfile?.Info?.user_middlename,
												surname: userProfile?.Info?.user_surname,
												second_surname: userProfile?.Info?.user_second_surname,
												gender: userProfile?.Info?.status_gender_id,
												email: userProfile?.Info?.user_email,
												birthday: userProfile?.Info?.Profile_birthday,
												phoneMobile: null,
												phone: null,
												phoneExt: null,
												isMobilePatient: null,
											},
										},
									});
								}}>
								<PencilAltIcon className="w-8 h-8 " />
								<div>Editar datos</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ShowProfile;
