import { createStore, compose } from 'redux';
import reducer from '../reducer';

const initialState = {
	updateEntityForm: {},
	calendar: {},
	updateProfileUserForm: {},
	currentUser: {},
	currentEntitySelected: {},
	updatePatientPhotoForm: {},
	accessRole: {},
	entitiesId: {},
};

const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, initialState, composeEnhancers());
// console.log('Estore: ', store);

export default store;
