/* eslint-disable @typescript-eslint/no-unused-vars */
const getLocalRefreshToken = () => {
	const user = JSON.parse(`${localStorage.getItem('user')}`);
	// console.log('Ha tomado el refreshtoken');
	return user?.RefreshToken;
};

const getUserIdAction = () => {
	const userId = JSON.parse(`${localStorage.getItem('userId')}`);
	return userId;
};

const getLocalAccessToken = () => {
	const user = JSON.parse(`${localStorage.getItem('user')}`);
	return user?.accessToken;
};

const updateLocalAccessToken = (IdToken: any, AccessToken: any) => {
	// console.log('UpdateLocalAccessToken In progress');

	let user: any = JSON.parse(`${localStorage.getItem('user')}`);

	// console.log(
	// 	'Token antes de actualizar: ',
	// 	JSON.parse(`${localStorage.getItem('user')}`).IdToken,
	// );

	console.log('Tokens iguales: ', user.IdToken === IdToken);

	user.IdToken = IdToken;
	user.AccessToken = AccessToken;
	localStorage.removeItem('user');
	localStorage.setItem('user', JSON.stringify(user));
	// console.log(
	// 	'Token Despues de actualizar: ',
	// 	JSON.parse(`${localStorage.getItem('user')}`).IdToken,
	// );
};

const getUser = () => {
	return JSON.parse(`${localStorage.getItem('user')}`);
};

const setUser = (user: any) => {
	// console.log('Almacenando Token: ', user);
	localStorage.setItem('user', JSON.stringify(user));
};

const getRole = () => {
	return localStorage.getItem('role');
};

const removeUser = () => {
	localStorage.removeItem('user');
};

const TokenService = {
	updateLocalAccessToken,
	getLocalRefreshToken,
	getLocalAccessToken,
	getUserIdAction,
	removeUser,
	getUser,
	setUser,
	getRole,
};

export default TokenService;
