import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setEntitySelectedByUser } from '../../actions';
import { clinicProfile, homeProfile } from '../../services/ProfileEntity';

const ModalSwitchEntity = ({
	setModalEntityOn,
	modalEntityOn,
	entitySelected,
	listEntity,
	...props
}: any) => {
	const [selected, setSelected] = useState<unknown>();

	console.log(listEntity);

	const handleOKClick = () => {
		setModalEntityOn(!modalEntityOn);
	};
	const onsubmit = async (item: any) => {
		setSelected(item?.id);
		entitySelected(`${item?.comercialname}`);

		const result =
			item?.type === 'Clinica'
				? await clinicProfile(parseInt(item?.id))
				: await homeProfile(parseInt(item?.id));
		console.log(result);

		// props?.setEntitySelectedByUser(result);
	};

	// useEffect(() => {

	// })

	return (
		<div className="bg-zinc-200 opacity-0 fixed inset-0 z-50">
			<div className="flex h-screen justify-center items-center ">
				<div className="flex flex-col h-screen lg:h-fit justify-center bg-white p-6 md:py-12 md:px-24 rounded-md">
					<h1 className="text-xl font-bold">Seleccion de entidades</h1>
					<p className="flex text-lg text-zinc-600">
						Se ha detectado que usted pertenece a mas de una entidad, por favor
						seleccione la entidad que desea gestionar
					</p>
					<div className="overflow-scroll grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 justify-center py-9">
						{listEntity?.clinics?.map((item: any) => {
							return (
								<button
									key={item?.id}
									onClick={() => {
										onsubmit(item);
									}}
									className={`flex flex-col text-center items-center cursor-pointer ${
										selected === item?.id ? 'border-2 border-cyan-300 ' : ''
									}`}>
									<OfficeBuildingIcon className={`w-20 h-20 `} />
									<p>{`${item?.type} ${item?.comercialname}`}</p>
								</button>
							);
						})}
					</div>
					<div className="flex">
						<button
							onClick={handleOKClick}
							className=" rounded px-4 py-2 text-white bg-green-400 ">
							Salir
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		state: {
			entitiesId: state.entitiesId,
			currentEntitySelected: state.currentEntitySelected,
		},
	};
};

const mapDispatchToProps = {
	setEntitySelectedByUser,
};

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(ModalSwitchEntity);
