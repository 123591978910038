/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm, Controller } from 'react-hook-form';
import Entities from '../../assets/svg/entidades.svg';
import { customStyles } from '../customStyleNewLabelCircle';
import { setUpdateEntityForm } from '../../actions';
import useGetEntity from '../../hooks/useGetEntity';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import List from '../../services/ListEntity';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer';

const CustomOption = ({ children, data, label, ...props }: any) => {
	return (
		<>
			<components.Option {...props}>{children}</components.Option>
		</>
	);
};

const filterOption = (candidate: any, input: any) => {
	console.log(candidate);

	if (
		candidate?.label?.toLowerCase().includes(input?.toLowerCase())
		// ||
		// candidate?.data?.ein?.toLowerCase().includes(input?.toLowerCase()) ||
		// candidate?.data?.npi?.toLowerCase().includes(input?.toLowerCase())
	) {
		return candidate;
	}
};

const ListEntity = (props: any) => {
	const { dataHomeList, dataClinicList, error, isLoading } = useGetEntity(
		props?.state?.accessRole,
	);

	const [searchTerm, setSearchTerm] = useState<any>();
	const [loading, setloading] = useState<boolean>(true);
	const [dataList, setDataList] = useState<[]>();

	useEffect(() => {
		if (dataList === null || dataList === undefined) {
			setTimeout(() => {
				setDataList(dataHomeList?.concat(dataClinicList));
			}, 1000);
		}
	}, [dataClinicList, dataHomeList, dataList]);

	let response: any;
	const navigator = useNavigate();

	const onSubmit = async (data: any) => {
		setloading(false);
		if (data.entityItem.type === 'C') {
			response = await List.clinic(data.entityItem);

			response.type = 'C';
			navigator('/entities/profile');
			// navigator('/update/entity');
			setloading(true);
			props.setUpdateEntityForm(response);
		} else if (data.entityItem.type === 'HN') {
			response = await List.home(data.entityItem);
			response.type = 'HN';
			navigator('/entities/profile');
			// navigator('/update/entity');
			props.setUpdateEntityForm(response);
			setloading(true);
		}
	};

	const { control } = useForm({
		mode: 'onChange',
	});

	const searchEntity = (value: any, onchange: any) => {
		if (value?.label) {
			setSearchTerm(value.label);
		} else {
			setSearchTerm('');
		}
		return onchange;
	};

	const selectedTypeEntity = (value: any, onchange: any) => {
		if (value?.value === 'clinic') {
			setDataList(dataClinicList);
		} else if (value?.value === 'nursinghome') {
			setDataList(dataHomeList);
		} else {
			setDataList(dataHomeList?.concat(dataClinicList));
		}
		return onchange;
	};

	return (
		<div className="w-full h-full flex flex-col gap-5 ">
			<div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 mb-5 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
				<div className="flex flex-col md:flex-row gap-1">
					<div className="flex flex-row">
						<img src={Entities} className={`w-20`} alt="" />
						<h1 className="w-full self-center text-[#96c952] text-4xl font-extrabold ">
							Entidades registradas
						</h1>
					</div>
				</div>
				<div>
					<h1 className="w-full self-center text-[#aaaaaa] text-2xl md:text-4xl font-light ">
						/ Buscar
					</h1>
				</div>
			</div>
			<div className="px-5 flex flex-row gap-2">
				<Controller
					control={control}
					name="selectionType"
					rules={{
						required: {
							value: false,
							message: 'Nursing Home es requerida',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => {
						return (
							<Select
								className="w-64"
								placeholder={`Tipo de entidad...`}
								ref={ref}
								isClearable
								styles={customStyles}
								onBlur={onBlur}
								onChange={onChange}
								options={[
									{ label: 'Todo', value: 'all' },
									{ label: 'Clinica', value: 'clinic' },
									{ label: 'Nursing Home', value: 'nursinghome' },
								]}
								value={value}
								components={{
									Option: CustomOption,
									ValueContainer: CustomValueContainer,
								}}
								isSearchable
								filterOption={filterOption}
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name="listEntity"
					rules={{
						required: {
							value: false,
							message: 'Nursing Home es requerida',
						},
					}}
					render={({ field: { onChange, onBlur, value, ref } }: any) => {
						return (
							<Select
								className="w-full"
								placeholder={`Busque una entidad por nombre, NPI, EIN`}
								ref={ref}
								isClearable
								styles={customStyles}
								onBlur={onBlur}
								onChange={searchEntity(value, onChange)}
								options={dataList}
								value={value}
								components={{ Option: CustomOption }}
								isSearchable
								filterOption={filterOption}
							/>
						);
					}}
				/>
			</div>
			<div className="flex flex-col gap-5 p-7">
				<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
					{isLoading ? (
						<div>Cargando</div>
					) : (
						dataList
							?.filter((val: any) => {
								if (
									searchTerm === '' ||
									searchTerm === null ||
									searchTerm === undefined
								) {
									return val;
								} else if (
									val?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
								) {
									return val;
								}
							})
							?.map((item: any) => {
								return (
									<div
										key={item.value}
										className="rounded-md border px-5 py-4 shadow-lg flex flex-col gap-3 bg-white">
										<div className="flex flex-col">
											<div className="flex flex-row gap-x-1">
												<b>Tipo:</b>
												<p>
													{item?.type === 'HN' ? 'Nursing Home' : 'Clinica'}
												</p>
											</div>
											<div className="flex flex-row gap-x-1">
												<b>Nombre:</b>
												<p>{item?.label ? item?.label : 'N/A'}</p>
											</div>
											<div className="flex flex-row gap-x-1">
												<b>Pueblo:</b>
												<p>{item?.state ? item?.state : 'N/A'}</p>
											</div>
										</div>
										<div className="flex flex-row">
											<div className="flex-1" />
											<button
												//w-full
												className="sm:w-fit rounded-lg border-[1px] border-[#044382] bg-[#1E93C6] px-4 py-2 font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-[#044382] hover:bg-[#0561A0]"
												disabled={!loading}
												onClick={() => {
													onSubmit({ entityItem: item });
												}}>
												Ver más
											</button>
										</div>
									</div>
								);
							})
					)}
				</div>
				<div className="w-full flex flex-row justify-center">
					<button
						className="m-auto px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300"
						onClick={() => {
							navigator('/home');
						}}>
						Volver
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		state: {
			currentUser: state.currentUser,
			accessRole: state.accessRole,
		},
	};
};

const mapDispatchToProps = {
	setUpdateEntityForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEntity);
// export default ListEntity;
