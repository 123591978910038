const SumaryUserRegisterData = ({
	setModalOn,
	setChoice,
	registerUser,
	setIsLoading,
	formData,
}: any) => {
	const handleOKClick = () => {
		registerUser();
		setChoice(true);
		setModalOn(false);
	};
	const handleCancelClick = () => {
		setIsLoading(true);
		setChoice(false);
		setModalOn(false);
	};

	console.log(formData);

	return (
		<div className=" opacity-100 fixed inset-0 z-50">
			<div className="flex h-screen justify-center items-center ">
				<div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
					<div className="flex flex-col text-lg text-zinc-600 max-w-xl space-y-4">
						<p className="font-bold text-2xl">Resumen del registro</p>
						<div className="flex flex-col">
							<div className="flex flex-row gap-1">
								<b className="font-bold">Tipo de usuario</b>
								<p>{formData?.user_type?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Primer nombre: </b>
								<p>{formData?.firstname}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Segundo nombre: </b>
								<p>{formData?.middlename}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Primer apellido: </b>
								<p>{formData?.surname}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Segundo apellido: </b>
								<p>{formData?.second_surname}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Genero: </b>
								<p>{formData?.gender?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Dia de nacimiento: </b>
								<p>{formData?.birthday?.toISOString()?.substring(0, 10)}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Pais: </b>
								<p>{formData?.countryOrigin?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Pueblo: </b>
								<p>{formData?.municipalState?.label}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Correo electronico</b>
								<p>{formData?.email}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Numero Celular: </b>
								<p>{formData?.phoneMobile}</p>
							</div>
							<div className="flex flex-row gap-1">
								<b className="font-bold">Entidad a la que pertenece: </b>
								<p>{formData?.nursinghome_id?.label}</p>
							</div>
						</div>
						<p className="font-bold text-base text-center">
							¿Esta seguro de registrar este usuario?
						</p>
					</div>
					<div className="flex">
						<div className="flex-1" />
						<button
							onClick={handleOKClick}
							className=" rounded px-4 py-2 text-white bg-green-400 ">
							Si
						</button>
						<button
							onClick={handleCancelClick}
							className="rounded px-4 py-2 ml-4 text-white bg-blue-500 ">
							No
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SumaryUserRegisterData;
