/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	InformationCircleIcon,
	PencilAltIcon,
	TrashIcon,
} from '@heroicons/react/outline';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUpdatePatientInfoForm } from '../../actions';
import Conditions from '../../assets/svg/PatientItems/Conditions.svg';
import useGetListNursing from '../../hooks/useGetListNursing';
import InformationGeneral from '../../assets/svg/PatientItems/informacionRegistro.svg';
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg';
import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg';
import Physicians from '../../assets/svg/PatientItems/Physicians.svg';

const PatientInfo = (props: any) => {
	const {
		name,
		middle,
		surname,
		secondSurname,
		email,
		gender,
		status,
		phones,
		birthday,
		entryDate,
		statusSex,
		bloodtype,
		tytocareId,
		isVaccinated,
		maritalStatus,
		insuranceCarrier,
		socialSecurityId,
		clinicalMedicalNH,
		insuranceCarrierII,
		preferenceHospital,
		nursingHomeId,
		nursingHomeSourceId,
		countryOrigin,
		id,
	} = props.data;

	const navigate = useNavigate();
	const { dataNursing, error, isLoading }: any = useGetListNursing();

	return (
		<React.Fragment>
			{/* <div className="w-full text-left md:px-20 space-y-4 text-lg pb-4 border-y-8 "> */}
			<div className="w-full text-left md:px-20 space-y-4 text-lg py-4 border-y-2 ">
				<div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
					<img src={InformationGeneral} className={`w-12`} alt="" />
					<h1 className="text-xl font-bold">
						<p className="text-black font-bold first-line:capitalize">
							{`Registro`}
						</p>
					</h1>
				</div>
				<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
					<div className="w-fit md:ml-24 min-w-sm	 ">
						<div className="md:gap-1.5 md:flex md:flex-row ">
							<p className="text-sky-500 font-bold">Casa de Reposo:</p>
							<p>
								{dataNursing
									.filter((item: any) => {
										if (item?.value === nursingHomeId) {
											return item;
										}
									})
									?.map((item: any) => {
										return item.label;
									})}
							</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Estado:</p> <p>{status}</p>
						</div>

						<div className="md:gap-1.5 md:flex md:flex-row ">
							<p className="text-sky-500 font-bold">Número de Residente:</p>
							<p>{clinicalMedicalNH}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Fecha de ingreso:</p>
							<p>{entryDate}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row ">
							<p className="text-sky-500 font-bold">Hogar de procedencia:</p>
							<p>
								{`${nursingHomeSourceId === 0}`
									? `${'Ninguno'}`
									: `${dataNursing
											.filter((item: any) => {
												if (item?.value === nursingHomeSourceId) {
													//console.log(`item:\n${item}`);
													return item;
												}
											})
											?.map((item: any) => {
												return item.label;
											})}`}
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
					<img src={InformationPersonal} className={`w-12`} alt="" />

					<h1 className="text-xl font-bold">
						<p className="text-black font-bold first-line:capitalize">
							{`Personal`}
						</p>
					</h1>
				</div>
				<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
					<div className="w-fit md:ml-24 min-w-sm	 ">
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Fecha nacimiento:</p>
							<p>{birthday ? birthday : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Número seguro social:</p>
							<p>{socialSecurityId ? socialSecurityId : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">País de Origen:</p>
							<p>{countryOrigin ? countryOrigin : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Género:</p>{' '}
							<p>{gender ? gender : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Sexo:</p>{' '}
							<p>{statusSex ? statusSex : 'N/A'}</p>
						</div>

						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Estado marital:</p>
							<p>{maritalStatus ? maritalStatus : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Número de Celular:</p>
							<p>{phones?.number ? phones?.number : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Correo Electrónico:</p>{' '}
							<p>{email ? email : 'N/A'}</p>
						</div>
					</div>
				</div>
				<div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
					<img src={Physicians} className={`w-12`} alt="" />
					<h1 className="text-black text-xl font-bold">Médica</h1>
				</div>

				<div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
					<div className="w-fit md:ml-24 min-w-sm	 ">
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Grupo sanguíneo:</p>
							<p>{bloodtype ? bloodtype : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Seguro médico 1:</p>
							<p>{insuranceCarrier ? insuranceCarrier : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Seguro médico 2:</p>
							<p>{insuranceCarrierII ? insuranceCarrierII : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Hospital de preferencia:</p>
							<p>{preferenceHospital ? preferenceHospital : 'N/A'}</p>
						</div>
						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">Vacunado/a:</p>
							<p>{isVaccinated ? 'Si' : 'No'}</p>
						</div>

						<div className="md:gap-1.5 md:flex md:flex-row">
							<p className="text-sky-500 font-bold">ID tytoCare:</p>
							<p>{tytocareId ? tytocareId : 'N/A'}</p>
						</div>
					</div>
				</div>

				<div className="flex flex-row gap-10">
					<div className={`hidden md:flex-1 md:flex md:flex-row`} />
					<div
						className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
						onClick={() => {
							navigate('/patientupdate');
							props.setUpdatePatientInfoForm({
								name,
								middle,
								surname,
								secondSurname,
								email,
								gender,
								status,
								phones,
								birthday,
								entryDate,
								statusSex,
								bloodtype,
								tytocareId,
								isVaccinated,
								maritalStatus,
								insuranceCarrier,
								socialSecurityId,
								clinicalMedicalNH,
								insuranceCarrierII,
								preferenceHospital,
								nursingHomeId,
								nursingHomeSourceId,
								countryOrigin,
								id,
							});
						}}>
						<PencilAltIcon className="w-8 h-8 " />
						<div>Editar datos</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapDispatchToProps = {
	setUpdatePatientInfoForm,
};

export default connect(null, mapDispatchToProps)(PatientInfo);
