export const actions = {
	setUpdateEntityForm: 'SET_UPDATE_ENTITY_FORM',
	clearUpdateEntityForm: 'CLEAR_UPDATE_ENTITY_FORM',

	setUpdatePatientInfoForm: 'SET_UPDATE_PATIENT_INFO_FORM',
	clearUpdatePatientInfoForm: 'CLEAR_UPDATE_PATIENT_INFO_FORM',

	setUpdatePatientTutorForm: 'SET_UPDATE_PATIENT_TUTOR_FORM',
	clearUpdatePatientTutorForm: 'CLEAR_UPDATE_PATIENT_TUTOR_FORM',

	setUpdatePatientDoctorForm: 'SET_UPDATE_PATIENT_DOCTOR_FORM',
	clearUpdatePatientDoctorForm: 'CLEAR_UPDATE_PATIENT_DOCTOR_FORM',

	setUpdatePatientAllerProfCondForm: 'SET_UPDATE_PATIENT_ALLCONSUR_FORM',
	clearUpdatePatientAllerProfCondForm: 'CLEAR_UPDATE_PATIENT_ALLCONSUR_FORM',

	setUpdatePatientMedicationForm: 'SET_UPDATE_PATIENT_MEDICATION_FORM',
	clearUpdatePatientMedicationForm: 'CLEAR_UPDATE_PATIENT_MEDICATION_FORM',

	setUpdatePatientVaccineForm: 'SET_UPDATE_PATIENT_VACCINE_FORM',
	clearUpdatePatientVaccineForm: 'CLEAR_UPDATE_PATIENT_VACCINE_FORM',

	setUpdatePatientPhotoForm: 'SET_UPDATE_PATIENT_PHOTO_FORM',
	clearUpdatePatientPhotoForm: 'CLEAR_UPDATE_PATIENT_PHOTO_FORM',

	setUpdateProfileUserForm: 'SET_UPDATE_PROFILE_USER_FORM',
	clearUpdateProfileUserForm: 'CLEAR_UPDATE_PROFILE_USER_FORM',

	setSidebarCurrentUser: 'SET_SIDEBAR_CURRENT_USER',
	previewMonthCalendar: 'PREVIEW_MONTH_CALENDAR',
	nextMonthCalendar: 'NEXT_MONTH_CALENDAR',

	setEntitySelectedByUser: 'SET_ENTITY_SELECTED_BY_USER',
	clearEntitySelectedByUser: 'CLEAR_ENTITY_SELECTED_BY_USER',

	setOpenSwitchEntityModal: 'SET_OPEN_SWITCH_ENTITY_MODAL',

	setAllowOptionsByRole: 'SET_ALLOW_OPTIONS_BY_ROLE',

	setEntitiesId: 'SET_ENTITIES_ID',
};

export const setAllowOptionsByRole = (payload: any) => ({
	type: actions.setAllowOptionsByRole,
	payload,
});

export const setUpdateEntityForm = (payload: any) => ({
	type: actions.setUpdateEntityForm,
	payload,
});

export const clearUpdateEntityForm = (payload: any) => ({
	type: actions.clearUpdateEntityForm,
	payload,
});

export const setUpdatePatientInfoForm = (payload: any) => ({
	type: actions.setUpdatePatientInfoForm,
	payload,
});

export const clearUpdatePatientInfoForm = (payload: any) => ({
	type: actions.clearUpdatePatientInfoForm,
	payload,
});

export const setUpdatePatientTutorForm = (payload: any) => ({
	type: actions.setUpdatePatientTutorForm,
	payload,
});
export const clearUpdatePatientTutorForm = (payload: any) => ({
	type: actions.clearUpdatePatientTutorForm,
	payload,
});
export const setUpdatePatientDoctorForm = (payload: any) => ({
	type: actions.setUpdatePatientDoctorForm,
	payload,
});

export const clearUpdatePatientDoctorForm = (payload: any) => ({
	type: actions.clearUpdatePatientDoctorForm,
	payload,
});
export const setUpdatePatientAllerProfCondForm = (payload: any) => ({
	type: actions.setUpdatePatientAllerProfCondForm,
	payload,
});

export const clearUpdatePatientAllerProfCondForm = (payload: any) => ({
	type: actions.clearUpdatePatientAllerProfCondForm,
	payload,
});
export const setUpdatePatientMedicationForm = (payload: any) => ({
	type: actions.setUpdatePatientMedicationForm,
	payload,
});

export const clearUpdatePatientMedicationForm = (payload: any) => ({
	type: actions.clearUpdatePatientMedicationForm,
	payload,
});

export const setUpdatePatientVaccineForm = (payload: any) => ({
	type: actions.setUpdatePatientVaccineForm,
	payload,
});

export const clearUpdatePatientVaccineForm = (payload: any) => ({
	type: actions.clearUpdatePatientVaccineForm,
	payload,
});

export const setUpdateProfileUserForm = (payload: any) => ({
	type: actions.setUpdateProfileUserForm,
	payload,
});

export const clearUpdateProfileUserForm = (payload: any) => ({
	type: actions.clearUpdateProfileUserForm,
	payload,
});

export const setSidebarCurrentUser = (payload: any) => ({
	type: actions.setSidebarCurrentUser,
	payload,
});

export const previewMonthCalendar = (payload: any) => ({
	type: actions.previewMonthCalendar,
	payload,
});

export const nextMonthCalendar = (payload: any) => ({
	type: actions.nextMonthCalendar,
	payload,
});

export const setEntitySelectedByUser = (payload: any) => ({
	type: actions.setEntitySelectedByUser,
	payload,
});

export const clearEntitySelectedByUser = (payload: any) => ({
	type: actions.clearEntitySelectedByUser,
	payload,
});

export const setUpdatePatientPhotoForm = (payload: any) => ({
	//Jhonathan
	type: actions.setUpdatePatientPhotoForm,
	payload,
});

export const clearUpdatePatientPhotoForm = (payload: any) => ({
	//Jhonathan
	type: actions.clearUpdatePatientPhotoForm,
	payload,
});

export const setEntitiesId = (payload: any) => ({
	//Jhonathan
	type: actions.setEntitiesId,
	payload,
});
