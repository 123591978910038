export const enum ROLES {
	ADMIN = 'Super Administrador',
	DOCTOR = 'Doctor',
	SECRETARY = 'Secretaria',
	NURSE = 'Enfermera',
	PATIENT = 'Paciente',
	CARER = 'Cuidador',
	PROJECT_MANAGER = 'Director del proyecto',
	PROJECT_ASSISTANT = 'Asistente del proyecto',
	PROJECT_EVALUATOR = 'Evaluador del proyecto',
	ADMIN_CLINIC = 'Administrador de la clinica',
	ADMIN_NURSING_HOME = 'Administrador del hogar',
}

export const enum ROLEID {
	DOCTOR = 1,
	SECRETARY = 2,
	NURSE = 3,
	PATIENT = 4,
	ADMIN = 5,
	CARER = 6,
	ADMIN_NURSING_HOME = 7,
	ADMIN_CLINIC = 8,
	PROJECT_MANAGER = 9,
	PROJECT_ASSISTANT = 10,
	PROJECT_EVALUATOR = 11,
}
