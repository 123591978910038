/* eslint-disable @typescript-eslint/no-unused-vars */
import api from './Api';
import Swal from 'sweetalert2';
import TokenService from './Token';

const linkEntities = async (data: any) => {
	const user = TokenService.getUser();
	console.log('Raw data: ', data);

	const { clinicId, id } = data;

	let formatedData = {
		nursing_homeId: id,
		clinicId,
	};
	console.log('Formated Data', formatedData);

	return await api.instanceEntities
		.post('/nursing_home_clinic/createRelation', formatedData, {
			headers: {
				Authorization: `${user.TokenType} ${user.IdToken}`,
				USER_ENTITY_ID: `${TokenService.getRole()}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then((response: any) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'Su usuario fue actualizado correctamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return response;
		})
		.catch((err: any) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Vuelva a intertarlo nuevamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return err;
		});
};
const linkEntitiesHN = async (data: any) => {
	const user = TokenService.getUser();
	console.log('Raw data: ', data);

	const { nursing_homeId, id } = data;

	let formatedData = {
		clinicId: id,
		nursing_homeId,
	};

	console.log('Formated Data', formatedData);

	return await api.instanceEntities
		.post('/nursing_home_clinic/createRelation', formatedData, {
			headers: {
				Authorization: `${user.TokenType} ${user.IdToken}`,
				USER_ENTITY_ID: `${TokenService.getRole()}`,
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		})
		.then((response: any) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				icon: 'success',
				title: 'Confirmación',
				text: 'Su usuario fue actualizado correctamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return response;
		})
		.catch((err: any) => {
			Swal.fire({
				customClass: {
					confirmButton: 'bg-red-500 text-white',
				},
				title: 'Error Interno!',
				text: 'Vuelva a intertarlo nuevamente',
				// icon: "error",
				confirmButtonText: 'Aceptar',
			});
			return err;
		});
};

const LinkEntitiesService = {
	linkEntities,
	linkEntitiesHN,
};

export default LinkEntitiesService;
