export const PreviousSectionButton: React.FC<{
	onClick: () => void;
	isDisabled?: boolean;
}> = ({ onClick, isDisabled, children }) => {
	return (
		<button
			data-tip
			onClick={onClick}
			disabled={isDisabled}
			type="button"
			className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed">
			{children}
		</button>
	);
};
